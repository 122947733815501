import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IEditDoctorPayload,
  ActionType,
  EditDoctorResponse,
} from "models/doctor/editDoctor";
import { httpStatus } from "configs/httpStatus";
import { editDoctor } from "services/doctor";
import { handleSuccessAction, handleErrorAction } from "./editDoctorActions";

function* editDoctorSaga({ payload }: { payload: IEditDoctorPayload }) {
  try {
    const response: EditDoctorResponse = yield call(editDoctor, payload);

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onEditDoctorSagaSubmitWatcher() {
  yield takeLatest(ActionType.EDIT_DOCTOR as any, editDoctorSaga);
}

export default [fork(onEditDoctorSagaSubmitWatcher)];
