import {
  ActionType,
  IMonthSchedulesPayload,
  MonthSchedulesResponse,
} from "models/schedules/monthSchedules";

export const getMonthSchedulesAction = (payload: IMonthSchedulesPayload) => {
  return {
    type: ActionType.MONTH_SCHEDULE_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MONTH_SCHEDULE_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: MonthSchedulesResponse) => {
  return {
    type: ActionType.MONTH_SCHEDULE_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.MONTH_SCHEDULE_LIST_RESET,
  };
};
