import {
  IUpdateMedicineByIDResponse,
  ActionType,
} from "models/medicine/updateMedicineByID";

export const updateMedicineByIDAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_MEDICINE_BY_ID,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_MEDICINE_BY_ID_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IUpdateMedicineByIDResponse) => {
  return {
    type: ActionType.UPDATE_MEDICINE_BY_ID_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_MEDICINE_BY_ID_RESET,
  };
};
