import {
  IEditDoctorPayload,
  ActionType,
  EditDoctorResponse,
} from "models/doctor/editDoctor";

export const editDoctorAction = (payload: IEditDoctorPayload) => {
  return {
    type: ActionType.EDIT_DOCTOR,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.EDIT_DOCTOR_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: EditDoctorResponse) => {
  return {
    type: ActionType.EDIT_DOCTOR_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.EDIT_DOCTOR_RESET,
  };
};
