import {
  ICreateMedicineByIDResponse,
  ActionType,
} from "models/medicine/createMedicineByID";

export const createMedicineByIDAction = (payload: any) => {
  return {
    type: ActionType.CREATE_MEDICINE_BY_ID,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_MEDICINE_BY_ID_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ICreateMedicineByIDResponse) => {
  return {
    type: ActionType.CREATE_MEDICINE_BY_ID_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_MEDICINE_BY_ID_RESET,
  };
};
