import {
  ActionType,
  IAppointmenDetailstPayload,
  AppointmentDetailsResponse,
} from "models/appointments/appointmentDetails";

export const getAppointmentDetailsAction = (
  payload: IAppointmenDetailstPayload
) => {
  return {
    type: ActionType.APPOINTMENT_DETAILS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPOINTMENT_DETAILS_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: AppointmentDetailsResponse) => {
  return {
    type: ActionType.APPOINTMENT_DETAILS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPOINTMENT_DETAILS_RESET,
  };
};
