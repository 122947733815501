import * as React from "react";

function ProfileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "transparent" }}
      {...props}
    >
      <path
        d="M24.8 8H7.2C5.98497 8 5 9.02335 5 10.2857V21.7143C5 22.9767 5.98497 24 7.2 24H24.8C26.015 24 27 22.9767 27 21.7143V10.2857C27 9.02335 26.015 8 24.8 8Z"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6 13.32H23.6"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6 16.15H23.6"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6 18.98H23.6"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C13.6569 17 15 15.6569 15 14C15 12.3431 13.6569 11 12 11C10.3431 11 9 12.3431 9 14C9 15.6569 10.3431 17 12 17Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M17 22H7C7 18.083 9.24462 16 12 16C14.7554 16 17 18.1616 17 22Z"
        fill={props.fill || "#3B3B3B"}
      />
    </svg>
  );
}

export default ProfileIcon;
