import { IResponse } from "models";

export enum ActionType {
  CREATE_DOCTOR = "action/CREATE_DOCTOR",
  CREATE_DOCTOR_SUCCESS = "action/CREATE_DOCTOR_SUCCESS",
  CREATE_DOCTOR_ERROR = "action/CREATE_DOCTOR_ERROR",
  CREATE_DOCTOR_RESET = "action/CREATE_DOCTOR_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateDoctorPayload {
  doctor: {
    email: string;
    password: string;
    last_name: string;
    first_name: string;
    kana_last_name: string;
    kana_first_name: string;
  };
}

export interface CreateDoctorResponse extends IResponse {}
