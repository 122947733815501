import {
  ActionType,
  CreateMedicalRecordResponse,
} from "models/medicalRecords/createMedicalRecord";

export const createMedicalRecordAction = (payload: any) => {
  return {
    type: ActionType.CREATE_MEDICAL_RECORD,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_MEDICAL_RECORD_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateMedicalRecordResponse) => {
  return {
    type: ActionType.CREATE_MEDICAL_RECORD_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_MEDICAL_RECORDD_RESET,
  };
};
