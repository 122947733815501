import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ISearchMedicinePayload,
  ActionType,
  ISearchMedicineResponse,
} from "models/medicine/searchMedicine";
import { httpStatus } from "configs/httpStatus";
import { searchMedicine } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./searchActions";

function* searchMedicineSaga({ payload }: { payload: ISearchMedicinePayload }) {
  try {
    const response: ISearchMedicineResponse = yield call(
      searchMedicine,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleSuccesAction(response));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onSearchMedicineWatcher() {
  yield takeLatest(ActionType.SEARCH_MEDICINE as any, searchMedicineSaga);
}

export default [fork(onSearchMedicineWatcher)];
