import {
  Action,
  ActionType,
  MedicalPlanResponse,
} from "models/medicalPlans/medicalPlan";
import createReducer from "../../createReducer";

export interface MedicalPlanReducerType {
  loading: boolean;
  error?: string;
  medicalPlanResponse?: MedicalPlanResponse;
}

const defaultState: MedicalPlanReducerType = {
  loading: false,
  error: undefined,
  medicalPlanResponse: undefined,
};

export const medicalPlanReducer = createReducer<MedicalPlanReducerType>(
  defaultState,
  {
    [ActionType.GET_MEDICAL_PLAN](state: MedicalPlanReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_MEDICAL_PLAN_ERROR](
      state: MedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        medicalPlanResponse: undefined,
      };
    },

    [ActionType.GET_MEDICAL_PLAN_SUCCESS](
      state: MedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        medicalPlanResponse: action.payload,
      };
    },

    [ActionType.GET_MEDICAL_PLAN_RESET](state: MedicalPlanReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        medicalPlanResponse: undefined,
      };
    },
  }
);
