import {
  ActionType,
  ICreateMedicalPlanPayload,
  CreateMedicalPlanResponse,
} from "models/medicalPlans/createMedicalPlan";

export const createMedicalPlanAction = (payload: ICreateMedicalPlanPayload) => {
  return {
    type: ActionType.CREATE_MEDICAL_PLAN,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_MEDICAL_PLAN_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateMedicalPlanResponse) => {
  return {
    type: ActionType.CREATE_MEDICAL_PLAN_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_MEDICAL_PLAN_RESET,
  };
};
