import {
  ActionType,
  AppointmentUpdateStatusConsultationResponse,
} from "models/appointments/updateStatusConsultation";

export const updateStatusConsultationAction = (payload: any) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: AppointmentUpdateStatusConsultationResponse
) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_RESET,
  };
};
