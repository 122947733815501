import {
  Action,
  ActionType,
  AppointmentDetailsResponse,
} from "models/appointments/appointmentDetails";
import createReducer from "../../createReducer";

export interface AppointmentDetailsReducerType {
  loading: boolean;
  error?: string;
  appointmentDetailsResponse?: AppointmentDetailsResponse;
}

const defaultState: AppointmentDetailsReducerType = {
  loading: false,
  error: undefined,
  appointmentDetailsResponse: undefined,
};

export const appointmentDetailsReducer =
  createReducer<AppointmentDetailsReducerType>(defaultState, {
    [ActionType.APPOINTMENT_DETAILS](state: AppointmentDetailsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.APPOINTMENT_DETAILS_ERROR](
      state: AppointmentDetailsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        appointmentDetailsResponse: undefined,
      };
    },

    [ActionType.APPOINTMENT_DETAILS_SUCCESS](
      state: AppointmentDetailsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentDetailsResponse: action.payload,
      };
    },

    [ActionType.APPOINTMENT_DETAILS_RESET](
      state: AppointmentDetailsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentDetailsResponse: undefined,
      };
    },
  });
