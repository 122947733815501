import { IResponse } from "models";

export enum ActionType {
  GET_MEDICINE_LIST_BY_ID = "action/GET_MEDICINE_LIST_BY_ID",
  GET_MEDICINE_LIST_BY_ID_SUCCESS = "action/GET_MEDICINE_LIST_BY_ID_SUCCESS",
  GET_MEDICINE_LIST_BY_ID_ERROR = "action/GET_MEDICINE_LIST_BY_ID_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetMedicineListByIDResponse extends IResponse {
  result: any;
}
