import { createApiCall, MethodType, URI } from "../Api";

import { IMedicalPlanPayload } from "models/medicalPlans/medicalPlan";
import { INotifyMedicalPlanPayload } from "models/medicalPlans/notifyMedicalPlan";
import { ICreateMedicalPlanPayload } from "models/medicalPlans/createMedicalPlan";
import { IEditMedicalPlanPayload } from "models/medicalPlans/editMedicalPlan";

export const getMedicalPlan = (payload: IMedicalPlanPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICAL_PLANS.GET_MEDICAL_PLAN(payload.record_id)
  );
};

export const notifyMedicalPlan = (payload: INotifyMedicalPlanPayload) => {
  return createApiCall(
    MethodType.POST,
    URI.MEDICAL_PLANS.POST_MEDICAL_PLAN(payload.medical_plan_id)
  );
};

export const createMedicalPlan = (payload: ICreateMedicalPlanPayload) => {
  return createApiCall(
    MethodType.POST,
    URI.MEDICAL_PLANS.CREATE_MEDICAL_PLAN(payload.medical_record_id),
    payload.plan
  );
};

export const editMedicalPlan = (payload: IEditMedicalPlanPayload) => {
  return createApiCall(
    MethodType.PATCH,
    URI.MEDICAL_PLANS.EDIT_MEDICAL_PLAN(payload.medical_plan_id),
    {
      medical_plan: payload.medical_plan,
    }
  );
};
