import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  IGetMedicineListResponse,
} from "models/medicine/getMedicineList";
import { httpStatus } from "configs/httpStatus";
import { getMedicineList } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./listActions";

function* getMedicineListSaga() {
  try {
    const response: IGetMedicineListResponse = yield call(getMedicineList);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetMedicineListWatcher() {
  yield takeLatest(ActionType.GET_MEDICINE_LIST as any, getMedicineListSaga);
}

export default [fork(onGetMedicineListWatcher)];
