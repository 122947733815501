import {
  ActionType,
  Action,
  CreateDoctorResponse,
} from "models/doctor/createDoctor";
import createReducer from "../../createReducer";

export interface CreateDoctorReducerType {
  loading: boolean;
  error?: string;
  createDoctorResponse?: CreateDoctorResponse;
}

const defaultState: CreateDoctorReducerType = {
  loading: false,
  error: undefined,
  createDoctorResponse: undefined,
};

export const createDoctorReducer = createReducer<CreateDoctorReducerType>(
  defaultState,
  {
    [ActionType.CREATE_DOCTOR](state: CreateDoctorReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_DOCTOR_ERROR](
      state: CreateDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        createDoctorResponse: undefined,
      };
    },

    [ActionType.CREATE_DOCTOR_SUCCESS](
      state: CreateDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createDoctorResponse: action.payload,
      };
    },

    [ActionType.CREATE_DOCTOR_RESET](state: CreateDoctorReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        createDoctorResponse: undefined,
      };
    },
  }
);
