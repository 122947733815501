import React from "react";
import styled from "styled-components";
import { Modal, ModalProps } from "antd";
import { theme } from "theme/theme";

const ModalStyled = styled(Modal)`
  width: 343px !important;
  top: 16px;

  .ant-modal-content {
    border-radius: 0px;
  }

  .ant-modal-header {
    padding: 9px 15px 8px;
    border-bottom: none;
    border-radius: 0px;
    background: #2f8cae;
    .ant-modal-title {
      font-weight: 400;
      color: #ffffff;
      line-height: 23px;
    }
  }

  .ant-modal-close {
    top: -4px;
    right: -6px;
  }

  .ant-modal-title {
    text-transform: capitalize;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
  }

  .ant-modal-body {
    padding: 16px;
    border-radius: 0px;
  }

  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
    display: none;
  }

  ${theme.media.tablet} {
    width: 980px !important;
    top: 50%;
    transform: translate(0%, -50%);

    .ant-modal-footer {
      padding: 0px 24px 16px;
      button {
        font-size: ${theme.sizes.md};
      }
    }
  }

  ${theme.media.laptopL} {
    top: 100px;
    transform: unset;
  }
`;

interface IModal extends ModalProps {}

const ModalCustom: React.FC<IModal> = ({ children, ...props }) => {
  return <ModalStyled {...props}>{children}</ModalStyled>;
};

export default ModalCustom;
