import {
  ActionType,
  AppointmentCancelStatusResponse,
} from "models/appointments/cancelStatus";

export const cancelStatusConsultationAction = (payload: any) => {
  return {
    type: ActionType.APPOINTMENT_CANCEL_STATUS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPOINTMENT_CANCEL_STATUS_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: AppointmentCancelStatusResponse
) => {
  return {
    type: ActionType.APPOINTMENT_CANCEL_STATUS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPOINTMENT_CANCEL_STATUS_RESET,
  };
};
