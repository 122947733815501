import {
  Action,
  ActionType,
  IDoctorsListResponse,
} from "models/doctor/doctorsList";
import createReducer from "../../createReducer";

export interface DoctorsListReducerType {
  loading: boolean;
  error?: string;
  doctorsListResponse?: IDoctorsListResponse;
}

const defaultState: DoctorsListReducerType = {
  loading: false,
  error: undefined,
  doctorsListResponse: undefined,
};

export const doctorsListReducer = createReducer<DoctorsListReducerType>(
  defaultState,
  {
    [ActionType.DOCTORS_LIST](state: DoctorsListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DOCTORS_ERROR](
      state: DoctorsListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DOCTORS_SUCCESS](
      state: DoctorsListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        doctorsListResponse: action.payload,
      };
    },
  }
);
