import React from "react";
import { IItems } from "./index";
import { SettingIcon, ScheduleIcon, ReservationIcon, ProfileIcon } from "icons";

export const MenuItems: IItems[] = [
  {
    key: "0",
    icon: <ScheduleIcon className="schedule-icon" />,
    label: "schedule-label",
    path: "/schedules",
  },
  // {
  //   key: "1",
  //   icon: <ReservationIcon className="reservation-icon" />,
  //   label: "reservation-label",
  //   path: "/reservations",
  // },
  {
    key: "2",
    icon: <ProfileIcon className="profile-icon" />,
    label: "profile-label",
    path: "/profile",
  },
  // {
  //   key: "3",
  //   icon: <SettingIcon />,
  //   label: "change-password-label",
  //   path: "/change-password",
  // },
];

export const AdminMenuItems: IItems[] = [
  {
    key: "0",
    icon: <ScheduleIcon className="schedule-icon" />,
    label: "schedule-label",
    path: "/schedules",
  },
  {
    key: "1",
    icon: <ReservationIcon className="reservation-icon" />,
    label: "reservation-label",
    path: "/reservations",
  },
  {
    key: "2",
    icon: <ProfileIcon className="profile-icon" />,
    label: "profile-label",
    path: "/profile",
  },
  // {
  //   key: "3",
  //   icon: <SettingIcon />,
  //   label: "change-password-label",
  //   path: "/change-password",
  // },
];
// export const MESSAGE_URL = ["/messages", "/message-list"];
