import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, notification } from "antd";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";

import { Input, Button, Select, Box, DatePicker } from "components";
import {
  AttentionIcon,
  CalendarIcon,
  CameraActive,
  CameraDisabled,
  ConfirmFailIcon,
  ConfirmSuccessIcon,
} from "icons";
import { localStorageHelper } from "utils";

import { IMonthAppointment } from "models/appointments/monthAppointmentsList";
import {
  GetAppointmentsListActionCreators,
  GetMonthAppointmentsActionCreators,
} from "redux/rootActions";
import {
  selectIsLoadingAppointmentsList,
  selectAppointmentsListErrorMessage,
  appointmentsListResponse,
} from "redux/appointments/appointmentsList/appointmentsListStates";
import {
  selectIsLoadingMonthAppointmentsList,
  selectmonthAppointmentsListErrorMessage,
  monthAppointmentsListResponse,
} from "redux/appointments/monthAppointments/monthAppointmentsStates";

import {
  WapperFilter,
  ContentWrapper,
  ScheduleTableStyled,
  WrapperReservations,
  Link,
  TodayButton,
  ReservationTable,
} from "./Reservations.style";
import {
  ScheduleNotiWrapper,
  ScheduleTableStyledAdmin,
} from "../schedules/Schedule.style";
import ReservationDetails from "./ReservationDetails";
import { httpStatus } from "configs/httpStatus";
registerLocale("ja", ja);

const { useBreakpoint } = Grid;
export type ScreenState = "list" | "update" | "";

interface LocationState {
  startDate: Date;
}

export const showWarning = (mode: number, temperature?: number) => {
  let themeScheme: {
    message: string;
    textColor: string;
    color: string;
    btnName: string;
    mode: number;
  } = {
    message: "",
    color: "",
    textColor: "",
    btnName: "",
    mode: 0,
  };
  if (temperature) {
    return "";
  }
  switch (mode) {
    case 1:
      themeScheme = {
        message:
          "オンライン診療を開始する準備ができましたら、右の開始ボタンを押してください",
        color: "#2AC769",
        textColor: "#3B3B3B",
        btnName: "開　始",
        mode: 1,
      };
      break;
    case 2:
      themeScheme = {
        message:
          "患者様とオンライン診療への同意がとれた場合は、右の「同意」ボタンを押してください。",
        color: "#FB4E4E",
        textColor: "#FB4E4E",
        btnName: "同　意",
        mode: 2,
      };
      break;
    case 3:
      themeScheme = {
        message: "オンライン診療への同意済み",
        color: "#B7B7B7",
        textColor: "#B7B7B7",
        btnName: "同　意",
        mode: 3,
      };
      break;
    default:
      return;
  }
  const Message = () => {
    //   return (
    //     <Trans i18nKey="body-temperature.warning-first">
    //       {{ percent: temperature }}
    //     </Trans>
    //   );

    return <div>20:30に大島大輔さんからの予約が入りました。</div>;
  };
  return (
    <React.Fragment>
      <ScheduleNotiWrapper
        style={{
          padding:
            themeScheme.mode !== 3 ? "3px 4px 4px 8px" : "11px 16px 10px",
        }}
      >
        <div
          className="title"
          style={{
            color: themeScheme.textColor,
            fontSize: themeScheme.mode !== 3 ? 14 : 18,
          }}
        >
          {themeScheme.message}
        </div>
        {themeScheme.mode !== 3 && (
          <div className="button-wrapper">
            <Button
              onClick={() => console.log("clicked")}
              background="#E0E0E0"
              color="#fff"
              name={"キャンセル"}
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              padding="13px 24px 11px"
              fontWeight={700}
            />

            <Button
              onClick={() => console.log("clicked")}
              background={themeScheme.color}
              color="#fff"
              name={themeScheme.btnName}
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              padding="13px 32px 11px"
              fontWeight={700}
            />
          </div>
        )}
      </ScheduleNotiWrapper>
    </React.Fragment>
  );
};

const ReservationManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const state = useLocation<LocationState>();
  const history = useHistory();

  const isLoadingAppointmentsList = useSelector(
    selectIsLoadingAppointmentsList
  );
  const isLoadingMonthAppointmentsList = useSelector(
    selectIsLoadingMonthAppointmentsList
  );

  const AppointmentsListErrorMessage = useSelector(
    selectAppointmentsListErrorMessage
  );
  const MonthAppointmentsListErrorMessage = useSelector(
    selectmonthAppointmentsListErrorMessage
  );

  const appointmentsListData = useSelector(appointmentsListResponse);
  const monthAppointmentsListData = useSelector(monthAppointmentsListResponse);

  const { watch, handleSubmit, control, setValue } = useForm({});

  const [startDate, setStartDate] = useState(new Date());
  const [isChecked, setIsChecked] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState<any>(new Date());
  const [startDateFilter2, setStartDateFilter2] = useState<any>(new Date());
  const [monthDateFilter, setMonthDateFilter] = useState<Date>(new Date());
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [monthSelected, setMonthSelected] = useState<boolean>(true);
  const [screen, setScreen] = useState<ScreenState>("");
  const [createVideoVisible, setCreateVideoVisisble] = useState<{
    visible: boolean;
    sent: boolean;
  }>({
    visible: false,
    sent: false,
  });
  const [highlightDates, setHighlightDates] = useState<Date[]>([]);
  const [appointmentList, setAppointmentList] = useState<any>([]);

  const toggleDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const screens = useBreakpoint();

  const onChangeCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const returnGender = (gender: string) => {
    if (gender === "女性") {
      return "女";
    }
    return "男";
  };

  const toggleVisibleVideoCreate = () => {
    setCreateVideoVisisble({
      visible: !createVideoVisible.visible,
      sent: createVideoVisible.sent,
    });
  };

  const handleChangeToday = (direction: "next" | "prev") => {
    if (startDateFilter) {
      setOpenDatePicker(false);
      setMonthSelected(true);
      switch (direction) {
        case "next":
          setMonthDateFilter(moment(startDateFilter).add(1, "day").toDate());
          setStartDateFilter(moment(startDateFilter).add(1, "day").toDate());
          setStartDateFilter2(moment(startDateFilter).add(1, "day").toDate());
          break;
        case "prev":
          setMonthDateFilter(
            moment(startDateFilter).subtract(1, "day").toDate()
          );
          setStartDateFilter(
            moment(startDateFilter).subtract(1, "day").toDate()
          );
          setStartDateFilter2(
            moment(startDateFilter).subtract(1, "day").toDate()
          );
          break;
        default:
          return;
      }
    }
  };

  const returnVideoButton = (link: string) => {
    if (link) {
      return (
        <Button
          onClick={() =>
            setCreateVideoVisisble({
              visible: true,
              sent: true,
            })
          }
          background="#2F8CAE"
          color="#fff"
          name="確 認"
          border="none"
          bdr="4px"
          fontSize={16}
          lineHeight="15px"
          padding="8px 16px 7px"
          fontWeight={400}
        />
      );
    } else {
      return (
        <Button
          onClick={() =>
            setCreateVideoVisisble({
              visible: true,
              sent: false,
            })
          }
          background="#2AC769"
          color="#fff"
          name="作 成"
          border="none"
          bdr="4px"
          fontSize={16}
          lineHeight="15px"
          padding="8px 16px 7px"
          fontWeight={400}
        />
      );
    }
  };

  const returnStatus = (status: number): string => {
    switch (status) {
      case 1:
        return "予約中";
      case 2:
        return "診察終了";
      case 3:
        return "診療前相談終了";
      case 4:
        return "請求済み";
      case 5:
        return "キャンセル";
      case 6:
        return "請求なし";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "予約日時",
      dataIndex: "date",
      key: "date",
      fixed: window.innerWidth < 576 ? "" : "left",
      // width: 213,
      render: (text: string, record: any) => {
        const date = new Date(text);
        if (!text || !record) {
          return <div>-</div>;
        }
        return (
          <div>
            {moment(text).format("MM/DD")}（
            {date.toLocaleString("ja", { weekday: "short" })}）
            {record.start_time}-{record.end_time}
          </div>
        );
      },
    },
    {
      title: "予約者名（ふりがな） / 年齢",
      dataIndex: "patient_name",
      key: "patient_name",
      // width: 348,
      fixed: window.innerWidth < 576 ? "" : "left",
      show: true,
      onCell: (record: any) => {
        return {
          onClick: () => {
            history.push({
              pathname: `/reservation-details/${record.id}`,
              state: {
                startDateFilter,
              },
            });
          },
        };
      },
      render: (text: string, record: any) => {
        if (!text || !record) {
          return <div>-</div>;
        }
        return (
          <RouterLink
            to={{
              pathname: `/reservation-details/${record.id}`,
              state: { startDateFilter },
            }}
          >
            <Link>
              {text}
              {record.patient_kana_name &&
                record.patient_kana_name !== " " &&
                `（${record.patient_kana_name}）`}
              {(!record.patient_kana_name ||
                record.patient_kana_name === " ") &&
                "  "}
              様
              {!record.patient_age && record.patient_age !== 0
                ? ""
                : ` / ${record.patient_age}歳`}
            </Link>
          </RouterLink>
        );
      },
    },
    {
      title: "性別",
      dataIndex: "patient_gender",
      key: "patient_gender",
      // width: 64,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{returnGender(text)}</div>;
      },
    },
    {
      title: "主訴",
      dataIndex: "chief_complain",
      key: "chief_complain",
      // width: 460,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "病院名",
      dataIndex: "hospital_name",
      key: "hospital_name",
      // width: 300,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    // {
    //   title: "ビデオ通話リンク",
    //   dataIndex: "meeting_url",
    //   key: "meeting_url",
    //   width: 160,
    //   show: true,
    //   render: (text: string) => (
    //     <div style={{ textAlign: "center" }}>{returnVideoButton(text)}</div>
    //   ),
    // },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      show: true,
      render: (text: any) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{returnStatus(text)}</div>;
      },
    },
  ];

  const getMonthAppointment = (date: any) => {
    if (date && openDatePicker) {
      dispatch(
        GetMonthAppointmentsActionCreators.getMonthAppointmentsListAction({
          start_date: `${moment(date)
            .startOf("M")
            .format("YYYY-MM-DD")}T00:00:00`,
          end_date: `${moment(date).endOf("M").format("YYYY-MM-DD")}T24:00:00`,
        })
      );
      if (
        startDateFilter &&
        moment(startDateFilter).month() === moment(date).month()
      ) {
        setMonthSelected(true);
        setStartDateFilter2(startDateFilter);
      } else {
        setMonthSelected(false);
        setStartDateFilter2(date);
      }
    }
  };

  useEffect(() => {
    if (state && state.state) {
      setStartDateFilter(state.state.startDate);
      setStartDateFilter2(state.state.startDate);
    }
  }, [state]);

  useEffect(() => {
    dispatch(GetAppointmentsListActionCreators.handleResetAction());
  }, []);

  useEffect(() => {
    if (startDateFilter) {
      dispatch(
        GetAppointmentsListActionCreators.getAppointmentsListAction({
          start_date: `${moment(startDateFilter).format(
            "YYYY-MM-DD"
          )}T00:00:00`,
          end_date: `${moment(startDateFilter).format("YYYY-MM-DD")}T24:00:00`,
        })
      );
    }
  }, [startDateFilter]);

  useEffect(() => {
    if (monthDateFilter && openDatePicker) {
      dispatch(
        GetMonthAppointmentsActionCreators.getMonthAppointmentsListAction({
          start_date: `${moment(monthDateFilter)
            .startOf("M")
            .format("YYYY-MM-DD")}T00:00:00`,
          end_date: `${moment(monthDateFilter)
            .endOf("M")
            .format("YYYY-MM-DD")}T24:00:00`,
        })
      );
    }
  }, [monthDateFilter, openDatePicker]);

  useEffect(() => {
    if (AppointmentsListErrorMessage) {
      // notification.error({
      //   placement: "bottomRight",
      //   message: "No appointments data",
      //   icon: <ConfirmFailIcon width={53} height={53} />,
      // });
      setAppointmentList([]);
      dispatch(GetAppointmentsListActionCreators.handleResetAction());
    }
  }, [AppointmentsListErrorMessage]);

  useEffect(() => {
    if (
      monthAppointmentsListData &&
      !monthAppointmentsListData.result.length &&
      !isLoadingMonthAppointmentsList
    ) {
      setHighlightDates([]);
    }
    if (monthAppointmentsListData) {
      const dateArray: Date[] = [];
      monthAppointmentsListData.result.map((appointment: IMonthAppointment) => {
        if (!dateArray.length) {
          dateArray.push(new Date(appointment.date));
        } else {
          if (
            !dateArray.some(
              (eventItem: Date) =>
                moment(eventItem).format("YYYY-MM-DD") === appointment.date
            )
          )
            dateArray.push(new Date(appointment.date));
        }
      });
      setHighlightDates(dateArray);
    }
  }, [monthAppointmentsListData]);

  useEffect(() => {
    if (
      appointmentsListData &&
      appointmentsListData.status === httpStatus.StatusOK
    ) {
      setAppointmentList(appointmentsListData?.result.reverse());
    }
  }, [appointmentsListData]);

  const ReservationManagement = () => (
    <WrapperReservations>
      {/* {showWarning(2)} */}
      <Box title="予約一覧" padding={screens.md ? "16px" : "8px"}>
        <WapperFilter>
          <div style={{ position: "relative" }}>
            <button
              className="btn-previous"
              onClick={() => handleChangeToday("prev")}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
            </button>
            <div className="title-wrapper">
              <div className="datepicker-wrapper">
                <CalendarIcon
                  width={22}
                  height={24}
                  onClick={toggleDatePicker}
                />
                <DatePicker
                  monthSelected={monthSelected}
                  open={openDatePicker}
                  locale="ja"
                  selected={startDateFilter2}
                  placeholderText={"unspecified"}
                  onChange={(date: Date) => {
                    setMonthSelected(true);
                    setStartDateFilter(date);
                    setStartDateFilter2(date);
                    setMonthDateFilter(date);
                    setOpenDatePicker(false);
                    // setTimeFilter("");
                    // dispatch(
                    //   TimeSlotActionCreators.getTimeSlotAction({
                    //     selected_slot: date,
                    //   })
                    // );
                  }}
                  highlightDates={highlightDates}
                  onMonthChange={(e: any) => getMonthAppointment(e)}
                  // onMonthChange={(e) => {
                  //   setMonthDateFilter(e);
                  //   setStartDateFilter(e);
                  // }}
                />
              </div>
              {startDateFilter && (
                <p className="pTittle">
                  {moment(startDateFilter).format("YYYY年MM月DD日")}（
                  {startDateFilter.toLocaleString("ja", { weekday: "short" })}）
                </p>
              )}
              <TodayButton
                isToday={moment(startDateFilter).isSame(
                  moment(new Date()),
                  "day"
                )}
                onClick={() => {
                  setMonthSelected(true);
                  setOpenDatePicker(false);
                  setStartDateFilter(new Date());
                  setStartDateFilter2(new Date());
                }}
              >
                本日
              </TodayButton>
              {/* <div
                className="today"
                style={{
                  cursor: "pointer",
                  background:
                    startDateFilter &&
                    moment(startDateFilter).isSame(moment(new Date()))
                      ? "#2ac769"
                      : "#ffffff",
                }}
              ></div> */}
              <button
                className="btn-next"
                onClick={() => handleChangeToday("next")}
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
              </button>
            </div>
          </div>
        </WapperFilter>
        <ContentWrapper>
          <ReservationTable
            dataSource={appointmentList}
            columns={columns}
            scroll={{ x: "max-content" }}
            loading={isLoadingAppointmentsList}
          />
        </ContentWrapper>
      </Box>
    </WrapperReservations>
  );

  const renderScreen = () => {
    switch (screen) {
      case "update":
        return <ReservationDetails />;
      case "list":
        return <ReservationManagement />;
      default:
        return <ReservationManagement />;
    }
  };

  return <React.Fragment>{renderScreen()}</React.Fragment>;
};

export default ReservationManagement;
