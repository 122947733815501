import {
  Action,
  ActionType,
  EditMedicalPlanResponse,
} from "models/medicalPlans/editMedicalPlan";
import createReducer from "../../createReducer";

export interface EditMedicalPlanReducerType {
  loading: boolean;
  error?: string;
  editMedicalPlanResponse?: EditMedicalPlanResponse;
}

const defaultState: EditMedicalPlanReducerType = {
  loading: false,
  error: undefined,
  editMedicalPlanResponse: undefined,
};

export const editMedicalPlanReducer = createReducer<EditMedicalPlanReducerType>(
  defaultState,
  {
    [ActionType.EDIT_MEDICAL_PLAN](state: EditMedicalPlanReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.EDIT_MEDICAL_PLAN_ERROR](
      state: EditMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        editMedicalPlanResponse: undefined,
      };
    },

    [ActionType.EDIT_MEDICAL_PLAN_SUCCESS](
      state: EditMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        editMedicalPlanResponse: action.payload,
      };
    },

    [ActionType.EDIT_MEDICAL_PLAN_RESET](state: EditMedicalPlanReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        editMedicalPlanResponse: undefined,
      };
    },
  }
);
