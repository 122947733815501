import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  IUpdateMedicineByIDResponse,
} from "models/medicine/updateMedicineByID";
import { httpStatus } from "configs/httpStatus";
import { updateMedicineByID } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./updateByIDActions";

function* updateMedicineByIDSaga({ payload }: { payload: any }) {
  try {
    const response: IUpdateMedicineByIDResponse = yield call(
      updateMedicineByID,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(
        handleSuccesAction({
          ...response,
          result: {},
        })
      );
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onUpdateMedicineByIDWatcher() {
  yield takeLatest(
    ActionType.UPDATE_MEDICINE_BY_ID as any,
    updateMedicineByIDSaga
  );
}

export default [fork(onUpdateMedicineByIDWatcher)];
