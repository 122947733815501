import { IResponse } from "models";

export enum ActionType {
  DOCTOR_APPOINTMENTS_LIST = "action/DOCTOR_APPOINTMENTS_LIST",
  DOCTOR_APPOINTMENTS_LIST_SUCCESS = "action/DOCTOR_APPOINTMENTS_LIST_SUCCESS",
  DOCTOR_APPOINTMENTS_LIST_ERROR = "action/DOCTOR_APPOINTMENTS_LIST_ERROR",
  DOCTOR_APPOINTMENTS_LIST_RESET = "action/DOCTOR_APPOINTMENTS_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDoctorAppointmentsPayload {
  doctor_id: number;
  payload: {
    start_date: string;
    end_date: string;
  };
}

export interface IDoctorAppointment {
  id: number;
  schedule_id: number;
  agree_repo_patient_id: number;
  is_cancelled: boolean;
  date: string;
  weekday_index: number;
  start_time: string;
  end_time: string;
  doctor_name: string;
  patient_name: string;
  patient_kana_name: string;
  patient_age: number;
  patient_gender: string;
  hospital_name: string;
  meeting_url: string;
  symptom: string;
}

export interface IDoctorAppointmentItem {
  appointments: IDoctorAppointment[];
  doctor_id: number;
  doctor_name: string;
}

export interface DoctorAppointmentsResponse extends IResponse {
  // result: IDoctorAppointmentItem[];
  result: any;
}
