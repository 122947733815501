import React from "react";
import styled from "styled-components";

import { WrapperAreaErrorMessage, ErrorMessage } from "theme/CommonStyle";

interface ITextArea {
  height?: string;
  label?: React.ReactNode;
  width?: number | string;
  bdr?: string; // border radius
  fsLabel?: number; // font size
  fwLabel?: number; // font weight
  lhLabel?: string; // line height
  fs?: number;
  fw?: number;
  lh?: string;
  padding?: string;
  value?: string;
  name?: string;
  type?: string;
  onChange?: any;
  error?: string;
  marginForm?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
  className?: any;
}

const FormControl = styled.div<ITextArea>(({ marginForm }) => ({
  position: "relative",
  margin: marginForm,
}));

const Label = styled.div<ITextArea>(
  ({ theme, fsLabel, fwLabel, lhLabel, disabled }) => ({
    color: disabled ? theme.colors.border : theme.colors.text.primary,
    fontSize: fsLabel || theme.sizes.xs,
    fontWeight: fwLabel || 400,
    lineHeight: lhLabel || "12px",
    marginBottom: 8,
  })
);

const TextAreaStyled = styled.textarea<ITextArea>(
  ({ theme, width, bdr, height, fs, fw, padding, lh, disabled }) => ({
    height: height || 62,
    width: width || "100%",
    borderRadius: bdr || theme.sizes.xs,
    boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.08)",
    border: `1px solid ${theme.colors.textFieldBackground}`,
    fontSize: fs || theme.sizes.md,
    fontWeight: fw || theme.fontWeight.medium,
    lineHeight: lh || "24px",
    padding: padding || 16,
    outline: "none",
    resize: "vertical",
    background: disabled ? theme.colors.background : "",
    ":focus": {
      border: `1px solid ${theme.colors.outline}`,
    },
    "::-webkit-scrollbar": {
      width: 16,
    },
    "::-webkit-scrollbar-track": {
      margin: "9px 0px 9px",
      borderLeft: "4px solid #FFFFFF",
      borderRight: "4px solid #FFFFFF",
      background: theme.colors.white,
      borderRadius: 7,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.border,
      borderRadius: 7,
      height: "8px",
      borderLeft: "4px solid #FFFFFF",
      borderRight: "4px solid #FFFFFF",
    },
  })
);

const TextAreaCustom: React.FC<ITextArea> = ({
  label,
  error,
  fsLabel,
  fwLabel,
  lhLabel,
  marginForm,
  placeholder,
  disabled,
  className = "",
  ...rest
}) => {
  return (
    <FormControl marginForm={marginForm} className={className}>
      {label && (
        <Label
          fsLabel={fsLabel}
          fwLabel={fwLabel}
          lhLabel={lhLabel}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
      <TextAreaStyled
        {...rest}
        spellCheck="false"
        placeholder={placeholder}
        disabled={disabled}
      />
      {error && (
        <WrapperAreaErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperAreaErrorMessage>
      )}
    </FormControl>
  );
};

export default TextAreaCustom;
