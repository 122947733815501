import { IResponse } from "models";

export enum ActionType {
  DELETE_DOCTOR = "action/DELETE_DOCTOR",
  DELETE_DOCTOR_SUCCESS = "action/DELETE_DOCTOR_SUCCESS",
  DELETE_DOCTOR_ERROR = "action/DELETE_DOCTOR_ERROR",
  DELETE_DOCTOR_RESET = "action/DELETE_DOCTOR_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteDoctorPayload {
  doctor_id: number;
}

export interface DeleteDoctorResponse extends IResponse {}
