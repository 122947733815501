import styled from "styled-components";
import { theme } from "theme/theme";
import { Modal } from "components";

export const Title = styled.div`
  font-family: Noto Sans JP;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
  color: #1a2f62;
`;

export const GroupInput = styled.div`
  display: flex;
  gap: 6px;

  button {
    height: 31px;
    margin-top: 20px;
  }
`;

export const WrapperFirstName = styled.div`
  display: flex;
  gap: 10px;

  button {
    height: 28px;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;

  button {
    width: 128px;
    line-height: 23px;
    padding: 8.5px 15px;
  }
`;

export const WrapperContentModal = styled.div`
  font-family: Noto Sans JP;
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 32px;
  }
`;

export const WrapperPhysicianManagement = styled.div`
  position: relative;
`;
