import { createApiCall, MethodType, URI } from "../Api";

import { IMonthSchedulesPayload } from "models/schedules/monthSchedules";
import { IWeekSchedulesPayload } from "models/schedules/weekSchedules";
import { IPostDoctorSchedulesPayload } from "models/schedules/postDoctorSchedules";
import { IDeleteSchedulePayload } from "models/schedules/deleteSchedule";
import { ICreateBulkSchedulesPayload } from "models/schedules/createBulkSchedules";

export const getMonthSchedules = (payload: IMonthSchedulesPayload) => {
  return createApiCall(MethodType.GET, URI.SCHEDULES.MONTH_SCHEDULES, payload);
};

export const getWeekSchedules = (payload: IWeekSchedulesPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.SCHEDULES.WEEK_SCHEDULES(payload.doctor_id),
    payload.payload
  );
};

export const postDoctorSchedules = (payload: IPostDoctorSchedulesPayload) => {
  return createApiCall(
    MethodType.POST,
    URI.SCHEDULES.POST_DOCTOR_SCHEDULES(payload.doctor_id),
    payload.payload
  );
};

export const deleteSchedule = (payload: IDeleteSchedulePayload) => {
  return createApiCall(
    MethodType.DELETE,
    URI.SCHEDULES.DELETE_SCHEDULE(payload.schedule_id)
  );
};

export const createBulkSchedules = (payload: ICreateBulkSchedulesPayload) => {
  return createApiCall(
    MethodType.POST,
    URI.SCHEDULES.CREATE_BULK_SCHEDULES,
    payload
  );
};
