import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DoctorAppointmentsResponse,
  IDoctorAppointmentsPayload,
} from "models/appointments/doctorAppointmentsList";
import { httpStatus } from "configs/httpStatus";
import { getDoctorAppointments } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./doctorAppointmentsActions";

function* getDoctorAppointmentsSaga({
  payload,
}: {
  payload: IDoctorAppointmentsPayload;
}) {
  try {
    const response: DoctorAppointmentsResponse = yield call(
      getDoctorAppointments,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDoctorAppointmentsWatcher() {
  yield takeLatest(
    ActionType.DOCTOR_APPOINTMENTS_LIST as any,
    getDoctorAppointmentsSaga
  );
}

export default [fork(onGetDoctorAppointmentsWatcher)];
