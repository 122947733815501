import {
  ActionType,
  UpdateMedicalRecordResponse,
} from "models/medicalRecords/updateMedicalRecord";

export const updateMedicalRecordAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_MEDICAL_RECORD,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_MEDICAL_RECORD_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: UpdateMedicalRecordResponse) => {
  return {
    type: ActionType.UPDATE_MEDICAL_RECORD_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_MEDICAL_RECORD_RESET,
  };
};
