import {
  IDeleteSchedulePayload,
  ActionType,
  DeleteScheduleResponse,
} from "models/schedules/deleteSchedule";

export const deleteScheduleAction = (payload: IDeleteSchedulePayload) => {
  return {
    type: ActionType.DELETE_SCHEDULE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_SCHEDULE_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DeleteScheduleResponse) => {
  return {
    type: ActionType.DELETE_SCHEDULE_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DELETE_SCHEDULE_RESET,
  };
};
