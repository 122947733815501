import {
  ActionType,
  IAppointmentsListPayload,
  AppointmentsListResponse,
} from "models/appointments/appointmentsList";

export const getAppointmentsListAction = (
  payload: IAppointmentsListPayload
) => {
  return {
    type: ActionType.APPOINTMENTS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPOINTMENTS_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: AppointmentsListResponse) => {
  return {
    type: ActionType.APPOINTMENTS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPOINTMENTS_LIST_RESET,
  };
};
