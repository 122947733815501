import { put, takeLatest, fork, call } from "redux-saga/effects";

import { ActionType, IDoctorsListResponse } from "models/doctor/doctorsList";
import { httpStatus } from "configs/httpStatus";
import { fetchDoctorList } from "services/doctor";

import { handleSuccesAction, handleErrorAction } from "./doctorsListActions";

function* getDoctorsListSaga({ payload }: any) {
  try {
    const response: IDoctorsListResponse = yield call(fetchDoctorList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDoctorsListWatcher() {
  yield takeLatest(ActionType.DOCTORS_LIST as any, getDoctorsListSaga);
}

export default [fork(onGetDoctorsListWatcher)];
