import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ICreateMedicineByIDResponse,
} from "models/medicine/createMedicineByID";
import { httpStatus } from "configs/httpStatus";
import { createMedicineByID } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./createByIDActions";

function* createMedicineByIDSaga({ payload }: { payload: any }) {
  try {
    const response: ICreateMedicineByIDResponse = yield call(
      createMedicineByID,
      payload
    );

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(
        handleSuccesAction({
          ...response,
          result: [],
        })
      );
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateMedicineByIDWatcher() {
  yield takeLatest(
    ActionType.CREATE_MEDICINE_BY_ID as any,
    createMedicineByIDSaga
  );
}

export default [fork(onCreateMedicineByIDWatcher)];
