import {
  IPostDoctorSchedulesPayload,
  ActionType,
  PostDoctorSchedulesResponse,
} from "models/schedules/postDoctorSchedules";

export const postDoctorSchedulesAction = (
  payload: IPostDoctorSchedulesPayload
) => {
  return {
    type: ActionType.POST_DOCTOR_SCHEDULES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.POST_DOCTOR_SCHEDULES_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: PostDoctorSchedulesResponse) => {
  return {
    type: ActionType.POST_DOCTOR_SCHEDULES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.POST_DOCTOR_SCHEDULES_RESET,
  };
};
