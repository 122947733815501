import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  MonthAppointmentsListResponse,
  IAppointmentsListPayload,
} from "models/appointments/monthAppointmentsList";
import { httpStatus } from "configs/httpStatus";
import { getAppointmentsList } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./monthAppointmentsActions";

function* getMonthAppointmentsListSaga({
  payload,
}: {
  payload: IAppointmentsListPayload;
}) {
  try {
    const response: MonthAppointmentsListResponse = yield call(
      getAppointmentsList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetMonthAppointmentsListWatcher() {
  yield takeLatest(
    ActionType.MONTH_APPOINTMENTS_LIST as any,
    getMonthAppointmentsListSaga
  );
}

export default [fork(onGetMonthAppointmentsListWatcher)];
