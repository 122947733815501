import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  CreateMedicalRecordResponse,
} from "models/medicalRecords/createMedicalRecord";
import { httpStatus } from "configs/httpStatus";
import { createMedicalRecord } from "services/medicalRecords";

import { handleSuccessAction, handleErrorAction } from "./createActions";

function* createMedicalRecordSaga({ payload }: any) {
  try {
    const response: CreateMedicalRecordResponse = yield call(
      createMedicalRecord,
      payload
    );
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateMedicalRecordWatcher() {
  yield takeLatest(
    ActionType.CREATE_MEDICAL_RECORD as any,
    createMedicalRecordSaga
  );
}

export default [fork(onCreateMedicalRecordWatcher)];
