import {
  ActionType,
  IDoctorAppointmentsPayload,
  DoctorAppointmentsResponse,
} from "models/appointments/doctorAppointmentsList";

export const getDoctorAppointmentsAction = (
  payload: IDoctorAppointmentsPayload
) => {
  return {
    type: ActionType.DOCTOR_APPOINTMENTS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DOCTOR_APPOINTMENTS_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DoctorAppointmentsResponse) => {
  return {
    type: ActionType.DOCTOR_APPOINTMENTS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DOCTOR_APPOINTMENTS_LIST_RESET,
  };
};
