import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AppointmentUpdateStatusConsultationResponse,
} from "models/appointments/updateStatusConsultation";
import { httpStatus } from "configs/httpStatus";
import { updateStatusConsultation } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./updateStatusConsultationActions";

function* updateStatusConsultationAppointmentSaga({
  payload,
}: {
  payload: any;
}) {
  try {
    const response: AppointmentUpdateStatusConsultationResponse = yield call(
      updateStatusConsultation,
      payload
    );
    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateStatusConsultationAppointmentSagaWatcher() {
  yield takeLatest(
    ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION as any,
    updateStatusConsultationAppointmentSaga
  );
}

export default [fork(onUpdateStatusConsultationAppointmentSagaWatcher)];
