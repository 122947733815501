import { IResponse } from "models";

export enum ActionType {
  POST_DOCTOR_SCHEDULES = "action/POST_DOCTOR_SCHEDULES",
  POST_DOCTOR_SCHEDULES_SUCCESS = "action/POST_DOCTOR_SCHEDULES_SUCCESS",
  POST_DOCTOR_SCHEDULES_ERROR = "action/POST_DOCTOR_SCHEDULES_ERROR",
  POST_DOCTOR_SCHEDULES_RESET = "action/POST_DOCTOR_SCHEDULES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IPostDoctorSchedulesPayload {
  doctor_id: number;
  payload: {
    schedule: {
      schedule_date: string;
      start_time: string;
      end_time: string;
    };
  };
}

export interface PostDoctorSchedulesResponse extends IResponse {}
