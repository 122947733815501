import {
  Action,
  ActionType,
  MedicalRecordResponse,
} from "models/medicalRecords/medicalRecord";
import createReducer from "../../createReducer";

export interface MedicalRecordReducerType {
  loading: boolean;
  error?: string;
  medicalRecordResponse?: MedicalRecordResponse;
}

const defaultState: MedicalRecordReducerType = {
  loading: false,
  error: undefined,
  medicalRecordResponse: undefined,
};

export const medicalRecordReducer = createReducer<MedicalRecordReducerType>(
  defaultState,
  {
    [ActionType.GET_MEDICAL_RECORD](state: MedicalRecordReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_MEDICAL_RECORD_ERROR](
      state: MedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        medicalRecordResponse: undefined,
      };
    },

    [ActionType.GET_MEDICAL_RECORD_SUCCESS](
      state: MedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        medicalRecordResponse: action.payload,
      };
    },

    [ActionType.GET_MEDICAL_RECORD_RESET](state: MedicalRecordReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        medicalRecordResponse: undefined,
      };
    },
  }
);
