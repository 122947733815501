import { IResponse } from "models";

export enum ActionType {
  DELETE_SCHEDULE = "action/DELETE_SCHEDULE",
  DELETE_SCHEDULE_SUCCESS = "action/DELETE_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE_ERROR = "action/DELETE_SCHEDULE_ERROR",
  DELETE_SCHEDULE_RESET = "action/DELETE_SCHEDULE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteSchedulePayload {
  schedule_id: number;
}

export interface DeleteScheduleResponse extends IResponse {}
