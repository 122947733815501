import * as React from "react";

function BackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.03206 0.000448097H0.906555C0.667575 0.00509072 0.439699 0.102107 0.270683 0.271157C0.101667 0.440207 0.00464169 0.668156 0 0.907184C0 1.14767 0.0955249 1.3783 0.265537 1.54834C0.435549 1.71839 0.666122 1.81392 0.906555 1.81392H8.03206C8.461 1.80012 8.88805 1.8766 9.28555 2.03839C9.68305 2.20018 10.0421 2.44368 10.3396 2.75313C10.637 3.06257 10.8661 3.43106 11.0121 3.83471C11.158 4.23835 11.2176 4.66816 11.1869 5.0963C11.203 5.94546 10.9207 6.77336 10.3891 7.43568C10.0954 7.76825 9.73177 8.03176 9.32428 8.20732C8.9168 8.38289 8.4755 8.46617 8.03206 8.45122H4.40584L6.61784 6.23879C6.78855 6.06805 6.88448 5.83647 6.88448 5.59501C6.88448 5.35354 6.78855 5.12196 6.61784 4.95122C6.44713 4.78048 6.21564 4.68456 5.97422 4.68456C5.73281 4.68456 5.50126 4.78048 5.33055 4.95122L1.5774 8.7051C1.41212 8.88205 1.32126 9.11581 1.32359 9.35795C1.32217 9.59455 1.41323 9.82234 1.5774 9.99268L5.33055 13.7466C5.5035 13.9202 5.73828 14.018 5.9833 14.0186C6.22242 14.0152 6.45046 13.9174 6.61784 13.7466C6.7851 13.574 6.87867 13.3431 6.87867 13.1028C6.87867 12.8624 6.7851 12.6316 6.61784 12.459L4.40584 10.2647H8.03206C8.73777 10.2821 9.43865 10.1432 10.0844 9.85789C10.7301 9.57259 11.3047 9.14796 11.7671 8.61444C12.5795 7.62355 13.0161 6.37764 13 5.0963C13.0299 4.4299 12.9228 3.76446 12.6854 3.14109C12.4479 2.51773 12.0852 1.94969 11.6196 1.4721C11.154 0.994496 10.5954 0.617478 9.97836 0.364354C9.3613 0.111231 8.69887 -0.0126207 8.03206 0.000448097Z"
        fill="#2F8CAE"
      />
    </svg>
  );
}

export default BackIcon;
