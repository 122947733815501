import * as React from "react";

function ReloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.6672 16.5997L21 10.8566L18.4728 11.0761C18.4728 11.0541 18.4709 11.0322 18.469 11.0102C17.9887 7.01322 14.6753 4 10.7635 4C6.48187 4 3 7.58793 3 12C3 16.4121 6.48187 20 10.7635 20C12.0261 20 13.281 19.6807 14.3906 19.0741C15.0045 18.7388 15.2388 17.9546 14.9154 17.32C14.5901 16.6875 13.829 16.446 13.2132 16.7792C12.4541 17.1923 11.6311 17.4038 10.7635 17.4038C7.87036 17.4038 5.51748 14.9793 5.51748 11.998C5.51748 9.01671 7.87036 6.59416 10.7635 6.59416C13.3972 6.59416 15.6261 8.61162 15.9631 11.2936L13.4514 11.5111L17.6653 16.6016L17.6672 16.5997Z"
        fill="#2F8CAE"
      />
    </svg>
  );
}

export default ReloadIcon;
