import React from "react";
import styled from "styled-components";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

interface IInput {
  label?: React.ReactNode;
  height?: number;
  width?: number | string;
  fs?: number;
  fw?: number;
  lh?: string;
  fsLabel?: number; // font size
  fwLabel?: number; // font weight
  lhLabel?: string; // line height
  value?: string;
  name?: string;
  type?: string;
  onChange?: any;
  error?: string;
  marginForm?: string;
  bdr?: any; // border-radius
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: any;
  maxLength?: any;
  autoComplete?: string;
  defaultValue?: any;
  padding?: string;
  style?: any;
  className?: any;
}

const FormControl = styled.div<IInput>(({ marginForm }) => ({
  position: "relative",
  margin: marginForm,
}));

const Label = styled.div<IInput>(({ theme, fsLabel, fwLabel, lhLabel }) => ({
  color: theme.colors.text.primary,
  fontSize: fsLabel || theme.sizes.xs,
  fontWeight: fwLabel || 400,
  lineHeight: lhLabel || "12px",
  marginBottom: 8,
}));

const InputStyled = styled.input<IInput>(
  ({ theme, width, height, fs, fw, bdr, padding, lh }) => ({
    height: height || 40,
    width: width || "100%",
    borderRadius: bdr || 6,
    boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.09)",
    border: `1px solid ${theme.colors.border}`,
    fontSize: fs || theme.sizes.sm,
    fontWeight: fw || theme.fontWeight.medium,
    lineHeight: lh || "20px",
    padding: padding || "0px 11px",
    outline: "none",
    ":focus": {
      border: `1px solid ${theme.colors.outline}`,
    },
    "::placeholder": {
      color: theme.colors.icon,
    },
  })
);

const Input: React.FC<IInput> = ({
  label,
  error,
  fsLabel,
  fwLabel,
  lhLabel,
  marginForm,
  defaultValue,
  autoComplete,
  className,
  ...rest
}) => {
  return (
    <FormControl marginForm={marginForm} className={className}>
      {label && (
        <Label fsLabel={fsLabel} fwLabel={fwLabel} lhLabel={lhLabel}>
          {label}
        </Label>
      )}
      <InputStyled
        {...rest}
        spellCheck="false"
        autoComplete={autoComplete}
        defaultValue={defaultValue}
      />
      {error && (
        <WrapperErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperErrorMessage>
      )}
    </FormControl>
  );
};

export default Input;
