import {
  IGetMedicineListResponse,
  Action,
  ActionType,
} from "models/medicine/getMedicineList";
import createReducer from "../../createReducer";

export interface GetMedicineListReducerType {
  loading: boolean;
  error?: string;
  medicineListResponse?: IGetMedicineListResponse;
}

const defaultState: GetMedicineListReducerType = {
  loading: false,
  error: "",
  medicineListResponse: undefined,
};

export const getMedicineListReducer = createReducer<GetMedicineListReducerType>(
  defaultState,
  {
    [ActionType.GET_MEDICINE_LIST](state: GetMedicineListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_MEDICINE_LIST_ERROR](
      state: GetMedicineListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_MEDICINE_LIST_SUCCESS](
      state: GetMedicineListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        medicineListResponse: action.payload,
      };
    },
  }
);
