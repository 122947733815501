import { IResponse } from "models";

export enum ActionType {
  CREATE_MEDICAL_PLAN = "action/CREATE_MEDICAL_PLAN",
  CREATE_MEDICAL_PLAN_SUCCESS = "action/CREATE_MEDICAL_PLAN_SUCCESS",
  CREATE_MEDICAL_PLAN_ERROR = "action/CREATE_MEDICAL_PLAN_ERROR",
  CREATE_MEDICAL_PLAN_RESET = "action/CREATE_MEDICAL_PLAN_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateMedicalPlanPayload {
  medical_record_id: number;
  plan: any;
}

export interface CreateMedicalPlanResponse extends IResponse {
  result: any;
}
