import {
  IGetMedicineListByIDResponse,
  Action,
  ActionType,
} from "models/medicine/getMedicineListByID";
import createReducer from "../../createReducer";

export interface GetMedicineListByIDReducerType {
  loading: boolean;
  error?: string;
  getMedicineListByIDResponse?: IGetMedicineListByIDResponse;
}

const defaultState: GetMedicineListByIDReducerType = {
  loading: false,
  error: "",
  getMedicineListByIDResponse: undefined,
};

export const getMedicineListByIDReducer =
  createReducer<GetMedicineListByIDReducerType>(defaultState, {
    [ActionType.GET_MEDICINE_LIST_BY_ID](
      state: GetMedicineListByIDReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_MEDICINE_LIST_BY_ID_ERROR](
      state: GetMedicineListByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_MEDICINE_LIST_BY_ID_SUCCESS](
      state: GetMedicineListByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        getMedicineListByIDResponse: action.payload,
      };
    },
  });
