import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  WeekSchedulesResponse,
  IWeekSchedulesPayload,
} from "models/schedules/weekSchedules";
import { httpStatus } from "configs/httpStatus";
import { getWeekSchedules } from "services/schedules";

import { handleSuccessAction, handleErrorAction } from "./weekSchedulesActions";

function* getWeekSchedulesSaga({
  payload,
}: {
  payload: IWeekSchedulesPayload;
}) {
  try {
    const response: WeekSchedulesResponse = yield call(
      getWeekSchedules,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetWeekSchedulesWatcher() {
  yield takeLatest(ActionType.WEEK_SCHEDULE_LIST as any, getWeekSchedulesSaga);
}

export default [fork(onGetWeekSchedulesWatcher)];
