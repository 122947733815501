import {
  Action,
  ActionType,
  ClinicalDepartmentListResponse,
} from "models/medicalRecords/clinicalDepartmentList";
import createReducer from "../../createReducer";

export interface ClinicalDepartmentListReducerType {
  loading: boolean;
  error?: string;
  clinicalDepartmentListResponse?: ClinicalDepartmentListResponse;
}

const defaultState: ClinicalDepartmentListReducerType = {
  loading: false,
  error: undefined,
  clinicalDepartmentListResponse: undefined,
};

export const clinicalDepartmentListReducer =
  createReducer<ClinicalDepartmentListReducerType>(defaultState, {
    [ActionType.GET_CLINICAL_DEPARTMENT_LIST](
      state: ClinicalDepartmentListReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_CLINICAL_DEPARTMENT_LIST_ERROR](
      state: ClinicalDepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        clinicalDepartmentListResponse: undefined,
      };
    },

    [ActionType.GET_CLINICAL_DEPARTMENT_LIST_SUCCESS](
      state: ClinicalDepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        clinicalDepartmentListResponse: action.payload,
      };
    },

    [ActionType.GET_CLINICAL_DEPARTMENT_LIST_RESET](
      state: ClinicalDepartmentListReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        clinicalDepartmentListResponse: undefined,
      };
    },
  });
