import {
  Action,
  ActionType,
  CreateMedicalPlanResponse,
} from "models/medicalPlans/createMedicalPlan";
import createReducer from "../../createReducer";

export interface CreateMedicalPlanReducerType {
  loading: boolean;
  error?: string;
  createMedicalPlanResponse?: CreateMedicalPlanResponse;
}

const defaultState: CreateMedicalPlanReducerType = {
  loading: false,
  error: undefined,
  createMedicalPlanResponse: undefined,
};

export const createMedicalPlanReducer =
  createReducer<CreateMedicalPlanReducerType>(defaultState, {
    [ActionType.CREATE_MEDICAL_PLAN](state: CreateMedicalPlanReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_MEDICAL_PLAN_ERROR](
      state: CreateMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        createMedicalPlanResponse: undefined,
      };
    },

    [ActionType.CREATE_MEDICAL_PLAN_SUCCESS](
      state: CreateMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicalPlanResponse: action.payload,
      };
    },

    [ActionType.CREATE_MEDICAL_PLAN_RESET](
      state: CreateMedicalPlanReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicalPlanResponse: undefined,
      };
    },
  });
