import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  MedicalRecordResponse,
  IMedicalRecordPayload,
} from "models/medicalRecords/medicalRecord";
import { httpStatus } from "configs/httpStatus";
import { getMedicalRecord } from "services/medicalRecords";

import { handleSuccessAction, handleErrorAction } from "./medicalRecordActions";

function* getMedicalRecordSaga({
  payload,
}: {
  payload: IMedicalRecordPayload;
}) {
  try {
    const response: MedicalRecordResponse = yield call(
      getMedicalRecord,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetMedicalRecordWatcher() {
  yield takeLatest(ActionType.GET_MEDICAL_RECORD as any, getMedicalRecordSaga);
}

export default [fork(onGetMedicalRecordWatcher)];
