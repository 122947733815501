import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IDeleteDoctorPayload,
  ActionType,
  DeleteDoctorResponse,
} from "models/doctor/deleteDoctor";
import { httpStatus } from "configs/httpStatus";
import { deleteDoctor } from "services/doctor";
import { handleSuccessAction, handleErrorAction } from "./deleteDoctorActions";

function* deleteDoctorSaga({ payload }: { payload: IDeleteDoctorPayload }) {
  try {
    const response: DeleteDoctorResponse = yield call(deleteDoctor, payload);

    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDeleteDoctorSagaSubmitWatcher() {
  yield takeLatest(ActionType.DELETE_DOCTOR as any, deleteDoctorSaga);
}

export default [fork(onDeleteDoctorSagaSubmitWatcher)];
