import { IResponse } from "models";

export enum ActionType {
  DELETE_MEDICINE_BY_ID = "action/DELETE_MEDICINE_BY_ID",
  DELETE_MEDICINE_BY_ID_SUCCESS = "action/DELETE_MEDICINE_BY_ID_SUCCESS",
  DELETE_MEDICINE_BY_ID_ERROR = "action/DELETE_MEDICINE_BY_ID_ERROR",
  DELETE_MEDICINE_BY_ID_RESET = "action/DELETE_MEDICINE_BY_ID_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteMedicineByIDResponse extends IResponse {
  result: any;
}
