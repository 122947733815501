import {
  ICreateMedicineByIDResponse,
  Action,
  ActionType,
} from "models/medicine/createMedicineByID";
import createReducer from "../../createReducer";

export interface CreateMedicineListByIDReducerType {
  loading: boolean;
  error?: string;
  createMedicineByIDResponse?: ICreateMedicineByIDResponse;
}

const defaultState: CreateMedicineListByIDReducerType = {
  loading: false,
  error: "",
  createMedicineByIDResponse: undefined,
};

export const createMedicineByIDReducer =
  createReducer<CreateMedicineListByIDReducerType>(defaultState, {
    [ActionType.CREATE_MEDICINE_BY_ID](
      state: CreateMedicineListByIDReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_MEDICINE_BY_ID_ERROR](
      state: CreateMedicineListByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_MEDICINE_BY_ID_SUCCESS](
      state: CreateMedicineListByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicineByIDResponse: action.payload,
      };
    },

    [ActionType.CREATE_MEDICINE_BY_ID_RESET](
      state: CreateMedicineListByIDReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicineByIDResponse: undefined,
      };
    },
  });
