import {
  Action,
  ActionType,
  WeekSchedulesResponse,
} from "models/schedules/weekSchedules";
import createReducer from "../../createReducer";

export interface WeekSchedulesReducerType {
  loading: boolean;
  error?: string;
  weekSchedulesResponse?: WeekSchedulesResponse;
}

const defaultState: WeekSchedulesReducerType = {
  loading: false,
  error: undefined,
  weekSchedulesResponse: undefined,
};

export const weekSchedulesReducer = createReducer<WeekSchedulesReducerType>(
  defaultState,
  {
    [ActionType.WEEK_SCHEDULE_LIST](state: WeekSchedulesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.WEEK_SCHEDULE_LIST_ERROR](
      state: WeekSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        weekSchedulesResponse: undefined,
      };
    },

    [ActionType.WEEK_SCHEDULE_LIST_SUCCESS](
      state: WeekSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        weekSchedulesResponse: action.payload,
      };
    },

    [ActionType.WEEK_SCHEDULE_LIST_RESET](state: WeekSchedulesReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        weekSchedulesResponse: undefined,
      };
    },
  }
);
