import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICreateBulkSchedulesPayload,
  ActionType,
  CreateBulkSchedulesResponse,
} from "models/schedules/createBulkSchedules";
import { httpStatus } from "configs/httpStatus";
import { createBulkSchedules } from "services/schedules";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./createBulkSchedulesActions";

function* createBulkSchedulesSaga({
  payload,
}: {
  payload: ICreateBulkSchedulesPayload;
}) {
  try {
    const response: CreateBulkSchedulesResponse = yield call(
      createBulkSchedules,
      payload
    );

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateBulkSchedulesSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.CREATE_BULK_SCHEDULES as any,
    createBulkSchedulesSaga
  );
}

export default [fork(onCreateBulkSchedulesSagaSubmitWatcher)];
