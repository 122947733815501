import { IResponse } from "models";

export enum ActionType {
  CREATE_MEDICAL_RECORD = "action/CREATE_MEDICAL_RECORD",
  CREATE_MEDICAL_RECORD_SUCCESS = "action/CREATE_MEDICAL_RECORD_SUCCESS",
  CREATE_MEDICAL_RECORD_ERROR = "action/CREATE_MEDICAL_RECORD_ERROR",
  CREATE_MEDICAL_RECORDD_RESET = "action/CREATE_MEDICAL_RECORDD_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CreateMedicalRecordResponse extends IResponse {
  result: any;
}
