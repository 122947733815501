import {
  ISubSearchMedicineResponse,
  ActionType,
} from "models/medicine/subSearchMedicine";

export const searchMedicineAction = (payload?: any) => {
  return {
    type: ActionType.SUB_SEARCH_MEDICINE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SUB_SEARCH_MEDICINE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ISubSearchMedicineResponse) => {
  return {
    type: ActionType.SUB_SEARCH_MEDICINE_SUCCESS,
    payload,
  };
};
