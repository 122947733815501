import * as React from "react";

function MinusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.58301 8.41602H11.083C11.2377 8.41602 11.3861 8.47747 11.4955 8.58687C11.6049 8.69627 11.6663 8.84464 11.6663 8.99935C11.6663 9.15406 11.6049 9.30243 11.4955 9.41183C11.3861 9.52122 11.2377 9.58268 11.083 9.58268H7.58301H6.41634H2.91634C2.76163 9.58268 2.61326 9.52122 2.50386 9.41183C2.39447 9.30243 2.33301 9.15406 2.33301 8.99935C2.33301 8.84464 2.39447 8.69627 2.50386 8.58687C2.61326 8.47747 2.76163 8.41602 2.91634 8.41602H6.41634H7.58301Z"
        fill="#FB4E4E"
      />
    </svg>
  );
}

export default MinusIcon;
