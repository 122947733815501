import * as React from "react";

function DeleteCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="16" height="16" rx="8" fill="white" />
      <rect x="0.5" y="0.5" width="16" height="16" rx="8" stroke="#FB4E4E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7304 5.57124C13.0899 5.21179 13.0899 4.62902 12.7304 4.26958C12.371 3.91014 11.7882 3.91014 11.4287 4.26958L8.49998 7.19832L5.5713 4.26967C5.21185 3.91023 4.62908 3.91023 4.26963 4.26967C3.91019 4.62911 3.91019 5.21188 4.26963 5.57132L7.19831 8.49998L4.26958 11.4287C3.91014 11.7881 3.91014 12.3709 4.26958 12.7303C4.62903 13.0898 5.2118 13.0898 5.57125 12.7303L8.49998 9.80163L11.4288 12.7304C11.7882 13.0899 12.371 13.0899 12.7305 12.7304C13.0899 12.371 13.0899 11.7882 12.7305 11.4288L9.80164 8.49998L12.7304 5.57124Z"
        fill="#FB4E4E"
      />
    </svg>
  );
}

export default DeleteCircle;
