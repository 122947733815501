import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ICreateMedicineResponse,
} from "models/medicine/createMedicine";
import { httpStatus } from "configs/httpStatus";
import { createMedicine } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./createActions";

function* createMedicineSaga({ payload }: { payload: any }) {
  try {
    const response: ICreateMedicineResponse = yield call(
      createMedicine,
      payload
    );

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateMedicineWatcher() {
  yield takeLatest(ActionType.CREATE_MEDICINE as any, createMedicineSaga);
}

export default [fork(onCreateMedicineWatcher)];
