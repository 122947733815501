import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Input, Button, Box } from "components";
import { SendCompleteIcon } from "icons";
import { localStorageHelper } from "utils";
import { PASSWORD_REGEX } from "constant";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

import {
  WrapperSection,
  WrapperTitle,
  FormTitle,
  PasswordContainer,
  FormWrapper,
} from "screens/changePassword/ChangePassword.style";

const schema = yup.object().shape({
  oldPassword: yup.string().required("current-password-required"),
  newPassword: yup
    .string()
    .required("new-password-required")
    .matches(PASSWORD_REGEX, "password-requirements"),
  confirmPassword: yup
    .string()
    .required("confirm-password-required")
    .oneOf([yup.ref("newPassword")], "not-match"),
});

const GeneralSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });

  const dispatch = useDispatch();
  const changePasswordSaved = false;

  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [errorPassword, setErrorPassword] = useState<string>("");

  const watchOldPassword = watch("oldPassword");

  const onSubmitPasswords = (data: any) => {
    const params = {
      current_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_new_password: data.confirmPassword,
    };
    console.log("password", data);
    // dispatch(ChangePasswordActionCreators.changePasswordAction(params));
  };

  useEffect(() => {
    if (changePasswordSaved) {
      notification.success({
        placement: "bottomRight",
        message: t("general-setting-apply"),
        icon: <SendCompleteIcon />,
      });
      setValue("oldPassword", "");
      setValue("newPassword", "");
      setValue("confirmPassword", "");
      //   dispatch(ChangePasswordActionCreators.handleResetDataAction());
    }
  }, [changePasswordSaved]);

  //   useEffect(() => {
  //     if (ChangePasswordErrorMessage) {
  //       setErrorPassword(ChangePasswordErrorMessage);
  //       dispatch(ChangePasswordActionCreators.handleResetDataAction());
  //     }
  //   }, [ChangePasswordErrorMessage]);

  useEffect(() => {
    if (watchOldPassword) {
      setErrorPassword("");
    }
  }, [watchOldPassword]);

  useEffect(() => {
    setErrorPassword("");
    // dispatch(ChangePasswordActionCreators.handleResetDataAction());
  }, []);

  return (
    <Box title={t1("change-password-label")} padding="24px">
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmitPasswords)}>
          <PasswordContainer>
            <div style={{ position: "relative" }}>
              <Controller
                control={control}
                name="oldPassword"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    label={<>{t("old-password")}</>}
                    height={32}
                    fsLabel={16}
                    marginForm="0 0 16px 0"
                    lhLabel="16px"
                    value={value}
                    onChange={onChange}
                    error={error && t(error.message)}
                    type="password"
                  />
                )}
              />
              {errorPassword && (
                <WrapperErrorMessage style={{ position: "relative", top: -10 }}>
                  <ErrorMessage>{errorPassword}</ErrorMessage>
                </WrapperErrorMessage>
              )}
            </div>
            <Controller
              control={control}
              name="newPassword"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  label={<>{t("new-password")}</>}
                  height={32}
                  fsLabel={16}
                  lhLabel="16px"
                  marginForm="0 0 16px 0"
                  value={value}
                  onChange={onChange}
                  error={error && t(error.message)}
                  type="password"
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  label={<>{t("confirm-password")}</>}
                  height={32}
                  fsLabel={16}
                  lhLabel="16px"
                  marginForm="0 0 0 0"
                  value={value}
                  onChange={onChange}
                  error={error && t(error.message)}
                  type="password"
                />
              )}
            />
          </PasswordContainer>
          <Button
            type="submit"
            name={t("keep")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            padding="4px 16px"
            margin="32px 0px 0px"
            width="100%"
          />
        </form>
      </FormWrapper>
    </Box>
  );
};

export default GeneralSetting;
