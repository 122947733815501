import React, { useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "antd";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  CalendarApi,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin from "@fullcalendar/interaction";
// import { INITIAL_EVENTS, createEventId } from "./event-utils";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput } from "@fullcalendar/react";
import moment from "moment";

import { Button, Select, Box, Input, TextArea } from "components";
import { AttentionIcon, ResizeIcon, LeftArrow, CloseIcon } from "icons";
import {
  selectIsLoading,
  selectErrorMessage,
} from "redux/authentication/authenticationStates";
import {
  WrapperSchedule,
  Alert,
  AlertMobile,
  Tooltip4AlertMobile,
  ContentWrapper,
  ScheduleTableStyled,
  WrapperFullCalendar,
  WrapperModal,
  ScheduleInfo,
  DoctorName,
  Time,
  Results,
  ScheduleInfoTop,
  ScheduleInfoBottom,
  WrapperBtnFooter,
  Line,
  WrapperInfo,
  WrapperCard,
  Card,
  GroupCards,
  Link,
  Bold,
  WrapperDoctor,
  ScheduleTitle,
  ScheduleNotiWrapper,
  ModalStyled,
  WrapperScheduleResize,
  ResizeWrapper,
} from "./Schedule.style";
import { Modal } from "./components";
registerLocale("ja", ja);

let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
export const createEventId = () => String(eventGuid++);
export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:45:00",
  },
];

const { useBreakpoint } = Grid;

const datas: any = [];
for (let i = 0; i < 5; i++) {
  datas[i] = {
    date_of_reservation: "8/2（水）19:00-19:15",
    reserved_person_name:
      i % 2
        ? "松原幸之助（まつばら こうのすけ）"
        : "グエンゴックチャンチャウ（ぐえんごっく ちゃんちゃう）",
    age: `${i}歳`,
    gender: "F",
    consult_content: "発熱、喉の痛みと腹痛がある",
    hospital_name: "茅野〇〇小児科医院",
    video_link: "meet",
    status: "診療開始前",
  };
}

const ScheduleManagement: React.FC = () => {
  const isLoading = useSelector(selectIsLoading);

  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const [isVisible4Schedule, setIsVisible4Schedule] = useState(false);
  const [isVisible4UserInfo, setIsVisible4UserInfo] = useState(false);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showCloseTooltip, setShowCloseTooltip] = useState<boolean>(false);
  const [showResizeModal, setShowResizeModal] = useState<boolean>(false);
  const calendarRef = useRef<any>(null);
  const handleEvents = useCallback(
    (events: EventApi[]) => setCurrentEvents(events),
    []
  );

  const returnToday = () => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.today();
    }
  };

  const handleToggleTooltip = () => {
    setIsVisibleTooltip(!isVisibleTooltip);
  };

  const screens = useBreakpoint();

  const onChangeCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const returnGender = (gender: string) => {
    if (gender === "F") {
      return "女";
    }
    return "男";
  };

  const showWarning = (mode: number, temperature?: number) => {
    let themeScheme: {
      message: string;
      textColor: string;
      color: string;
      btnName: string;
      mode: number;
    } = {
      message: "",
      color: "",
      textColor: "",
      btnName: "",
      mode: 0,
    };
    if (temperature) {
      return "";
    }
    switch (mode) {
      case 1:
        themeScheme = {
          message:
            "オンライン診療を開始する準備ができましたら、右の開始ボタンを押してください",
          color: "#2AC769",
          textColor: "#3B3B3B",
          btnName: "開　始",
          mode: 1,
        };
        break;
      case 2:
        themeScheme = {
          message:
            "患者様とオンライン診療への同意がとれた場合は、右の「同意」ボタンを押してください。",
          color: "#FB4E4E",
          textColor: "#FB4E4E",
          btnName: "同　意",
          mode: 2,
        };
        break;
      case 3:
        themeScheme = {
          message: "オンライン診療への同意済み",
          color: "#B7B7B7",
          textColor: "#B7B7B7",
          btnName: "同　意",
          mode: 3,
        };
        break;
      default:
        return;
    }
    const Message = () => {
      //   return (
      //     <Trans i18nKey="body-temperature.warning-first">
      //       {{ percent: temperature }}
      //     </Trans>
      //   );

      return <div>20:30に大島大輔さんからの予約が入りました。</div>;
    };
    return (
      <React.Fragment>
        <ScheduleNotiWrapper
          style={{
            padding:
              themeScheme.mode !== 3 ? "3px 4px 4px 8px" : "11px 16px 10px",
          }}
        >
          <div
            className="title"
            style={{
              color: themeScheme.textColor,
              fontSize: themeScheme.mode !== 3 ? 14 : 18,
            }}
          >
            {themeScheme.message}
          </div>
          {themeScheme.mode !== 3 && (
            <div className="button-wrapper">
              <Button
                onClick={() => console.log("clicked")}
                background="#E0E0E0"
                color="#fff"
                name={"キャンセル"}
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                padding="13px 24px 11px"
                fontWeight={700}
              />

              <Button
                onClick={() => console.log("clicked")}
                background={themeScheme.color}
                color="#fff"
                name={themeScheme.btnName}
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                padding="13px 32px 11px"
                fontWeight={700}
              />
            </div>
          )}
        </ScheduleNotiWrapper>
      </React.Fragment>
    );
  };

  const columns = [
    {
      title: "予約日時",
      dataIndex: "date_of_reservation",
      key: "date_of_reservation",
      width: 193,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "予約者名（ふりがな）",
      dataIndex: "reserved_person_name",
      key: "reserved_person_name",
      width: 238,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return (
          <div>
            {text}
            <span>様</span>
          </div>
        );
      },
    },
    {
      title: "年齢",
      dataIndex: "age",
      key: "age",
      width: 64,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "性別",
      dataIndex: "gender",
      key: "gender",
      width: 64,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{returnGender(text)}</div>;
      },
    },
    {
      title: "相談内容",
      dataIndex: "consult_content",
      key: "consult_content",
      width: 324,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "病院名",
      dataIndex: "hospital_name",
      key: "hospital_name",
      width: 144,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      show: true,
      render: () => {
        return <LeftArrow width={32} height={32} />;
      },
    },
  ];

  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    setIsVisible4Schedule(true);
    const title = prompt("イベントのタイトルを入力してください")?.trim();
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  }, []);
  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    if (
      window.confirm(`このイベント「${clickInfo.event.title}」を削除しますか`)
    ) {
      clickInfo.event.remove();
    }
  }, []);

  const handleDayCell = useCallback((info: any) => {
    const day = moment(info.date).format("D");

    const originElement = info.el.querySelectorAll(".fc-daygrid-day-number");
    originElement.forEach((e: any) => e.classList.add("d-none"));

    const targetElement = info.el.querySelectorAll(".fc-daygrid-day-top");
    targetElement.forEach((e: any) => (e.innerHTML = day));
  }, []);

  return (
    <WrapperSchedule>
      {showWarning(2)}
      <Box
        title="スケジュール"
        padding={screens.md ? "16px" : "8px"}
        style={{ marginBottom: 24 }}
      >
        <WrapperFullCalendar displayMode="month">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            customButtons={{
              today: {
                text: "本日",
                click: function () {
                  returnToday();
                },
              },
            }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek",
            }}
            initialView="dayGridMonth"
            selectable={true}
            editable={true}
            initialEvents={INITIAL_EVENTS}
            locales={allLocales}
            locale="ja"
            eventsSet={handleEvents}
            select={handleDateSelect}
            eventClick={handleEventClick}
            dayCellDidMount={handleDayCell}
            // slotDuration={"00:15:00"}
            // slotLabelInterval={15}
            // slotLabelFormat="hh:mm"
          />
        </WrapperFullCalendar>
        <Modal
          title="患者情報"
          visible={isVisible4UserInfo}
          footer={<></>}
          onCancel={() => setIsVisible4UserInfo(false)}
        >
          <WrapperInfo>
            保険者番号：12345678 / 氏名：王子田 佐和子（おうしでん さわこ）様 /
            年齢：3歳 / 性別：女 / 生年月日：2020年2月23日
          </WrapperInfo>
          <div style={{ display: "flex", gap: 8 }}>
            <WrapperInfo style={{ flex: 2 }}>
              <div>郵便番号：111-1111</div>
              <div>住所：東京都台東区浅草1-1-1 ハイツ浅草101</div>
              <div>電話番号：000-0000-0000</div>
              <div>
                メールアドレス：<Link>aaaa@leber.jp</Link>
              </div>
            </WrapperInfo>
            <GroupCards>
              <WrapperCard>
                <Card>
                  <div>保険証登録</div>
                  <div></div>
                </Card>
                <Card>
                  <div>身分証登録</div>
                  <div></div>
                </Card>
              </WrapperCard>
              <div
                style={{
                  fontSize: 12,
                  lineHeight: "17px",
                  marginTop: 8,
                }}
              >
                公的助成受給書等
              </div>
              <WrapperCard>
                <Card>
                  <div></div>
                  <div></div>
                </Card>
                <Card>
                  <div></div>
                  <div></div>
                </Card>
              </WrapperCard>
            </GroupCards>
          </div>
          <WrapperInfo>
            <Bold>病歴</Bold>
            <div>
              病名：かぜ / 罹患した時期：2020年12月 / ステータス：治っていない
            </div>
            <div>
              病名：カウザルギー / 罹患した時期：2020年12月 / ステータス：治療中
            </div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>病院から処方されている薬</Bold>
            <div>・トウキ</div>
            <div>・トコン</div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>アレルギー</Bold>
            <div>・オオブタクサ</div>
            <div>・豚肉</div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>身体情報</Bold>
            <div>身長（cm）：170 / 体重（kg）：72</div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>飲酒歴</Bold>
            <div>頻度：週に1回程度 / 種類：ビール / 量：750ml</div>
            <div>頻度：月に1回程度 / 種類：その他 / 量：125ml（1杯）</div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>喫煙歴</Bold>
            <div>
              頻度：毎日 / 喫煙歴（年数）：20年以上 /
              1日に吸う本数：20本から30本
            </div>
          </WrapperInfo>
        </Modal>
        <Modal
          title="2023年8月4日（金）"
          visible={isVisible4Schedule}
          footer={<></>}
          onCancel={() => setIsVisible4Schedule(false)}
        >
          <WrapperModal>
            <ScheduleInfo>
              <ScheduleInfoTop>
                <DoctorName>高梨りほ医師</DoctorName>
                <Time>
                  <div>開始時間</div>
                  <Select />
                  <div>時</div>
                  <Select />
                  <div>分</div>
                  <div style={{ marginLeft: 16 }}>開始時間</div>
                  <Select />
                  <div>時</div>
                  <Select />
                  <div>分</div>
                  <Button
                    // onClick={ok}
                    background="#2AC769"
                    color="#fff"
                    name="追加"
                    border="none"
                    bdr="6px"
                    fontSize={16}
                    fontWeight={700}
                  />
                </Time>
              </ScheduleInfoTop>
              <Line />
              <ScheduleInfoBottom>
                <div>18:00〜22:00</div>
              </ScheduleInfoBottom>
            </ScheduleInfo>
            <ScheduleInfo>
              <ScheduleInfoTop>
                <DoctorName>高梨りほ医師</DoctorName>
                <Time>
                  <div>開始時間</div>
                  <Select />
                  <div>時</div>
                  <Select />
                  <div>分</div>
                  <div style={{ marginLeft: 16 }}>開始時間</div>
                  <Select />
                  <div>時</div>
                  <Select />
                  <div>分</div>
                  <Button
                    // onClick={ok}
                    background="#2AC769"
                    color="#fff"
                    name="追加"
                    border="none"
                    bdr="6px"
                    fontSize={16}
                    fontWeight={700}
                  />
                </Time>
              </ScheduleInfoTop>
              <Line />
              <ScheduleInfoBottom>
                <div>18:00〜22:00</div>
              </ScheduleInfoBottom>
            </ScheduleInfo>
          </WrapperModal>
          <WrapperBtnFooter>
            <Button
              onClick={() => setIsVisible4Schedule(false)}
              background="#E0E0E0"
              color="#fff"
              name="キャンセル"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
            />

            <Button
              // onClick={ok}
              background="#2F8CAE"
              color="#fff"
              name="登録"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
            />
          </WrapperBtnFooter>
        </Modal>
        <ModalStyled
          title={
            <ResizeWrapper>
              <ResizeIcon
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                style={{ cursor: "pointer" }}
              />
              {showTooltip && <div className="tool-tip">ページとして開く</div>}
            </ResizeWrapper>
          }
          visible={showResizeModal}
          closeIcon={
            <ResizeWrapper>
              <CloseIcon
                fill="#3B3B3B"
                onMouseEnter={() => setShowCloseTooltip(true)}
                onMouseLeave={() => setShowCloseTooltip(false)}
                style={{ cursor: "pointer" }}
              />
              {showCloseTooltip && (
                <div className="tool-tip" style={{ bottom: -37, left: 2 }}>
                  閉じる
                </div>
              )}
            </ResizeWrapper>
          }
          onCancel={() => setIsVisible4Schedule(false)}
        >
          <WrapperScheduleResize>
            <div className="info-block">
              <div>
                予約日時：<span>8/2（水）19:00-19:15</span>
              </div>
              <div>
                氏名：
                <span>
                  グエンゴックチャンチャウ（ぐえんごっく ちゃんちゃう）様
                </span>
              </div>
              <div>
                年齢：<span>3歳</span>
              </div>
              <div>
                性別：<span>女</span>
              </div>
              <div>
                電話番号：<span>000-0000-0000</span>
              </div>
            </div>
            <div className="input-block">
              <TextArea
                label="主訴"
                fsLabel={16}
                fwLabel={700}
                lhLabel="16px"
                fs={16}
                value="主訴が入ります。本日発熱ありで、関節などが痛い。体温は38.6度。主訴が入ります。本日発熱ありで、関節などが痛い。体温は38.6度。主訴が入ります。本日発熱ありで、関節などが痛い。体温は38.6度。主訴が入ります。本日発熱ありで、関節などが痛い。体温は38.6度。"
                width={"100%"}
                marginForm="0px 0px 16px"
                height="112px"
                padding="7px 8px"
              />
              <TextArea
                label="医療相談内容"
                fsLabel={16}
                fwLabel={700}
                lhLabel="16px"
                fs={16}
                value="医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。医療相談内容が入ります。"
                width={"100%"}
                marginForm="0px 0px 16px"
                height="112px"
                padding="7px 8px"
              />
              <TextArea
                label="医師からの医療相談回答"
                fsLabel={16}
                fwLabel={700}
                lhLabel="16px"
                fs={16}
                value="医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。医師からの医療相談回答が入ります。"
                width={"100%"}
                height="112px"
                padding="7px 8px"
              />
            </div>
            <div className="button-block">
              <Button
                onClick={() => console.log("clicked")}
                background="#E0E0E0"
                color="#fff"
                name={"キャンセル"}
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                padding="13px 24px 11px"
                fontWeight={700}
              />

              <Button
                onClick={() => console.log("clicked")}
                background="#2AC769"
                color="#fff"
                name="登　録"
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                padding="13px 32px 11px"
                fontWeight={700}
              />
            </div>
          </WrapperScheduleResize>
        </ModalStyled>
      </Box>

      <Box padding="18px 12px 18px 16px">
        <ScheduleTitle>カレンダー上の予約一覧</ScheduleTitle>
        <ScheduleTableStyled
          dataSource={datas}
          columns={columns}
          scroll={{ x: "max-content" }}
          loading={isLoading}
        />
      </Box>
    </WrapperSchedule>
  );
};

export default ScheduleManagement;
