import {
  Action,
  ActionType,
  DoctorAppointmentsResponse,
} from "models/appointments/doctorAppointmentsList";
import createReducer from "../../createReducer";

export interface DoctorAppointmentsReducerType {
  loading: boolean;
  error?: string;
  doctorAppointmentsResponse?: DoctorAppointmentsResponse;
}

const defaultState: DoctorAppointmentsReducerType = {
  loading: false,
  error: undefined,
  doctorAppointmentsResponse: undefined,
};

export const doctorAppointmentsReducer =
  createReducer<DoctorAppointmentsReducerType>(defaultState, {
    [ActionType.DOCTOR_APPOINTMENTS_LIST](
      state: DoctorAppointmentsReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DOCTOR_APPOINTMENTS_LIST_ERROR](
      state: DoctorAppointmentsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        doctorAppointmentsResponse: undefined,
      };
    },

    [ActionType.DOCTOR_APPOINTMENTS_LIST_SUCCESS](
      state: DoctorAppointmentsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        doctorAppointmentsResponse: action.payload,
      };
    },

    [ActionType.DOCTOR_APPOINTMENTS_LIST_RESET](
      state: DoctorAppointmentsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        doctorAppointmentsResponse: undefined,
      };
    },
  });
