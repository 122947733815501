import {
  Action,
  ActionType,
  AppointmentUpdateStatusConsultationResponse,
} from "models/appointments/updateStatusConsultation";
import createReducer from "../../createReducer";

export interface AppointmentUpdateStatusConsultationReducerType {
  loading: boolean;
  error?: string;
  appointmentUpdateStatusConsultationResponse?: AppointmentUpdateStatusConsultationResponse;
}

const defaultState: AppointmentUpdateStatusConsultationReducerType = {
  loading: false,
  error: undefined,
  appointmentUpdateStatusConsultationResponse: undefined,
};

export const appointmentUpdateStatusConsultationReducer =
  createReducer<AppointmentUpdateStatusConsultationReducerType>(defaultState, {
    [ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION](
      state: AppointmentUpdateStatusConsultationReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_ERROR](
      state: AppointmentUpdateStatusConsultationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        appointmentUpdateStatusConsultationResponse: undefined,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_SUCCESS](
      state: AppointmentUpdateStatusConsultationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentUpdateStatusConsultationResponse: action.payload,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_CONSULTATION_RESET](
      state: AppointmentUpdateStatusConsultationReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentUpdateStatusConsultationResponse: undefined,
      };
    },
  });
