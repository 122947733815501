import React from "react";
import styled from "styled-components";
import { Modal, ModalProps } from "antd";
import { theme } from "theme/theme";

const ModalStyled = styled(Modal)`
  width: 343px !important;
  top: 16px;

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    padding: 11px 13px;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-close {
    top: -6px;
    right: 0px;
  }

  .ant-modal-title {
    text-transform: capitalize;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
  }

  .ant-modal-body {
    padding: 24px 16px 0px;
  }

  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }

  ${theme.media.tablet} {
    width: 720px !important;
    top: 50%;
    transform: translate(0%, -50%);

    .ant-modal-footer {
      padding: 0px 24px 16px;
      button {
        font-size: ${theme.sizes.md};
      }
    }
  }

  ${theme.media.laptopL} {
    top: 100px;
    transform: unset;
  }
`;

interface IModal extends ModalProps {}

const ModalCustom: React.FC<IModal> = ({ children, ...props }) => {
  return <ModalStyled {...props}>{children}</ModalStyled>;
};

export default ModalCustom;
