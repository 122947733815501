import { IResponse } from "models";

export enum ActionType {
  APPOINTMENT_UPDATE_STATUS_CONSULTATION = "action/APPOINTMENT_UPDATE_STATUS_CONSULTATION",
  APPOINTMENT_UPDATE_STATUS_CONSULTATION_SUCCESS = "action/APPOINTMENT_UPDATE_STATUS_CONSULTATION_SUCCESS",
  APPOINTMENT_UPDATE_STATUS_CONSULTATION_ERROR = "action/APPOINTMENT_UPDATE_STATUS_CONSULTATION_ERROR",
  APPOINTMENT_UPDATE_STATUS_CONSULTATION_RESET = "action/APPOINTMENT_UPDATE_STATUS_CONSULTATION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AppointmentUpdateStatusConsultationResponse extends IResponse {
  result: any;
}
