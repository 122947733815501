import {
  ActionType,
  Action,
  EditDoctorResponse,
} from "models/doctor/editDoctor";
import createReducer from "redux/createReducer";

export interface EditDoctorReducerType {
  loading: boolean;
  error?: string;
  editDoctorResponse?: EditDoctorResponse;
}

const defaultState: EditDoctorReducerType = {
  loading: false,
  error: undefined,
  editDoctorResponse: undefined,
};

export const editDoctorReducer = createReducer<EditDoctorReducerType>(
  defaultState,
  {
    [ActionType.EDIT_DOCTOR](state: EditDoctorReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.EDIT_DOCTOR_ERROR](
      state: EditDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        editDoctorResponse: undefined,
      };
    },

    [ActionType.EDIT_DOCTOR_SUCCESS](
      state: EditDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        editDoctorResponse: action.payload,
      };
    },

    [ActionType.EDIT_DOCTOR_RESET](state: EditDoctorReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        editDoctorResponse: undefined,
      };
    },
  }
);
