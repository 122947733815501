import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICreateDoctorPayload,
  ActionType,
  CreateDoctorResponse,
} from "models/doctor/createDoctor";
import { httpStatus } from "configs/httpStatus";
import { createDoctor } from "services/doctor";
import { handleSuccessAction, handleErrorAction } from "./createDoctorActions";

function* createDoctorSaga({ payload }: { payload: ICreateDoctorPayload }) {
  try {
    const response: CreateDoctorResponse = yield call(createDoctor, payload);

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateDoctorSagaSubmitWatcher() {
  yield takeLatest(ActionType.CREATE_DOCTOR as any, createDoctorSaga);
}

export default [fork(onCreateDoctorSagaSubmitWatcher)];
