import {
  ActionType,
  IMedicalPlanPayload,
  MedicalPlanResponse,
} from "models/medicalPlans/medicalPlan";

export const getMedicalPlanAction = (payload: IMedicalPlanPayload) => {
  return {
    type: ActionType.GET_MEDICAL_PLAN,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_MEDICAL_PLAN_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: MedicalPlanResponse) => {
  return {
    type: ActionType.GET_MEDICAL_PLAN_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.GET_MEDICAL_PLAN_RESET,
  };
};
