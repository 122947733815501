import { ActionType, IDoctorsListResponse } from "models/doctor/doctorsList";

export const getDoctorsListAction = (payload: any) => {
  return {
    type: ActionType.DOCTORS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DOCTORS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IDoctorsListResponse) => {
  return {
    type: ActionType.DOCTORS_SUCCESS,
    payload,
  };
};
