import {
  ActionType,
  Action,
  CreateBulkSchedulesResponse,
} from "models/schedules/createBulkSchedules";
import createReducer from "../../createReducer";

export interface CreateBulkSchedulesReducerType {
  loading: boolean;
  error?: string;
  createBulkSchedulesResponse?: CreateBulkSchedulesResponse;
}

const defaultState: CreateBulkSchedulesReducerType = {
  loading: false,
  error: undefined,
  createBulkSchedulesResponse: undefined,
};

export const createBulkSchedulesReducer =
  createReducer<CreateBulkSchedulesReducerType>(defaultState, {
    [ActionType.CREATE_BULK_SCHEDULES](state: CreateBulkSchedulesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_BULK_SCHEDULES_ERROR](
      state: CreateBulkSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        createBulkSchedulesResponse: undefined,
      };
    },

    [ActionType.CREATE_BULK_SCHEDULES_SUCCESS](
      state: CreateBulkSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createBulkSchedulesResponse: action.payload,
      };
    },

    [ActionType.CREATE_BULK_SCHEDULES_RESET](
      state: CreateBulkSchedulesReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createBulkSchedulesResponse: undefined,
      };
    },
  });
