import {
  ActionType,
  Action,
  DeleteScheduleResponse,
} from "models/schedules/deleteSchedule";
import createReducer from "../../createReducer";

export interface DeleteScheduleReducerType {
  loading: boolean;
  error?: string;
  deleteScheduleResponse?: DeleteScheduleResponse;
}

const defaultState: DeleteScheduleReducerType = {
  loading: false,
  error: undefined,
  deleteScheduleResponse: undefined,
};

export const deleteScheduleReducer = createReducer<DeleteScheduleReducerType>(
  defaultState,
  {
    [ActionType.DELETE_SCHEDULE](state: DeleteScheduleReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_SCHEDULE_ERROR](
      state: DeleteScheduleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleteScheduleResponse: undefined,
      };
    },

    [ActionType.DELETE_SCHEDULE_SUCCESS](
      state: DeleteScheduleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteScheduleResponse: action.payload,
      };
    },

    [ActionType.DELETE_SCHEDULE_RESET](state: DeleteScheduleReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteScheduleResponse: undefined,
      };
    },
  }
);
