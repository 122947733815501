import { IResponse } from "models";

export enum ActionType {
  APPOINTMENT_DETAILS = "action/APPOINTMENT_DETAILS",
  APPOINTMENT_DETAILS_SUCCESS = "action/APPOINTMENT_DETAILS_SUCCESS",
  APPOINTMENT_DETAILS_ERROR = "action/APPOINTMENT_DETAILS_ERROR",
  APPOINTMENT_DETAILS_RESET = "action/APPOINTMENT_DETAILS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IAppointmenDetailstPayload {
  appointment_id: number;
}

export interface IConsultation {
  question_text: string;
  answer_text: string;
}

export interface IHistoryItem {
  id: number;
  date_time: string;
  chief_complain: string;
}

export interface IAppointmentDetails {
  id: number;
  schedule_id: number;
  agree_repo_patient_id: number;
  is_cancelled: boolean;
  date: string;
  weekday_index: number;
  start_time: string;
  end_time: string;
  doctor_name: string;
  patient_name: string;
  patient_kana_name: string;
  patient_age: number;
  patient_gender: string;
  hospital_name: string;
  meeting_url: string;
  symptom: string;
  chief_complain: string;
  patient_postal_code: string;
  patient_address: string;
  patient_mobile_number: string;
  patient_email: string;
  patient_height: number;
  patient_weight: number;
  date_of_birth: string;
  insurance_number: any;
  insurance_image_url: string;
  id_card_image_url: string;
  subsidy_receipt1_image_url: string;
  subsidy_receipt2_image_url: string;
  illness_history: any[];
  drink_history: any;
  prescribed_medication: any;
  smoking_history: any;
  allergy_info: any;
  agree_repo_consultation: IConsultation[];
  doctor_response: string;
  medical_record: any;
  status: number;
  pre_interview_answer: IConsultation[];
  histroies: IHistoryItem[];
  clinical_department_name: string;
  prescription: any[];
}

export interface AppointmentDetailsResponse extends IResponse {
  result: IAppointmentDetails;
}
