import { IResponse } from "models";

export enum ActionType {
  MONTH_APPOINTMENTS_LIST = "action/MONTH_APPOINTMENTS_LIST",
  MONTH_APPOINTMENTS_LIST_SUCCESS = "action/MONTH_APPOINTMENTS_LIST_SUCCESS",
  MONTH_APPOINTMENTS_LIST_ERROR = "action/MONTH_APPOINTMENTS_LIST_ERROR",
  MONTH_APPOINTMENTS_LIST_RESET = "action/MONTH_APPOINTMENTS_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IAppointmentsListPayload {
  start_date: string;
  end_date: string;
}

export interface IMonthAppointment {
  id: number;
  schedule_id: number;
  agree_repo_patient_id: number;
  is_cancelled: boolean;
  date: string;
  weekday_index: number;
  start_time: string;
  end_time: string;
  doctor_name: string;
  patient_name: string;
  patient_kana_name: string;
  patient_age: number;
  patient_gender: string;
  hospital_name: string;
  meeting_url: string;
  symptom: string;
}

export interface IMonthAppointmentListItem {
  appointments: IMonthAppointment[];
  doctor_id: number;
  doctor_name: string;
}

export interface MonthAppointmentsListResponse extends IResponse {
  result: IMonthAppointment[];
}
