import * as React from "react";

function SquareDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="23"
        height="23"
        rx="6"
        fill="white"
        stroke="#E0E0E0"
      />
      <path
        d="M13.4004 12.5004L17.4254 8.48183C17.5457 8.36153 17.6133 8.19836 17.6133 8.02822C17.6133 7.85808 17.5457 7.69492 17.4254 7.57461C17.3051 7.45431 17.1419 7.38672 16.9718 7.38672C16.8017 7.38672 16.6385 7.45431 16.5182 7.57461L12.4996 11.5996L8.48097 7.57461C8.36066 7.45431 8.1975 7.38672 8.02736 7.38672C7.85722 7.38672 7.69405 7.45431 7.57375 7.57461C7.45344 7.69492 7.38586 7.85808 7.38586 8.02822C7.38586 8.19836 7.45344 8.36153 7.57375 8.48183L11.5987 12.5004L7.57375 16.5191C7.51387 16.5784 7.46634 16.6491 7.4339 16.727C7.40147 16.8048 7.38477 16.8883 7.38477 16.9727C7.38477 17.057 7.40147 17.1405 7.4339 17.2184C7.46634 17.2962 7.51387 17.3669 7.57375 17.4263C7.63314 17.4862 7.7038 17.5337 7.78166 17.5661C7.85951 17.5986 7.94302 17.6153 8.02736 17.6153C8.1117 17.6153 8.19521 17.5986 8.27306 17.5661C8.35092 17.5337 8.42158 17.4862 8.48097 17.4263L12.4996 13.4013L16.5182 17.4263C16.5776 17.4862 16.6482 17.5337 16.7261 17.5661C16.804 17.5986 16.8875 17.6153 16.9718 17.6153C17.0561 17.6153 17.1397 17.5986 17.2175 17.5661C17.2954 17.5337 17.366 17.4862 17.4254 17.4263C17.4853 17.3669 17.5328 17.2962 17.5653 17.2184C17.5977 17.1405 17.6144 17.057 17.6144 16.9727C17.6144 16.8883 17.5977 16.8048 17.5653 16.727C17.5328 16.6491 17.4853 16.5784 17.4254 16.5191L13.4004 12.5004Z"
        fill="#FB4E4E"
      />
    </svg>
  );
}

export default SquareDelete;
