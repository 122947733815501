import {
  IDeleteMedicineByIDResponse,
  ActionType,
} from "models/medicine/deleteByID";

export const deleteMedicineByIDAction = (payload: any) => {
  return {
    type: ActionType.DELETE_MEDICINE_BY_ID,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_MEDICINE_BY_ID_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IDeleteMedicineByIDResponse) => {
  return {
    type: ActionType.DELETE_MEDICINE_BY_ID_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DELETE_MEDICINE_BY_ID_RESET,
  };
};
