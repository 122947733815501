import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  EditMedicalPlanResponse,
  IEditMedicalPlanPayload,
} from "models/medicalPlans/editMedicalPlan";
import { httpStatus } from "configs/httpStatus";
import { editMedicalPlan } from "services/medicalPlans";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./editMedicalPlanActions";

function* editMedicalPlanSaga({
  payload,
}: {
  payload: IEditMedicalPlanPayload;
}) {
  try {
    const response: EditMedicalPlanResponse = yield call(
      editMedicalPlan,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onEditMedicalPlanWatcher() {
  yield takeLatest(ActionType.EDIT_MEDICAL_PLAN as any, editMedicalPlanSaga);
}

export default [fork(onEditMedicalPlanWatcher)];
