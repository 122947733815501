import React, { useState, useEffect } from "react";
import { Grid, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

import { Box, Input, Button, Table } from "components";
import {
  EditIcon,
  DeleteIcon,
  AttentionIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
} from "icons";
import { EMAIL_REGEX, PASSWORD_REGEX } from "constant";
import { httpStatus } from "configs/httpStatus";

import {
  GetDoctorsListActionCreators,
  DeleteDoctorActionCreators,
  CreateDoctorActionCreators,
  EditDoctorActionCreators,
} from "redux/rootActions";
import {
  selectIsLoadingDoctors,
  doctorsListDataResponse,
} from "redux/doctor/doctorsList/doctorsListState";
import {
  selectIsLoadingDeleteDoctor,
  deleteDoctorErrorMessage,
  deleteDoctorDataResponse,
} from "redux/doctor/deleteDoctor/deleteDoctorStates";
import {
  selectIsCreatingDoctor,
  createDoctorErrorMessage,
  createDoctorDataResponse,
} from "redux/doctor/createDoctor/createDoctorStates";
import {
  selectIsEditingDoctor,
  editDoctorErrorMessage,
  editDoctorDataResponse,
} from "redux/doctor/editDoctor/editDoctorStates";

import {
  GroupInput,
  Title,
  WrapperFirstName,
  WrapperBtnFooter,
  WrapperContentModal,
  ModalStyled,
  WrapperPhysicianManagement,
} from "./PhysicianManagement.style";
import {
  WrapperSchedule,
  Alert,
  AlertMobile,
  Tooltip4AlertMobile,
} from "../schedules/Schedule.style";

const { useBreakpoint } = Grid;

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("email-address-required")
    .matches(EMAIL_REGEX, "wrong-format"),
  password: yup
    .string()
    .required("password-validation")
    .matches(PASSWORD_REGEX, "password-requirements"),
  password_confirmation: yup
    .string()
    .required("confirm-password-required")
    .oneOf([yup.ref("password")], "password-not-match"),
});

const PhysicianManagement = () => {
  const dispatch = useDispatch();

  const isLoadingDoctor = useSelector(selectIsLoadingDoctors);
  const isCreatingDoctor = useSelector(selectIsCreatingDoctor);
  const isEditingDoctor = useSelector(selectIsEditingDoctor);

  const DeleteDoctorError = useSelector(deleteDoctorErrorMessage);
  const CreateDoctorError = useSelector(createDoctorErrorMessage);
  const editDoctorError = useSelector(editDoctorErrorMessage);

  const doctorList = useSelector(doctorsListDataResponse);
  const deleteDoctorResponse = useSelector(deleteDoctorDataResponse);
  const createDoctorResponse = useSelector(createDoctorDataResponse);
  const editDoctorResponse = useSelector(editDoctorDataResponse);

  const { handleSubmit, control, setValue, reset } = useForm({
    // resolver: yupResolver(schema),
  });

  const [showEdit, setShowEdit] = useState<any>({});
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [editDoctor, setEditDoctor] = useState<{
    id: any;
    email: string;
  }>({
    id: undefined,
    email: "",
  });

  const screens = useBreakpoint();

  const handleDeleteDoctor = () => {
    setIsConfirmModal(false);
    if (selectedDoctor) {
      dispatch(
        DeleteDoctorActionCreators.deleteDoctorAction({
          doctor_id: selectedDoctor,
        })
      );
    }
  };

  const handleCreateDoctor = (data: { [x: string]: any }) => {
    const {
      email,
      password,
      first_name,
      last_name,
      kana_first_name,
      kana_last_name,
    } = data;

    dispatch(
      CreateDoctorActionCreators.createDoctorAction({
        doctor: {
          email,
          password,
          first_name,
          last_name,
          kana_first_name,
          kana_last_name,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
  }, []);

  useEffect(() => {
    if (
      createDoctorResponse &&
      createDoctorResponse.status === httpStatus.StatusCreated
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: createDoctorResponse.message,
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      reset();
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("kana_first_name", "");
      setValue("kana_last_name", "");
      setValue("email", "");
      setValue("password", "");
      dispatch(CreateDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [createDoctorResponse]);

  useEffect(() => {
    if (deleteDoctorResponse && deleteDoctorResponse?.message) {
      notification.success({
        placement: "bottomRight",
        message: deleteDoctorResponse.message,
        icon: <SendCompleteIcon />,
      });
      setSelectedDoctor(undefined);
      dispatch(DeleteDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [deleteDoctorResponse]);

  useEffect(() => {
    if (editDoctorResponse && editDoctorResponse?.message && !isEditingDoctor) {
      notification.success({
        placement: "bottomRight",
        message: "医師情報を更新しました。",
        icon: <SendCompleteIcon />,
      });
      setSelectedDoctor(undefined);
      dispatch(EditDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [editDoctorResponse]);

  useEffect(() => {
    if (DeleteDoctorError) {
      notification.error({
        placement: "bottomRight",
        message: "delete doctor error",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setSelectedDoctor(undefined);
      dispatch(DeleteDoctorActionCreators.handleResetAction());
    }
  }, [DeleteDoctorError]);

  useEffect(() => {
    if (CreateDoctorError) {
      notification.error({
        placement: "bottomRight",
        message: "create doctor error",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setSelectedDoctor(undefined);
      dispatch(CreateDoctorActionCreators.handleResetAction());
    }
  }, [CreateDoctorError]);

  useEffect(() => {
    if (editDoctorError) {
      notification.error({
        placement: "bottomRight",
        message: "Edit doctor error",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setEditDoctor({
        id: undefined,
        email: "",
      });
      dispatch(EditDoctorActionCreators.handleResetAction());
    }
  }, [editDoctorError]);

  const showWarning = (temperature?: number) => {
    if (temperature) {
      return "";
    }
    const Message = () => {
      //   return (
      //     <Trans i18nKey="body-temperature.warning-first">
      //       {{ percent: temperature }}
      //     </Trans>
      //   );

      return <div>20:30に大島大輔さんからの予約が入りました。</div>;
    };
    return (
      <React.Fragment>
        {screens.md ? (
          //   <Alert background={temperature >= 5 ? "#FB2121" : "#EBC300"}>
          <Alert background={"#2F8CAE"}>
            <AttentionIcon style={{ marginRight: 8 }} />
            <Message />
          </Alert>
        ) : (
          <React.Fragment>
            {isVisibleTooltip && (
              <Tooltip4AlertMobile>
                <Message />
              </Tooltip4AlertMobile>
            )}
            <AlertMobile
              background={"#2F8CAE"}
              onClick={() => console.log("clicked")}
            >
              <AttentionIcon style={{ marginRight: 8 }} />
            </AlertMobile>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const columns = [
    {
      title: "名前",
      dataIndex: "doctor_name",
      key: "doctor_name",
      width: 175,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <WrapperFirstName>
            <Button
              onClick={() => {
                setShowEdit({
                  [record.id]: false,
                });
                if (editDoctor.email && editDoctor.email !== record.email) {
                  dispatch(
                    EditDoctorActionCreators.editDoctorAction({
                      id: editDoctor.id,
                      doctor: {
                        email: editDoctor.email,
                      },
                    })
                  );
                }
              }}
              background="#2F8CAE"
              color="#fff"
              name="保存"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              width="44px"
            />
            <Input value={text} width={160} height={28} disabled={true} />
          </WrapperFirstName>
        ) : (
          <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
            <EditIcon
              fill="#3B3B3B"
              onClick={() => {
                setShowEdit({
                  [record.id]: true,
                });
                setEditDoctor({
                  id: record.id,
                  email: record.email,
                });
              }}
            />
            {text}
          </div>
        );
      },
    },
    {
      title: "メール",
      dataIndex: "email",
      key: "email",
      width: 94,
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <Input
            value={editDoctor.email ? editDoctor.email : ""}
            width={320}
            height={28}
            onChange={(e: any) =>
              setEditDoctor({
                ...editDoctor,
                email: e.target.value,
              })
            }
          />
        ) : (
          <div>{text}</div>
        );
      },
    },
    {
      title: "ふりがな",
      dataIndex: "kana_doctor_name",
      key: "kana_doctor_name",
      width: 155,
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <Input value={text} width={160} height={28} disabled={true} />
        ) : (
          <div>{text}</div>
        );
      },
    },
    {
      title: "",
      width: 155,
      render: (text: string, record: any) => {
        return (
          <div style={{ textAlign: "right" }}>
            <DeleteIcon
              fill="#FB4E4E"
              onClick={() => {
                console.log(record);
                setSelectedDoctor(record?.id);
                setIsConfirmModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <WrapperPhysicianManagement>
      {/* {showWarning()} */}
      <Box title="登録医師管理" padding="16px" style={{ marginBottom: 24 }}>
        <Title>医師登録</Title>
        <form noValidate onSubmit={handleSubmit(handleCreateDoctor)}>
          <GroupInput style={{ marginBottom: 16 }}>
            <Controller
              control={control}
              name="last_name"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="姓"
                  fsLabel={16}
                  width={136}
                  height={31}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="first_name"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="名"
                  fsLabel={16}
                  width={136}
                  height={31}
                  marginForm="0 10px 0 0"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="kana_last_name"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="姓（ふりがな）"
                  fsLabel={16}
                  width={136}
                  height={31}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="kana_first_name"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="名（ふりがな）"
                  fsLabel={16}
                  width={136}
                  height={31}
                  marginForm="0 18px 0 0"
                  onChange={onChange}
                />
              )}
            />

            <Button
              // onClick={close}
              background="#2F8CAE"
              color="#fff"
              name="登録"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              width="128px"
              disabled={isCreatingDoctor}
            />
          </GroupInput>
          <GroupInput>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="メールアドレス"
                  fsLabel={16}
                  width={280}
                  marginForm="0 10px 0 0"
                  height={31}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  label="初期パスワード"
                  fsLabel={16}
                  width={280}
                  height={31}
                  onChange={onChange}
                />
              )}
            />
          </GroupInput>
        </form>
      </Box>
      <Box padding="16px">
        <Title>登録医師一覧</Title>
        <Table columns={columns} dataSource={doctorList?.result || []} />
      </Box>

      {isConfirmModal && (
        <ModalStyled visible={isConfirmModal} type="confirm" footer={null}>
          <WrapperContentModal>
            <div>医師アカウントを削除します。</div>
            <div>よろしいですか？</div>
            <WrapperBtnFooter>
              <Button
                onClick={() => setIsConfirmModal(false)}
                background="#E0E0E0"
                color="#fff"
                name="キャンセル"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />

              <Button
                onClick={handleDeleteDoctor}
                background="#FB4E4E"
                color="#fff"
                name="はい"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />
            </WrapperBtnFooter>
          </WrapperContentModal>
        </ModalStyled>
      )}
    </WrapperPhysicianManagement>
  );
};

export default PhysicianManagement;
