import {
  ActionType,
  IWeekSchedulesPayload,
  WeekSchedulesResponse,
} from "models/schedules/weekSchedules";

export const getWeekSchedulesAction = (payload: IWeekSchedulesPayload) => {
  return {
    type: ActionType.WEEK_SCHEDULE_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.WEEK_SCHEDULE_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: WeekSchedulesResponse) => {
  return {
    type: ActionType.WEEK_SCHEDULE_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.WEEK_SCHEDULE_LIST_RESET,
  };
};
