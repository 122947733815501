import {
  Action,
  ActionType,
  UpdateMedicalRecordResponse,
} from "models/medicalRecords/updateMedicalRecord";
import createReducer from "../../createReducer";

export interface UpdateMedicalRecordReducerType {
  loading: boolean;
  error?: string;
  updateMedicalRecordResponse?: UpdateMedicalRecordResponse;
}

const defaultState: UpdateMedicalRecordReducerType = {
  loading: false,
  error: undefined,
  updateMedicalRecordResponse: undefined,
};

export const updateMedicalRecordReducer =
  createReducer<UpdateMedicalRecordReducerType>(defaultState, {
    [ActionType.UPDATE_MEDICAL_RECORD](state: UpdateMedicalRecordReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_MEDICAL_RECORD_ERROR](
      state: UpdateMedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateMedicalRecordResponse: undefined,
      };
    },

    [ActionType.UPDATE_MEDICAL_RECORD_SUCCESS](
      state: UpdateMedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateMedicalRecordResponse: action.payload,
      };
    },

    [ActionType.UPDATE_MEDICAL_RECORD_RESET](
      state: UpdateMedicalRecordReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateMedicalRecordResponse: undefined,
      };
    },
  });
