import {
  ICreateMedicineResponse,
  Action,
  ActionType,
} from "models/medicine/createMedicine";
import createReducer from "../../createReducer";

export interface CreateMedicineReducerType {
  loading: boolean;
  error?: string;
  createMedicineResponse?: ICreateMedicineResponse;
}

const defaultState: CreateMedicineReducerType = {
  loading: false,
  error: "",
  createMedicineResponse: undefined,
};

export const createMedicineReducer = createReducer<CreateMedicineReducerType>(
  defaultState,
  {
    [ActionType.CREATE_MEDICINE](state: CreateMedicineReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_MEDICINE_ERROR](
      state: CreateMedicineReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_MEDICINE_SUCCESS](
      state: CreateMedicineReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicineResponse: action.payload,
      };
    },

    [ActionType.CREATE_MEDICINE_RESET](state: CreateMedicineReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicineResponse: undefined,
      };
    },
  }
);
