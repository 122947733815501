import {
  IDeleteDoctorPayload,
  ActionType,
  DeleteDoctorResponse,
} from "models/doctor/deleteDoctor";

export const deleteDoctorAction = (payload: IDeleteDoctorPayload) => {
  return {
    type: ActionType.DELETE_DOCTOR,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_DOCTOR_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DeleteDoctorResponse) => {
  return {
    type: ActionType.DELETE_DOCTOR_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DELETE_DOCTOR_RESET,
  };
};
