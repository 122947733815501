import {
  Action,
  ActionType,
  CreateMedicalRecordResponse,
} from "models/medicalRecords/createMedicalRecord";
import createReducer from "../../createReducer";

export interface CreateMedicalRecordReducerType {
  loading: boolean;
  error?: string;
  createMedicalRecordResponse?: CreateMedicalRecordResponse;
}

const defaultState: CreateMedicalRecordReducerType = {
  loading: false,
  error: undefined,
  createMedicalRecordResponse: undefined,
};

export const createMedicalRecordReducer =
  createReducer<CreateMedicalRecordReducerType>(defaultState, {
    [ActionType.CREATE_MEDICAL_RECORD](state: CreateMedicalRecordReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_MEDICAL_RECORD_ERROR](
      state: CreateMedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        createMedicalRecordResponse: undefined,
      };
    },

    [ActionType.CREATE_MEDICAL_RECORD_SUCCESS](
      state: CreateMedicalRecordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicalRecordResponse: action.payload,
      };
    },

    [ActionType.CREATE_MEDICAL_RECORDD_RESET](
      state: CreateMedicalRecordReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createMedicalRecordResponse: undefined,
      };
    },
  });
