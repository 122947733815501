import { IResponse } from "models";

export enum ActionType {
  DOCTORS_LIST = "action/DOCTORS_LIST",
  DOCTORS_SUCCESS = "action/DOCTORS_SUCCESS",
  DOCTORS_ERROR = "action/DOCTORS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDoctorsListResponse extends IResponse {
  //   result: {
  //     total: number;
  //     doctors: IDoctor[];
  //   };
  result: IDoctor[];
}

export interface IDoctor {
  id: number;
  email: string;
  doctor_name: string;
  kana_doctor_name: string;
}
