import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "../routes";
import configureStore from "./configureStore";

import Loading from "components/loading";
const { persistor, store } = configureStore();

function ReduxRoot() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Loading width="80" height="80" />}
        persistor={persistor}
      >
        <App />
      </PersistGate>
    </Provider>
  );
}

export { store };

export default ReduxRoot;
