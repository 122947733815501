import { IResponse } from "models";

export enum ActionType {
  UPDATE_MEDICAL_RECORD = "action/UPDATE_MEDICAL_RECORD",
  UPDATE_MEDICAL_RECORD_SUCCESS = "action/UPDATE_MEDICAL_RECORD_SUCCESS",
  UPDATE_MEDICAL_RECORD_ERROR = "action/UPDATE_MEDICAL_RECORD_ERROR",
  UPDATE_MEDICAL_RECORD_RESET = "action/UPDATE_MEDICAL_RECORD_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface UpdateMedicalRecordResponse extends IResponse {
  result: any;
}
