import {
  IGetMedicineListByIDResponse,
  ActionType,
} from "models/medicine/getMedicineListByID";

export const getMedicineListByIDAction = (payload: any) => {
  return {
    type: ActionType.GET_MEDICINE_LIST_BY_ID,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_MEDICINE_LIST_BY_ID_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetMedicineListByIDResponse) => {
  return {
    type: ActionType.GET_MEDICINE_LIST_BY_ID_SUCCESS,
    payload,
  };
};
