import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";

import { Input, TextArea, Button } from "components";
import { SendCompleteIcon, ConfirmFailIcon } from "icons";
import {
  GetMedicalPlanActionCreators,
  NotifyMedicalPlanActionCreators,
  EditMedicalPlanActionCreators,
} from "redux/rootActions";
import {
  selectIsLoadingMedicalPlan,
  medicalPlanResponse,
  selectMedicalPlanErrorMessage,
} from "redux/medicalPlans/medicalPlan/medicalPlanStates";
import {
  selectIsLoadingNotifyMedicalPlan,
  notifyMedicalPlanResponse,
} from "redux/medicalPlans/notifyMedicalPlan/notifyMedicalPlanStates";
import {
  selectIsEditingMedicalPlan,
  editMedicalPlanResponse,
} from "redux/medicalPlans/editMedicalPlan/editMedicalPlanStates";

import { RightContentWrapper, MedicalPlanContent } from "./Reservations.style";

const MedicalPlan = ({
  medicalRecordId,
  medicalPlans,
}: {
  medicalRecordId: any;
  medicalPlans: any;
}) => {
  const dispatch = useDispatch();

  const isLoadingMedicalPlan = useSelector(selectIsLoadingMedicalPlan);
  const notifyingMedicalPlan = useSelector(selectIsLoadingNotifyMedicalPlan);
  const editingMedicalPlan = useSelector(selectIsEditingMedicalPlan);

  const notifyMedicalPlanData = useSelector(notifyMedicalPlanResponse);
  const editMedicalPlanData = useSelector(editMedicalPlanResponse);

  const [medicalPlan, setMedicalPlan] = useState<any>({});
  const [editing, setEditing] = useState<boolean>(false);

  const checkEditBeforeNotify = useMemo<boolean>(() => {
    if (
      medicalPlan &&
      Object(medicalPlan) &&
      medicalPlans &&
      Object(medicalPlans)
    ) {
      if (
        medicalPlan.disease_name !== medicalPlans.disease_name ||
        medicalPlan.details !== medicalPlans.details ||
        medicalPlan.period !== medicalPlans.period ||
        medicalPlan.parent_name !== medicalPlans.parent_name ||
        medicalPlan.patient_name !== medicalPlans.patient_name
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [medicalPlan, medicalPlans]);

  const handleNotification = (type: string, message: string | any) => {
    if (type === "success") {
      notification.success({
        placement: "bottomRight",
        message: message,
        icon: <SendCompleteIcon />,
      });
    } else {
      notification.error({
        placement: "bottomRight",
        message: message,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
    }
  };

  useEffect(() => {
    if (notifyMedicalPlanData && !notifyingMedicalPlan) {
      handleNotification("success", notifyMedicalPlanData.message);
      dispatch(NotifyMedicalPlanActionCreators.handleResetAction());
    }
  }, [notifyMedicalPlanData, notifyingMedicalPlan]);

  useEffect(() => {
    if (editMedicalPlanData && !editingMedicalPlan) {
      if (checkEditBeforeNotify && !editing) {
        if (medicalPlan && medicalPlan.id) {
          dispatch(
            NotifyMedicalPlanActionCreators.notifyMedicalPlanAction({
              medical_plan_id: medicalPlan.id,
            })
          );
        }
      } else {
        handleNotification("success", editMedicalPlanData.message);
      }
      if (medicalRecordId) {
        dispatch(
          GetMedicalPlanActionCreators.getMedicalPlanAction({
            record_id: medicalRecordId,
          })
        );
      }
      if (editing) {
        setEditing(false);
      }
      dispatch(EditMedicalPlanActionCreators.handleResetAction());
    }
  }, [editMedicalPlanData, editingMedicalPlan]);

  useEffect(() => {
    if (medicalPlans) {
      setMedicalPlan(medicalPlans);
    }
  }, [medicalPlans]);

  return (
    <RightContentWrapper>
      <MedicalPlanContent>
        <div className="title-group">
          <div className="title">診療計画書</div>
          <Button
            onClick={() => {
              if (medicalPlan.id) {
                setEditing(true);
                dispatch(
                  EditMedicalPlanActionCreators.editMedicalPlanAction({
                    medical_plan_id: medicalPlan.id,
                    medical_plan: {
                      disease_name: medicalPlan.disease_name
                        ? medicalPlan.disease_name
                        : "",
                      details: medicalPlan.details ? medicalPlan.details : "",
                      period: medicalPlan.period ? medicalPlan.period : "",
                      parent_name: medicalPlan.parent_name
                        ? medicalPlan.parent_name
                        : "",
                      patient_name: medicalPlan.patient_name
                        ? medicalPlan.patient_name
                        : "",
                    },
                  })
                );
              }
            }}
            background="#2AC769"
            color="#FFFFFF"
            name="保存"
            border="none"
            bdr="6px"
            fontSize={16}
            lineHeight="16px"
            padding="13px 32px 11px"
            fontWeight={700}
            disabled={
              !medicalPlan || notifyingMedicalPlan || editingMedicalPlan
            }
          />
        </div>
        <div className="area-group">
          <div className="label">疾病名</div>
          <Input
            className="area"
            label=""
            fs={16}
            lh="23px"
            value={
              medicalPlan && medicalPlan?.disease_name
                ? medicalPlan.disease_name
                : ""
            }
            width={"100%"}
            height={40}
            disabled={isLoadingMedicalPlan}
            onChange={(e: any) => {
              setMedicalPlan({
                ...medicalPlan,
                disease_name: e.target.value,
              });
            }}
          />
        </div>
        <div className="area-group">
          <div className="label">診療内容</div>
          <TextArea
            className="area"
            label=""
            fs={16}
            lh="23px"
            value={
              medicalPlan && medicalPlan?.details ? medicalPlan.details : ""
            }
            width={"100%"}
            height="131px"
            onChange={(e: any) => {
              setMedicalPlan({
                ...medicalPlan,
                details: e.target.value,
              });
            }}
            disabled={isLoadingMedicalPlan}
          />
        </div>
        <div className="area-group">
          <div className="label">診療期間</div>
          <Input
            className="area"
            label=""
            fs={16}
            lh="23px"
            value={medicalPlan && medicalPlan?.period ? medicalPlan.period : ""}
            onChange={(e: any) => {
              setMedicalPlan({
                ...medicalPlan,
                period: e.target.value,
              });
            }}
            width={"100%"}
            height={40}
            disabled={isLoadingMedicalPlan}
          />
        </div>
        <div className="area-group">
          <div className="label">保護者氏名</div>
          <Input
            className="area"
            label=""
            fs={16}
            lh="23px"
            value={
              medicalPlan && medicalPlan?.parent_name
                ? medicalPlan.parent_name
                : ""
            }
            onChange={(e: any) => {
              setMedicalPlan({
                ...medicalPlan,
                parent_name: e.target.value,
              });
            }}
            width={"100%"}
            height={40}
            disabled={isLoadingMedicalPlan}
          />
        </div>
        <div className="area-group">
          <div className="label">患者氏名</div>
          <Input
            className="area"
            label=""
            fs={16}
            lh="23px"
            value={
              medicalPlan && medicalPlan?.patient_name
                ? medicalPlan.patient_name
                : ""
            }
            onChange={(e: any) => {
              setMedicalPlan({
                ...medicalPlan,
                patient_name: e.target.value,
              });
            }}
            width={"100%"}
            height={40}
            disabled={isLoadingMedicalPlan}
          />
        </div>
        <Button
          style={{ float: "right" }}
          onClick={() => {
            if (medicalPlan && medicalPlan.id) {
              if (checkEditBeforeNotify) {
                dispatch(
                  EditMedicalPlanActionCreators.editMedicalPlanAction({
                    medical_plan_id: medicalPlan.id,
                    medical_plan: {
                      disease_name: medicalPlan.disease_name
                        ? medicalPlan.disease_name
                        : "",
                      details: medicalPlan.details ? medicalPlan.details : "",
                      period: medicalPlan.period ? medicalPlan.period : "",
                      parent_name: medicalPlan.parent_name
                        ? medicalPlan.parent_name
                        : "",
                      patient_name: medicalPlan.patient_name
                        ? medicalPlan.patient_name
                        : "",
                    },
                  })
                );
              } else {
                dispatch(
                  NotifyMedicalPlanActionCreators.notifyMedicalPlanAction({
                    medical_plan_id: medicalPlan.id,
                  })
                );
              }
            }
          }}
          background="#2F8CAE"
          color="#FFFFFF"
          name="ユーザーに診療計画書を通知"
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="23px"
          padding="5px 8px 4px"
          fontWeight={700}
          disabled={!medicalPlan || notifyingMedicalPlan}
        />
      </MedicalPlanContent>
    </RightContentWrapper>
  );
};

export default MedicalPlan;
