import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  IDeleteMedicineByIDResponse,
} from "models/medicine/deleteByID";
import { httpStatus } from "configs/httpStatus";
import { deleteMedicineByID } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./deleteByIDActions";

function* deleteMedicineByIDSaga({ payload }: { payload: any }) {
  try {
    const response: IDeleteMedicineByIDResponse = yield call(
      deleteMedicineByID,
      payload
    );

    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDeleteMedicineByIDWatcher() {
  yield takeLatest(
    ActionType.DELETE_MEDICINE_BY_ID as any,
    deleteMedicineByIDSaga
  );
}

export default [fork(onDeleteMedicineByIDWatcher)];
