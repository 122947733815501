import {
  ActionType,
  VersionHistoryPayload,
  VersionHistoryResponse,
} from "models/medicalRecords/versionHistory";

export const getVersionHistoryAction = (payload: VersionHistoryPayload) => {
  return {
    type: ActionType.GET_VERSION_HISTORY,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_VERSION_HISTORY_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: VersionHistoryResponse) => {
  return {
    type: ActionType.GET_VERSION_HISTORY_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.GET_VERSION_HISTORY_RESET,
  };
};
