import {
  ActionType,
  AppointmentUpdateStatusResponse,
} from "models/appointments/updateStatus";

export const updateStatusAction = (payload: any) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: AppointmentUpdateStatusResponse
) => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPOINTMENT_UPDATE_STATUS_RESET,
  };
};
