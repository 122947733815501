import {
  ActionType,
  IEditMedicalPlanPayload,
  EditMedicalPlanResponse,
} from "models/medicalPlans/editMedicalPlan";

export const editMedicalPlanAction = (payload: IEditMedicalPlanPayload) => {
  return {
    type: ActionType.EDIT_MEDICAL_PLAN,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.EDIT_MEDICAL_PLAN_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: EditMedicalPlanResponse) => {
  return {
    type: ActionType.EDIT_MEDICAL_PLAN_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.EDIT_MEDICAL_PLAN_RESET,
  };
};
