import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Input, Button, Select } from "components";
import { LinkStyled } from "theme/CommonStyle";
// import { ForgotPasswordActionCreators } from "redux/rootActions";
import { useDispatch, useSelector } from "react-redux";
// import {
//   selectIsLoading,
//   selectErrorMessage,
// } from "redux/forgotpassword/forgotpasswordStates";
// import { ForgotPasswordData } from "models/forgotPassword";
import { useTranslation } from "react-i18next";

const WrapperLinkOutSide = styled.div(({ theme }) => ({
  textAlign: "center",
  position: "absolute",
  bottom: -50,
  left: "33%",
  a: {
    ...LinkStyled(theme),
  },
}));

const Description = styled.div(({ theme }) => ({
  fontSize: theme.sizes.sm,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.text.primary,
  lineHeight: "20px",
  marginBottom: 18,
}));

const schema = yup.object().shape({
  email: yup.string().trim().required("email-required"),
});

const ForgotPassword: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "common",
  });
  const dispatch = useDispatch();
  // const errorMessage = useSelector(selectErrorMessage);
  // const isLoading = useSelector(selectIsLoading);

  const options = [
    {
      id: 1,
      value: 1,
      name: t("administrator"),
    },
    {
      id: 2,
      value: 2,
      name: t("editor"),
    },
  ];

  const { watch, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const watchEmailField = watch("email");

  // useEffect(() => {
  //   dispatch(ForgotPasswordActionCreators.handleErrorAction(""));
  // }, [watchEmailField]);

  useEffect(() => {
    setValue("role", options[0].value);
  }, []);

  const onSubmit = (data: any) => {
    // const params: ForgotPasswordData = {
    //   locale: t1("locale"),
    //   user: {
    //     ...data,
    //   },
    // };
    // dispatch(ForgotPasswordActionCreators.forgotPasswordUserAction(params));
    console.log("submit password");
  };

  return (
    <>
      <Description>{t("send-password-reset-procedure")}</Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Input
              label={t("email")}
              type="email"
              onChange={onChange}
              error={t(error?.message)}
              marginForm="0 0 20px 0"
            />
          )}
        />
        <div style={{ position: "relative" }}>
          {/* {errorMessage && (
            <div
              style={{
                color: "#FB2121",
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "12px",
                position: "absolute",
                top: -15,
              }}
            >
              {errorMessage}
            </div>
          )} */}
          <Button
            name={t("reset-password")}
            type="submit"
            background="#2F8CAE"
            color="#fff"
            bdr="6px"
            width="100%"
            margin="4px 0 0 0"
            padding="8px 0px"
            border="none"
            fontWeight={700}
            fontSize={16}
            // disabled={isLoading}
            disabled={false}
          />
        </div>
      </form>
      <WrapperLinkOutSide>
        <Link to="/login">{t("back-to-login")}</Link>
      </WrapperLinkOutSide>
    </>
  );
};

export default ForgotPassword;
