import { IResponse } from "models";

export enum ActionType {
  GET_MEDICINE_LIST = "action/GET_MEDICINE_LIST",
  GET_MEDICINE_LIST_SUCCESS = "action/GET_MEDICINE_LIST_SUCCESS",
  GET_MEDICINE_LIST_ERROR = "action/GET_MEDICINE_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetMedicineListResponse extends IResponse {
  result: any;
}
