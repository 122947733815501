import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AppointmentsListResponse,
  IAppointmentsListPayload,
} from "models/appointments/appointmentsList";
import { httpStatus } from "configs/httpStatus";
import { getAppointmentsList } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./appointmentsListActions";

function* getAppointmentsListSaga({
  payload,
}: {
  payload: IAppointmentsListPayload;
}) {
  try {
    const response: AppointmentsListResponse = yield call(
      getAppointmentsList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAppointmentsListWatcher() {
  yield takeLatest(
    ActionType.APPOINTMENTS_LIST as any,
    getAppointmentsListSaga
  );
}

export default [fork(onGetAppointmentsListWatcher)];
