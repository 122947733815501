import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { Button, Modal } from "components";

import { theme } from "theme/theme";

export const ModalStyled = styled(Modal)`
  width: 1200px !important;
  .ant-modal-header {
    padding: 12px 16px 11px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  .ant-modal-close {
    right: -5px !important;
    top: -3px !important;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 32px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  .left-block {
    flex: 1;
    background: #fbfbfb;
    padding: 33px 16px 24px 0px;
    border-radius: 0px 0px 0px 10px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12) inset;
  }
  .right-block {
    min-width: 340px;
    border-left: 1px solid #cccccc;
    padding: 16px;
  }
`;

export const LeftBlockContent = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RightBlockContent = styled.div`
  width: 100%;
  max-height: 1100px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  color: ${theme.colors.text.primary};
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .table {
    margin-top: 16px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: #f2f2f2;
    .head {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .body {
      width: 100%;
      .item {
        .medicine {
          border-top: 1px solid #e0e0e0;
          padding: 7px 10px 7px 7px;
          border-bottom: 1px dashed ${theme.colors.border};
          background: #ffffff;
          .med-title {
            margin-left: 1px;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 16px;
          }
          .med-volume {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left,
            .right {
              display: flex;
              gap: 2px;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
            }
            .right {
            }
          }
        }
        .prescription {
          background: #ffffff;
          padding: 8px;
          display: flex;
          gap: 16px;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }

        &:last-child {
          .prescription {
            border-radius: 0px 0px 6px 6px;
          }
        }
      }
    }
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }

  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const SearchBlock = styled.div`
  color: ${theme.colors.text.primary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  margin-bottom: 32px;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    .input-group {
      position: relative;
      width: max-content;
      svg {
        position: absolute;
        right: 3px;
        top: 3px;
      }
    }
    .checkbox-group {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .ant-select-selection-search-input {
    padding-right: 17px !important;
    text-overflow: ellipsis;
  }
`;

export const SelectedDrugBlock = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  background: #ffffff;
  color: ${theme.colors.text.primary};
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .first-line {
    display: flex;
    align-items: center;
    gap: 8px;
    .drug {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      padding: 9px 8px 7px;
      border-radius: 6px;
      background: #f2f2f2;
      flex: 1;
    }
    .unit-block {
      display: flex;
      align-items: center;
      input {
        border: 1px solid rgba(224, 224, 224, 1);
        width: 60px;
        border-radius: 8px 0 0 8px;
        letter-spacing: 0px;
        padding: 4px 0 5px 12px;
      }
      .unit {
        border-radius: 0px 6px 6px 0px;
        background: #f2f2f2;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid ${theme.colors.textFieldBackground};
        border-left: none;
        height: 30px;
      }
    }
    button {
      width: 30px;
      height: 30px;
    }
  }
`;

export const InputBlock = styled.div`
  min-height: 54px;
  margin-top: 16px;
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.textFieldBackground};
  border-radius: 6px;
  .left {
    min-width: 102px;
    padding: 0px 16px;
    border-right: 1px solid ${theme.colors.textFieldBackground};
    background: ${theme.colors.background};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.text.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }
  .right {
    min-height: 54px;
    flex: 1;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    padding: 15px 16px;
  }
  .right-1 {
    padding: 0 16px;
    margin: 16px 0;
  }
`;

export const AudioWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 12px 16px;
  flex-wrap: wrap;
  .ant-radio-wrapper {
    height: max-content;
    align-items: center !important;
    display: flex;
    margin-right: 0px !important;
    margin-left: 2px;
  }
  .ant-radio-wrapper::after {
    display: none;
  }
  .ant-radio-wrapper > span:not(.ant-radio) {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    padding-left: 6px;
    padding-right: 0;
  }
  .ant-radio {
    top: 0 !important;
  }
  .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-inner::after {
    background-color: #2ac769;
    top: calc(50% - 6.5px);
    left: calc(50% - 6.5px);
    width: 13px;
    height: 13px;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-checked .ant-radio-inner {
    border-color: #cccccc;
  }
`;

export const AudioWrapperIndividual = styled.div`
  padding-top: 7px;
  .ant-radio-wrapper {
    height: max-content;
    align-items: center !important;
    display: flex;
    margin-right: 0px !important;
    margin-left: 2px;
  }
  .ant-radio-wrapper::after {
    display: none;
  }
  .ant-radio-wrapper > span:not(.ant-radio) {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    padding-left: 6px;
    padding-right: 0;
  }
  .ant-radio {
    top: 0 !important;
  }
  .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-inner::after {
    background-color: #2ac769;
    top: calc(50% - 6.5px);
    left: calc(50% - 6.5px);
    width: 13px;
    height: 13px;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-checked .ant-radio-inner {
    border-color: #cccccc;
  }
`;

export const TakeMedWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ConfirmButtonBlock = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const Label = styled.div`
  background: var(--leber-admin-color-primary-background, #f2f2f2);
  padding: 2px 6px;
  display: flex;
  gap: 16px;
  margin-bottom: 8px;

  div:first-child {
    color: var(--leber-admin-color-title-texts, #1a2f62);
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  div:last-child {
    color: var(--leber-admin-color-secondary-texts, #666);
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Text = styled.div`
  color: var(
    --leber-admin-color-caption-input-and-everywhere-text-use,
    #3b3b3b
  );
  font-family: Noto Sans JP;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
`;

export const Flex = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
`;

export const WrapperSelect = styled.div`
  display: flex;
  position: absolute;
  right: -85px;
  top: -8px;

  .unit {
    padding-top: 9px;
    padding-left: 4px;
  }
  > span {
    padding-top: 9px;
    padding-left: 4px;
  }
`;

export const WrapperSelectStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const WrapperSelectWithTextCenter = styled.div`
  display: flex;

  .unit {
    padding-top: 8px;
    padding-left: 4px;
    font-weight: 400;
  }
  > span {
    padding-top: 8px;
    padding-left: 4px;
    font-weight: 400;
  }
`;

export const WrapperDosage = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.textFieldBackground};
  margin-bottom: 16px;
`;

export const GroupButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  > button {
    height: 24px;
    span {
      margin-right: 3px !important;
      line-height: 17px;
    }
  }
`;

export const SelectedBodyPartsWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-checkbox-group {
      height: 24px;
    }
  }
`;

export const NotClickSection = styled.div<{
  disabled: boolean;
}>`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;

export const WrapperError = styled.div<{
  error: string;
}>`
  position: relative;
  padding: 4px 4px 0px;
  background: ${(props) => (props.error ? "#fee3e3" : "none")};
  border-radius: 4px;
  width: fit-content;
`;

export const WrapperErrorMessage = styled.div`
  position: absolute;
  right: -3px;
  top: 29%;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  display: block;
  background: ${theme.colors.white};
  color: ${theme.colors.error};
  font-size: 10px;
  font-weight: ${theme.fontWeight.regular};
  line-height: 10px !important;
  padding: 2px 4px;
  z-index: 100;
  border-radius: 4px;
  border: 1px solid ${theme.colors.error};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  width: max-content;
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -3px;
    height: 4px;
    width: 4px;
    background: ${theme.colors.error};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -1px;
    width: 3px;
    height: 4px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const TextRadio = styled.div<{
  disabled: boolean;
}>`
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;
