import {
  ICreateDoctorPayload,
  ActionType,
  CreateDoctorResponse,
} from "models/doctor/createDoctor";

export const createDoctorAction = (payload: ICreateDoctorPayload) => {
  return {
    type: ActionType.CREATE_DOCTOR,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_DOCTOR_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateDoctorResponse) => {
  return {
    type: ActionType.CREATE_DOCTOR_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_DOCTOR_RESET,
  };
};
