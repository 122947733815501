import { IResponse } from "models";

export enum ActionType {
  NOTIFY_MEDICAL_PLAN = "action/NOTIFY_MEDICAL_PLAN",
  NOTIFY_MEDICAL_PLAN_SUCCESS = "action/NOTIFY_MEDICAL_PLAN_SUCCESS",
  NOTIFY_MEDICAL_PLAN_ERROR = "action/NOTIFY_MEDICAL_PLAN_ERROR",
  NOTIFY_MEDICAL_PLAN_RESET = "action/NOTIFY_MEDICAL_PLAN_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface INotifyMedicalPlanPayload {
  medical_plan_id: number;
}

export interface NotifyMedicalPlanResponse extends IResponse {
  //   result: INotifyMedicalPlan;
}
