import React from "react";
import styled from "styled-components";
import { Image, ImageProps } from "antd";
import { theme } from "theme/theme";

export const ImageStyled = styled(Image)`
  height: 60px;
  width: 80px;
  border-radius: 8px;
  position: relative;
  left: -1px;
  top: -1px;
  cursor: pointer;
  ${theme.media.desktop} {
  }
`;

export const ImageWrapper = styled.div<{ visible: boolean }>`
  position: relative;
  /* border-color: ${(props) =>
    props.visible ? "#ffffff !important" : "#bdbdbd !important"}; */
  border-color: #ffffff !important;
  .ant-image-mask {
    display: none;
  }
`;

interface Image extends ImageProps {
  visible: boolean;
  src: string;
}

const CustomImage: React.FC<Image> = ({ src, visible, ...props }) => {
  return (
    <ImageWrapper visible={visible}>
      <div
        style={{
          zIndex: 1,
          width: 80,
          height: 61,
          borderRadius: 8,
          backgroundColor: "#ffffff",
          border: "1px dashed #bdbdbd",
          position: "absolute",
          bottom: -2,
          left: 0,
          marginBottom: 0,
          display: visible ? "none" : "block",
        }}
      ></div>
      <ImageStyled src={src} {...props} />
    </ImageWrapper>
  );
};

export default CustomImage;
