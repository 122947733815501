import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AppointmentUpdateStatusResponse,
} from "models/appointments/updateStatus";
import { httpStatus } from "configs/httpStatus";
import { updateStatus } from "services/appointments";

import { handleSuccessAction, handleErrorAction } from "./updateStatusActions";

function* updateStatusAppointmentSaga({ payload }: { payload: any }) {
  try {
    const response: AppointmentUpdateStatusResponse = yield call(
      updateStatus,
      payload
    );
    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateStatusAppointmentSagaWatcher() {
  yield takeLatest(
    ActionType.APPOINTMENT_UPDATE_STATUS as any,
    updateStatusAppointmentSaga
  );
}

export default [fork(onUpdateStatusAppointmentSagaWatcher)];
