import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays, addDays, getDate } from "date-fns";
import ja from "date-fns/locale/ja";

import {
  WrapperDateRangePicker,
  WrapperDateRangePickerSecond,
  Text,
  CalendarIconStyled,
  WrapperCalendarDay,
} from "./CustomDatePicker.style";

registerLocale("ja", ja);
const dateFormat = "yyyy/MM/dd";

const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth }: any) => {
  return (
    <>
      <button
        aria-label="Previous Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        onClick={decreaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          {"<"}
        </span>
      </button>
      <div className="react-datepicker__current-month">
        {monthDate.getFullYear()}年{monthDate.getMonth() + 1}月
      </div>
      <button
        aria-label="Next Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        onClick={increaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          {">"}
        </span>
      </button>
    </>
  );
};

const CustomDatePicker = ({
  startDate,
  onChange,
  locale,
  monthsShown,
}: any) => {
  const renderDayContents = (day: any, date: any) => {
    return (
      <WrapperCalendarDay>
        <span>{getDate(date)}</span>
        <p>予約5件</p>
      </WrapperCalendarDay>
    );
  };
  const highlightWithRanges: any = [
    {
      "react-datepicker__day--highlighted-appointment-1": [
        addDays(new Date(), 0),
        addDays(new Date(), 2),
        addDays(new Date(), 15),
      ],
    },
    {
      "react-datepicker__day--highlighted-appointment-2": [
        addDays(new Date(), 13),
        addDays(new Date(), 24),
      ],
    },
    {
      "react-datepicker__day--highlighted-appointment-3": [
        addDays(new Date(), 16),
      ],
    },
    {
      "react-datepicker__day--highlighted-appointment-4": [
        subDays(new Date(), 4),
        subDays(new Date(), 8),
      ],
    },
  ];

  return (
    <WrapperDateRangePicker style={{ display: "inline-flex" }}>
      <DatePicker
        locale={locale}
        renderCustomHeader={CustomHeader}
        selected={startDate}
        onChange={onChange}
        renderDayContents={renderDayContents}
        monthsShown={monthsShown}
        highlightDates={highlightWithRanges}
        inline
      />
    </WrapperDateRangePicker>
  );
};

export default CustomDatePicker;
