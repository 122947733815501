import {
  Action,
  ActionType,
  AppointmentsListResponse,
} from "models/appointments/appointmentsList";
import createReducer from "../../createReducer";

export interface AppointmentsListReducerType {
  loading: boolean;
  error?: string;
  appointmentsListResponse?: AppointmentsListResponse;
}

const defaultState: AppointmentsListReducerType = {
  loading: false,
  error: undefined,
  appointmentsListResponse: undefined,
};

export const appointmentsListReducer =
  createReducer<AppointmentsListReducerType>(defaultState, {
    [ActionType.APPOINTMENTS_LIST](state: AppointmentsListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.APPOINTMENTS_LIST_ERROR](
      state: AppointmentsListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        appointmentsListResponse: undefined,
      };
    },

    [ActionType.APPOINTMENTS_LIST_SUCCESS](
      state: AppointmentsListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentsListResponse: action.payload,
      };
    },

    [ActionType.APPOINTMENTS_LIST_RESET](state: AppointmentsListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentsListResponse: undefined,
      };
    },
  });
