import { IResponse } from "models";

export enum ActionType {
  GET_VERSION_HISTORY = "action/GET_VERSION_HISTORY",
  GET_VERSION_HISTORY_SUCCESS = "action/GET_VERSION_HISTORY_SUCCESS",
  GET_VERSION_HISTORY_ERROR = "action/GET_VERSION_HISTORY_ERROR",
  GET_VERSION_HISTORY_RESET = "action/GET_VERSION_HISTORY_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface VersionHistoryPayload {
  medical_record_id: number;
}

export interface VersionHistoryItem {
  created_at: string;
}

export interface VersionHistoryResponse extends IResponse {
  result: VersionHistoryItem[];
}
