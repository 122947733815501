import { IResponse } from "models";

export enum ActionType {
  GET_CLINICAL_DEPARTMENT_LIST = "action/GET_CLINICAL_DEPARTMENT_LIST",
  GET_CLINICAL_DEPARTMENT_LIST_SUCCESS = "action/GET_CLINICAL_DEPARTMENT_LIST_SUCCESS",
  GET_CLINICAL_DEPARTMENT_LIST_ERROR = "action/GET_CLINICAL_DEPARTMENT_LIST_ERROR",
  GET_CLINICAL_DEPARTMENT_LIST_RESET = "action/GET_CLINICAL_DEPARTMENT_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ClinicalDepartmentListResponse extends IResponse {
  result: any;
}
