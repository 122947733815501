import { IResponse } from "models";

export enum ActionType {
  CREATE_MEDICINE_BY_ID = "action/CREATE_MEDICINE_BY_ID",
  CREATE_MEDICINE_BY_ID_SUCCESS = "action/CREATE_MEDICINE_BY_ID_SUCCESS",
  CREATE_MEDICINE_BY_ID_ERROR = "action/CREATE_MEDICINE_BY_ID_ERROR",
  CREATE_MEDICINE_BY_ID_RESET = "action/CREATE_MEDICINE_BY_ID_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateMedicineByIDResponse extends IResponse {
  result: any;
  error_detail?: string;
}
