import {
  ActionType,
  IAppointmentsListPayload,
  MonthAppointmentsListResponse,
} from "models/appointments/monthAppointmentsList";

export const getMonthAppointmentsListAction = (
  payload: IAppointmentsListPayload
) => {
  return {
    type: ActionType.MONTH_APPOINTMENTS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MONTH_APPOINTMENTS_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: MonthAppointmentsListResponse) => {
  return {
    type: ActionType.MONTH_APPOINTMENTS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.MONTH_APPOINTMENTS_LIST_RESET,
  };
};
