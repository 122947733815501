import * as React from "react";

function DeleteIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1746 12.0002L18.4246 6.75849C18.5815 6.60157 18.6697 6.38874 18.6697 6.16682C18.6697 5.9449 18.5815 5.73207 18.4246 5.57515C18.2677 5.41823 18.0549 5.33008 17.833 5.33008C17.611 5.33008 17.3982 5.41823 17.2413 5.57515L11.9996 10.8252L6.75796 5.57515C6.60104 5.41823 6.38821 5.33008 6.16629 5.33008C5.94437 5.33008 5.73154 5.41823 5.57462 5.57515C5.4177 5.73207 5.32955 5.9449 5.32955 6.16682C5.32955 6.38874 5.4177 6.60157 5.57462 6.75849L10.8246 12.0002L5.57462 17.2418C5.49652 17.3193 5.43452 17.4115 5.39221 17.513C5.34991 17.6146 5.32812 17.7235 5.32812 17.8335C5.32812 17.9435 5.34991 18.0524 5.39221 18.154C5.43452 18.2555 5.49652 18.3477 5.57462 18.4252C5.65209 18.5033 5.74426 18.5653 5.84581 18.6076C5.94736 18.6499 6.05628 18.6717 6.16629 18.6717C6.2763 18.6717 6.38522 18.6499 6.48677 18.6076C6.58832 18.5653 6.68049 18.5033 6.75796 18.4252L11.9996 13.1752L17.2413 18.4252C17.3188 18.5033 17.4109 18.5653 17.5125 18.6076C17.614 18.6499 17.7229 18.6717 17.833 18.6717C17.943 18.6717 18.0519 18.6499 18.1534 18.6076C18.255 18.5653 18.3472 18.5033 18.4246 18.4252C18.5027 18.3477 18.5647 18.2555 18.607 18.154C18.6493 18.0524 18.6711 17.9435 18.6711 17.8335C18.6711 17.7235 18.6493 17.6146 18.607 17.513C18.5647 17.4115 18.5027 17.3193 18.4246 17.2418L13.1746 12.0002Z"
        fill="#FB4E4E"
      />
    </svg>
  );
}

export default DeleteIcon2;
