import {
  ActionType,
  INotifyMedicalPlanPayload,
} from "models/medicalPlans/notifyMedicalPlan";
import { IResponse } from "models";

export const notifyMedicalPlanAction = (payload: INotifyMedicalPlanPayload) => {
  return {
    type: ActionType.NOTIFY_MEDICAL_PLAN,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.NOTIFY_MEDICAL_PLAN_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IResponse) => {
  return {
    type: ActionType.NOTIFY_MEDICAL_PLAN_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.NOTIFY_MEDICAL_PLAN_RESET,
  };
};
