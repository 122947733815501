import { IResponse } from "models";

export enum ActionType {
  SEARCH_MEDICINE = "action/SEARCH_MEDICINE",
  SEARCH_MEDICINE_SUCCESS = "action/SEARCH_MEDICINE_SUCCESS",
  SEARCH_MEDICINE_ERROR = "action/SEARCH_MEDICINE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISearchMedicinePayload {
  name: string;
}

export interface ISearchMedicineResponse extends IResponse {
  result: {
    id: number;
    medicine_unit_id: number;
    medicine_unit_name: string;
    name: string;
    presets: null;
  }[];
}
