import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  UpdateMedicalRecordResponse,
} from "models/medicalRecords/updateMedicalRecord";
import { httpStatus } from "configs/httpStatus";
import { updateMedicalRecord } from "services/medicalRecords";

import { handleSuccessAction, handleErrorAction } from "./updateActions";

function* updateMedicalRecordSaga({ payload }: any) {
  try {
    const response: UpdateMedicalRecordResponse = yield call(
      updateMedicalRecord,
      payload
    );
    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateMedicalRecordWatcher() {
  yield takeLatest(
    ActionType.UPDATE_MEDICAL_RECORD as any,
    updateMedicalRecordSaga
  );
}

export default [fork(onUpdateMedicalRecordWatcher)];
