import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IPostDoctorSchedulesPayload,
  ActionType,
  PostDoctorSchedulesResponse,
} from "models/schedules/postDoctorSchedules";
import { httpStatus } from "configs/httpStatus";
import { postDoctorSchedules } from "services/schedules";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./postDoctorSchedulesActions";

function* postDoctorSchedulesSaga({
  payload,
}: {
  payload: IPostDoctorSchedulesPayload;
}) {
  try {
    const response: PostDoctorSchedulesResponse = yield call(
      postDoctorSchedules,
      payload
    );

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onPostDoctorSchedulesSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.POST_DOCTOR_SCHEDULES as any,
    postDoctorSchedulesSaga
  );
}

export default [fork(onPostDoctorSchedulesSagaSubmitWatcher)];
