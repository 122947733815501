import { createApiCall, MethodType, URI } from "../Api";

import { IMedicalRecordPayload } from "models/medicalRecords/medicalRecord";
import { VersionHistoryPayload } from "models/medicalRecords/versionHistory";

export const getMedicalRecord = (payload: IMedicalRecordPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICAL_RECORDS.GET_MEDICAL_RECORD(payload.record_id)
  );
};

export const createMedicalRecord = (payload: any) => {
  return createApiCall(
    MethodType.POST,
    URI.MEDICAL_RECORDS.CREATE_MEDICINE_RECORD(payload.id),
    payload.params
  );
};

export const updateMedicalRecord = (payload: any) => {
  return createApiCall(
    MethodType.PUT,
    URI.MEDICAL_RECORDS.GET_MEDICAL_RECORD(payload.id),
    payload.params
  );
};

export const getClinicalDepartmentList = () => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICAL_RECORDS.GET_CLINICAL_DEPARTMENT_LIST
  );
};

export const getVersionHistory = (payload: VersionHistoryPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICAL_RECORDS.GET_VERSION_HISTORY(payload.medical_record_id)
  );
};
