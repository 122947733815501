import * as React from "react";

function ReservationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "transparent" }}
      {...props}
    >
      <path
        d="M24 8H8V24H24V8Z"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="2.51"
        strokeMiterlimit="10"
      />
      <path
        d="M14.9281 23C14.1458 23 13.4131 22.702 12.8668 22.1432L6.8568 16.1329C6.31043 15.5866 6 14.8539 6 14.0716C6 13.2893 6.29802 12.5566 6.8568 12.0102C7.40316 11.4638 8.13578 11.1534 8.91807 11.1534C9.70036 11.1534 10.4206 11.4514 10.9793 12.0102L14.9281 15.9591L24.03 6.85683C24.5763 6.31045 25.309 6 26.0912 6C26.8735 6 27.6062 6.29803 28.1525 6.85683C29.2825 7.98685 29.2825 9.83711 28.1525 10.9795L16.9893 22.1432C16.443 22.6896 15.7104 23 14.9281 23Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M26.2443 6.60348C26.8007 6.60348 27.3571 6.81983 27.7744 7.23979C28.6216 8.09243 28.6216 9.47957 27.7744 10.3322L16.4062 21.7729C15.9762 22.2056 15.4325 22.4092 14.8761 22.4092C14.3197 22.4092 13.7633 22.1929 13.346 21.7729L7.22559 15.6135C6.37835 14.7609 6.37835 13.3737 7.22559 12.5211C7.65554 12.0884 8.19929 11.8848 8.75569 11.8848C9.31209 11.8848 9.86849 12.1011 10.2858 12.5211L14.8761 17.1407L24.7142 7.23979C25.1442 6.8071 25.6879 6.60348 26.2443 6.60348ZM26.2443 5C25.2453 5 24.2969 5.39451 23.5888 6.10717L14.8761 14.8754L11.4112 11.3885C10.7031 10.6758 9.75468 10.2813 8.75569 10.2813C7.7567 10.2813 6.8083 10.6758 6.10015 11.3885C4.63328 12.8647 4.63328 15.2699 6.10015 16.7334L12.2205 22.8928C12.9287 23.6055 13.8771 24 14.8761 24C15.8751 24 16.8235 23.6055 17.5316 22.8928L28.8998 11.4521C30.3667 9.97589 30.3667 7.57066 28.8998 6.10717C28.1917 5.39451 27.2433 5 26.2443 5Z"
        fill="white"
      />
    </svg>
  );
}

export default ReservationIcon;
