import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AppointmentDetailsResponse,
  IAppointmenDetailstPayload,
} from "models/appointments/appointmentDetails";
import { httpStatus } from "configs/httpStatus";
import { getAppointmentDetails } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./appointmentDetailsActions";

function* getAppointmentDetailsSaga({
  payload,
}: {
  payload: IAppointmenDetailstPayload;
}) {
  try {
    const response: AppointmentDetailsResponse = yield call(
      getAppointmentDetails,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.error || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAppointmentDetailsWatcher() {
  yield takeLatest(
    ActionType.APPOINTMENT_DETAILS as any,
    getAppointmentDetailsSaga
  );
}

export default [fork(onGetAppointmentDetailsWatcher)];
