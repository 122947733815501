import { IResponse } from "models";

export enum ActionType {
  APPOINTMENT_CANCEL_STATUS = "action/APPOINTMENT_CANCEL_STATUS",
  APPOINTMENT_CANCEL_STATUS_SUCCESS = "action/APPOINTMENT_CANCEL_STATUS_SUCCESS",
  APPOINTMENT_CANCEL_STATUS_ERROR = "action/APPOINTMENT_CANCEL_STATUS_ERROR",
  APPOINTMENT_CANCEL_STATUS_RESET = "action/APPOINTMENT_CANCEL_STATUS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AppointmentCancelStatusResponse extends IResponse {
  result: any;
}
