import { Row } from "antd";
import React from "react";
import styled from "styled-components";
import { theme } from "theme/theme";

interface IBox {
  title?: string;
  icon?: React.ReactNode;
  subTitle?: string;
  padding?: string;
  children?: React.ReactNode;
  action?: React.ReactNode;
  style?: any;
}

const Wrapper = styled.div``;

const Title = styled.h1(({ theme }) => ({
  color: theme.colors.text.primary,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.sizes.md,
  lineHeight: "23px",
  marginBottom: 24,
  marginRight: 4,
}));

const SubTitle = styled.h1(({ theme }) => ({
  color: theme.colors.text.primary,
  fontWeight: theme.fontWeight.medium,
  fontSize: theme.sizes.sm,
  lineHeight: "14px",
  marginBottom: 13,
}));

const Avatar = styled.div(({ theme }) => ({
  verticalAlign: "bottom",
  width: 28,
  height: 28,
  background: theme.colors.background,
}));

const Container = styled.div<IBox>(({ theme, padding }) => ({
  background: theme.colors.white,
  padding: padding,
  borderRadius: 8,
}));

const WrapperTitle = styled.div`
  position: relative;
`;

const WrapperAction = styled.div`
  position: initial;
  margin-bottom: 13px;
  ${theme.media.tablet} {
    position: absolute;
    right: 0;
    bottom: -9px;
    margin-bottom: 0px;
  }
`;

const Box: React.FC<IBox> = ({
  title,
  icon,
  subTitle,
  children,
  padding,
  action,
  ...props
}) => {
  return (
    <Wrapper>
      <WrapperTitle>
        <Row>
          {title && <Title>{title}</Title>}
          {icon && <Avatar>{icon}</Avatar>}
        </Row>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {action && <WrapperAction>{action}</WrapperAction>}
      </WrapperTitle>
      <Container {...props} padding={padding}>
        {children}
      </Container>
    </Wrapper>
  );
};

export default Box;
