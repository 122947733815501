import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  INotifyMedicalPlanPayload,
} from "models/medicalPlans/notifyMedicalPlan";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { notifyMedicalPlan } from "services/medicalPlans";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./notifyMedicalPlanActions";

function* notifyMedicalPlanSaga({
  payload,
}: {
  payload: INotifyMedicalPlanPayload;
}) {
  try {
    const response: IResponse = yield call(notifyMedicalPlan, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onNotifyMedicalPlanWatcher() {
  yield takeLatest(
    ActionType.NOTIFY_MEDICAL_PLAN as any,
    notifyMedicalPlanSaga
  );
}

export default [fork(onNotifyMedicalPlanWatcher)];
