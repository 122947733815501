import {
  Action,
  ActionType,
  AppointmentUpdateStatusResponse,
} from "models/appointments/updateStatus";
import createReducer from "../../createReducer";

export interface AppointmentUpdateStatusReducerType {
  loading: boolean;
  error?: string;
  appointmentUpdateStatusResponse?: AppointmentUpdateStatusResponse;
}

const defaultState: AppointmentUpdateStatusReducerType = {
  loading: false,
  error: undefined,
  appointmentUpdateStatusResponse: undefined,
};

export const appointmentUpdateStatusReducer =
  createReducer<AppointmentUpdateStatusReducerType>(defaultState, {
    [ActionType.APPOINTMENT_UPDATE_STATUS](
      state: AppointmentUpdateStatusReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_ERROR](
      state: AppointmentUpdateStatusReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        appointmentUpdateStatusResponse: undefined,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_SUCCESS](
      state: AppointmentUpdateStatusReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentUpdateStatusResponse: action.payload,
      };
    },

    [ActionType.APPOINTMENT_UPDATE_STATUS_RESET](
      state: AppointmentUpdateStatusReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        appointmentUpdateStatusResponse: undefined,
      };
    },
  });
