import {
  ISearchMedicinePayload,
  ISearchMedicineResponse,
  ActionType,
} from "models/medicine/searchMedicine";

export const searchMedicineAction = (payload: ISearchMedicinePayload) => {
  return {
    type: ActionType.SEARCH_MEDICINE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEARCH_MEDICINE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ISearchMedicineResponse) => {
  return {
    type: ActionType.SEARCH_MEDICINE_SUCCESS,
    payload,
  };
};
