export const dailyDosageFrequencyOptions = [
  {
    id: 1,
    name: 1,
    value: 1,
  },
  {
    id: 2,
    name: 2,
    value: 2,
  },
  {
    id: 3,
    name: 3,
    value: 3,
  },
  {
    id: 4,
    name: 4,
    value: 4,
  },
  {
    id: 5,
    name: 5,
    value: 5,
  },
  {
    id: 6,
    name: 6,
    value: 6,
  },
  {
    id: 7,
    name: 7,
    value: 7,
  },
  {
    id: 8,
    name: 8,
    value: 8,
  },
  {
    id: 9,
    name: 9,
    value: 9,
  },
  {
    id: 10,
    name: 10,
    value: "A",
  },
  {
    id: 11,
    name: 11,
    value: "B",
  },
  {
    id: 12,
    name: 12,
    value: "C",
  },
];

export const detailDosage = [
  {
    id: 0,
    name: "経口",
    value: 0,
    disabled: false,
  },
  {
    id: 1,
    name: "舌下",
    value: 1,
    disabled: true,
  },
  {
    id: 2,
    name: "バッカル",
    value: 2,
    disabled: true,
  },
  {
    id: 3,
    name: "口腔内塗布",
    value: 3,
    disabled: true,
  },
];

export const detailDosage2 = [
  {
    id: 0,
    name: "貼付",
    value: "A",
  },
  { id: 1, name: "塗布", value: "B" },
  { id: 2, name: "湿布", value: "C" },
  { id: 3, name: "撒布", value: "D" },
  { id: 4, name: "噴霧", value: "E" },
  { id: 5, name: "消毒", value: "F" },
  { id: 6, name: "点耳", value: "G" },
  { id: 7, name: "点眼", value: "H" },
  { id: 8, name: "点鼻", value: "J" },
  { id: 9, name: "うがい", value: "K" },
  { id: 10, name: "吸入", value: "L" },
  { id: 11, name: "トローチ", value: "M" },
  { id: 12, name: "膀胱洗浄", value: "N" },
  { id: 13, name: "鼻腔内洗浄", value: "P" },
  { id: 14, name: "浣腸", value: "Q" },
  { id: 15, name: "肛門挿入", value: "R" },
  { id: 16, name: "肛門注入", value: "S" },
  { id: 17, name: "膣内挿入", value: "T" },
  { id: 18, name: "膀胱注入", value: "U" },
];

export const timingMealsOptions = [
  {
    id: 1,
    name: "食前",
    value: 1,
  },
  { id: 2, name: "食直前", value: 2 },
  { id: 3, name: "食後", value: 4 },
  { id: 4, name: "食直後", value: 3 },
  { id: 5, name: "2時間後", value: 5 },
];

export const dispensingInstructionOptions = [
  {
    id: 1,
    name: "別包",
    value: "A",
  },
  { id: 2, name: "一包化", value: "B" },
  { id: 3, name: "混合", value: "C" },
  { id: 4, name: "粉砕", value: "D" },
];

export const supplyTypeOptions = [
  {
    id: 1,
    name: "日分",
    value: 1,
    disabled: false,
  },
  { id: 2, name: "回分", value: 2, disabled: true },
  { id: 3, name: "総量", value: 3, disabled: true },
];

export const additionalInfoOptions = [
  {
    id: 1,
    name: "から数回",
    value: "A",
  },
  { id: 2, name: "程度", value: "B" },
  { id: 3, name: "から", value: "C" },
];

export const additionalInfo2Options = [
  {
    id: 1,
    name: "約",
    value: "A",
  },
  { id: 2, name: "数時間", value: "B" },
  { id: 3, name: "から", value: "C" },
];

export const basicDosageOptions = [
  {
    id: 1,
    name: "内服薬",
    value: 1,
    disabled: false,
  },
  { id: 2, name: "外用薬", value: 2, disabled: true },
];

export const extraOptions = [
  {
    id: 1,
    name: "空腹時",
    value: 1,
    disabled: true,
  },
  { id: 2, name: "哺乳時", value: 2, disabled: true },
];

export const eventCategoryOptions = [
  {
    id: 1,
    name: "痛み",
    value: 1,
  },
  {
    id: 2,
    name: "呼吸",
    value: 2,
  },
  {
    id: 3,
    name: "循環器",
    value: 3,
  },
  {
    id: 4,
    name: "消化器",
    value: 4,
  },
  {
    id: 5,
    name: "腎",
    value: 5,
  },
  {
    id: 6,
    name: "精神神経",
    value: 6,
  },
  {
    id: 7,
    name: "熱",
    value: 7,
  },
  {
    id: 8,
    name: "皮膚",
    value: 8,
  },
  {
    id: 9,
    name: "その他症状",
    value: 9,
  },
  {
    id: 10,
    name: "医療",
    value: "A",
  },
  {
    id: 11,
    name: "生活",
    value: "B",
  },
  {
    id: 12,
    name: "その他",
    value: "W",
  },
];

export const firstEventNameOptions = [
  {
    id: 1,
    name: "疼痛時",
    value: 1,
  },
  {
    id: 2,
    name: "頭痛時",
    value: 2,
  },
  {
    id: 3,
    name: "歯痛時",
    value: 3,
  },
  {
    id: 4,
    name: "胸痛時",
    value: 4,
  },
  {
    id: 5,
    name: "腹痛時",
    value: 5,
  },
  {
    id: 6,
    name: "腰痛時",
    value: 6,
  },
  {
    id: 7,
    name: "関節痛時",
    value: 7,
  },
];

export const secondEventNameOptions = [
  {
    id: 1,
    name: "喘鳴時",
    value: 1,
  },
  {
    id: 2,
    name: "喘息発作時",
    value: 2,
  },
  {
    id: 3,
    name: "喉がゴロゴロする時",
    value: 3,
  },
  {
    id: 4,
    name: "しゃっくり時",
    value: 4,
  },
  {
    id: 5,
    name: "咳込時",
    value: 5,
  },
];

export const thirdEventNameOptions = [
  {
    id: 1,
    name: "血圧上昇時",
    value: 1,
  },
  {
    id: 2,
    name: "血圧上昇時○○mmHg以上",
    value: 2,
  },
  {
    id: 3,
    name: "血糖値○○mg/dL以上",
    value: 3,
  },
];

export const fourEventNameOptions = [
  {
    id: 1,
    name: "便秘時",
    value: 1,
  },
  {
    id: 2,
    name: "お腹がゴロゴロする時",
    value: 2,
  },
  {
    id: 3,
    name: "下痢時",
    value: 3,
  },
  {
    id: 4,
    name: "排便時",
    value: 4,
  },
  {
    id: 5,
    name: "嘔吐時",
    value: 5,
  },
  {
    id: 6,
    name: "口腔乾燥時",
    value: 6,
  },
  {
    id: 7,
    name: "吐き気時",
    value: 7,
  },
  {
    id: 8,
    name: "空腹時",
    value: 8,
  },
  {
    id: 9,
    name: "出血時",
    value: 9,
  },
];

export const fiveEventNameOptions = [
  {
    id: 1,
    name: "乏尿時",
    value: 1,
  },
  {
    id: 2,
    name: "乏尿時○○mL/時間未満",
    value: 2,
  },
  {
    id: 3,
    name: "多尿時",
    value: 3,
  },
  {
    id: 4,
    name: "多尿時○○mL/時間以上",
    value: 4,
  },
  {
    id: 5,
    name: "むくみ時",
    value: 5,
  },
];

export const sixEventNameOptions = [
  {
    id: 1,
    name: "不眠時",
    value: 1,
  },
  {
    id: 2,
    name: "不安時",
    value: 2,
  },
  {
    id: 3,
    name: "不穏時",
    value: 3,
  },
  {
    id: 4,
    name: "いらいら時",
    value: 4,
  },
  {
    id: 5,
    name: "けいれん時",
    value: 5,
  },
  {
    id: 6,
    name: "めまい時",
    value: 6,
  },
  {
    id: 7,
    name: "疲労時",
    value: 7,
  },
];

export const sevenEventNameOptions = [
  {
    id: 1,
    name: "発熱時",
    value: 1,
  },
  {
    id: 2,
    name: "発熱時（○○度以上）",
    value: 2,
  },
  {
    id: 3,
    name: "悪寒時",
    value: 3,
  },
];

export const eightEventNameOptions = [
  {
    id: 1,
    name: "かゆい時",
    value: 1,
  },
  {
    id: 2,
    name: "発疹時",
    value: 2,
  },
];

export const nightEventNameOptions = [
  {
    id: 1,
    name: "発作時",
    value: 1,
  },
  {
    id: 2,
    name: "症状ある時",
    value: 2,
  },
  {
    id: 3,
    name: "不穏時",
    value: 3,
  },
  {
    id: 4,
    name: "いらいら時",
    value: 4,
  },
  {
    id: 5,
    name: "けいれん時",
    value: 5,
  },
  {
    id: 6,
    name: "めまい時",
    value: 6,
  },
  {
    id: 7,
    name: "疲労時",
    value: 7,
  },
];

export const tenEventNameOptions = [
  {
    id: 1,
    name: "検査前",
    value: 1,
  },
  {
    id: 2,
    name: "検査前○○分",
    value: 2,
  },
  {
    id: 3,
    name: "検査時",
    value: 3,
  },
  {
    id: 4,
    name: "手術前",
    value: 4,
  },
  {
    id: 5,
    name: "手術中",
    value: 5,
  },
  {
    id: 6,
    name: "手術後",
    value: 6,
  },
  {
    id: 7,
    name: "処置前",
    value: 7,
  },
  {
    id: 8,
    name: "処置時",
    value: 8,
  },
  {
    id: 9,
    name: "処置後",
    value: 9,
  },
];

export const eleventEventNameOptions = [
  {
    id: 1,
    name: "起床時",
    value: 1,
  },
  {
    id: 2,
    name: "入浴前",
    value: 2,
  },
  {
    id: 3,
    name: "食事前",
    value: 3,
  },
  {
    id: 4,
    name: "食事後",
    value: 4,
  },
  {
    id: 5,
    name: "就寝前",
    value: 5,
  },
  {
    id: 6,
    name: "外出時",
    value: 6,
  },
  {
    id: 7,
    name: "哺乳時",
    value: 7,
  },
];

export const twelveEventNameOptions = [
  {
    id: 1,
    name: "必要時",
    value: 1,
  },
  {
    id: 2,
    name: "適宜",
    value: 2,
  },
];

export const commonTimeOptions = [
  {
    id: 0,
    name: "0",
    value: "A",
  },
  {
    id: 1,
    name: "1",
    value: "B",
  },
  {
    id: 2,
    name: "2",
    value: "C",
  },
  {
    id: 3,
    name: "3",
    value: "D",
  },
  {
    id: 4,
    name: "4",
    value: "E",
  },
  {
    id: 5,
    name: "5",
    value: "F",
  },

  {
    id: 6,
    name: "6",
    value: "G",
  },
  {
    id: 7,
    name: "7",
    value: "H",
  },
  {
    id: 8,
    name: "8",
    value: "I",
  },
  {
    id: 9,
    name: "9",
    value: "J",
  },
  {
    id: 10,
    name: "10",
    value: "K",
  },
  {
    id: 11,
    name: "11",
    value: "L",
  },
  {
    id: 12,
    name: "12",
    value: "M",
  },
  {
    id: 13,
    name: "13",
    value: "N",
  },
  {
    id: 14,
    name: "14",
    value: "O",
  },
  {
    id: 15,
    name: "15",
    value: "P",
  },
  {
    id: 16,
    name: "16",
    value: "Q",
  },
  {
    id: 17,
    name: "17",
    value: "R",
  },
  {
    id: 18,
    name: "18",
    value: "S",
  },
  {
    id: 19,
    name: "19",
    value: "T",
  },
  {
    id: 20,
    name: "20",
    value: "U",
  },
  {
    id: 21,
    name: "21",
    value: "V",
  },
  {
    id: 22,
    name: "22",
    value: "W",
  },
  {
    id: 23,
    name: "23",
    value: "X",
  },
];

export const associatedBodyPartsOptions = [
  {
    id: 0,
    name: "全身",
    value: 0,
    subValues: ["10", "01L", "01R", "01B"],
  },
  {
    id: 1,
    name: "頭皮",
    value: 1,
    subValues: ["120", "12L", "12R", "12B"],
  },
  {
    id: 2,
    name: "頭部",
    value: 2,
    subValues: ["130", "13L", "13R", "13B"],
  },
  {
    id: 3,
    name: "頭頂部",
    value: 3,
    subValues: ["140", "14L", "14R", "14B"],
  },
  {
    id: 4,
    name: "頭頂部",
    value: 4,
    subValues: ["150", "15L", "15R", "15B"],
  },
  {
    id: 5,
    name: "ひたい",
    value: 5,
    subValues: ["210", "21L", "21R", "21B"],
  },
  {
    id: 6,
    name: "顔",
    value: 6,
    subValues: ["220", "22L", "22R", "22B"],
  },
  {
    id: 7,
    name: "まゆ",
    value: 7,
    subValues: ["230", "23L", "23R", "23B"],
  },
  {
    id: 8,
    name: "まゆのまわり",
    value: 8,
    subValues: ["240", "24L", "24R", "24B"],
  },
  {
    id: 9,
    name: "まぶた",
    value: 9,
    subValues: ["250", "25L", "25R", "25B"],
  },
  {
    id: 10,
    name: "眼",
    value: 10,
    subValues: ["260", "26L", "26R", "26B"],
  },
  {
    id: 11,
    name: "目のまわり",
    value: 11,
    subValues: ["270", "27L", "27R", "27B"],
  },
  {
    id: 12,
    name: "頬",
    value: 12,
    subValues: ["310", "31L", "31R", "31B"],
  },
  {
    id: 13,
    name: "鼻",
    value: 13,
    subValues: ["320", "32L", "32R", "32B"],
  },
  {
    id: 14,
    name: "鼻のまわり",
    value: 14,
    subValues: ["330", "33L", "33R", "33B"],
  },
  {
    id: 15,
    name: "鼻の下",
    value: 15,
    subValues: ["340", "34L", "34R", "34B"],
  },
  {
    id: 16,
    name: "鼻腔内",
    value: 16,
    subValues: ["350", "35L", "35R", "35B"],
  },
  {
    id: 17,
    name: "耳",
    value: 17,
    subValues: ["420", "42L", "42R", "42B"],
  },
  {
    id: 18,
    name: "耳たぶ",
    value: 18,
    subValues: ["430", "43L", "43R", "43B"],
  },
  {
    id: 19,
    name: "耳のうしろ",
    value: 19,
    subValues: ["440", "44L", "44R", "44B"],
  },
  {
    id: 20,
    name: "耳のまわり",
    value: 20,
    subValues: ["450", "45L", "45R", "45B"],
  },
  {
    id: 21,
    name: "耳の中",
    value: 21,
    subValues: ["460", "46L", "46R", "46B"],
  },
  {
    id: 22,
    name: "口",
    value: 22,
    subValues: ["520", "52L", "52R", "52B"],
  },
  {
    id: 23,
    name: "口のまわり",
    value: 23,
    subValues: ["530", "53L", "53R", "53B"],
  },
  {
    id: 24,
    name: "口唇",
    value: 24,
    subValues: ["540", "54L", "54R", "54B"],
  },
  {
    id: 25,
    name: "口腔内",
    value: 25,
    subValues: ["550", "55L", "55R", "55B"],
  },
  {
    id: 26,
    name: "口腔内ほほの内側",
    value: 26,
    subValues: ["560", "56L", "56R", "56B"],
  },
  {
    id: 27,
    name: "口腔内上あご部",
    value: 27,
    subValues: ["570", "57L", "57R", "57B"],
  },
  {
    id: 28,
    name: "上歯茎部",
    value: 28,
    subValues: ["580", "58L", "58R", "58B"],
  },
  {
    id: 29,
    name: "下歯茎部",
    value: 29,
    subValues: ["590", "59L", "59R", "59B"],
  },
  {
    id: 30,
    name: "舌",
    value: 30,
    subValues: ["5A0", "5AL", "5AR", "5AB"],
  },
  {
    id: 31,
    name: "舌の裏側",
    value: 31,
    subValues: ["5B0", "5BL", "5BR", "5BB"],
  },
  {
    id: 32,
    name: "喉の奥",
    value: 32,
    subValues: ["610", "61L", "61R", "61B"],
  },
  {
    id: 33,
    name: "扁桃腺部",
    value: 33,
    subValues: ["620", "62L", "62R", "62B"],
  },
  {
    id: 34,
    name: "下あご",
    value: 34,
    subValues: ["630", "63L", "63R", "63B"],
  },
  {
    id: 35,
    name: "首",
    value: 35,
    subValues: ["640", "64L", "64R", "64B"],
  },
  {
    id: 36,
    name: "うなじ",
    value: 36,
    subValues: ["650", "65L", "65R", "65B"],
  },
  {
    id: 37,
    name: "肩",
    value: 37,
    subValues: ["710", "71L", "71R", "71B"],
  },
  {
    id: 38,
    name: "上肢",
    value: 38,
    subValues: ["720", "72L", "72R", "72B"],
  },
  {
    id: 39,
    name: "腕",
    value: 39,
    subValues: ["730", "73L", "73R", "73B"],
  },
  {
    id: 40,
    name: "上腕",
    value: 40,
    subValues: ["740", "74L", "74R", "74B"],
  },
  {
    id: 41,
    name: "前腕",
    value: 41,
    subValues: ["750", "75L", "75R", "75B"],
  },
  {
    id: 42,
    name: "ひじ",
    value: 42,
    subValues: ["760", "76L", "76R", "76B"],
  },
  {
    id: 43,
    name: "手",
    value: 43,
    subValues: ["770", "77L", "77R", "77B"],
  },
  {
    id: 44,
    name: "手の甲",
    value: 44,
    subValues: ["780", "78L", "78R", "78B"],
  },
  {
    id: 45,
    name: "手のひら",
    value: 45,
    subValues: ["790", "79L", "79R", "79B"],
  },
  {
    id: 46,
    name: "手の指",
    value: 46,
    subValues: ["7A0", "7AL", "7AR", "7AB"],
  },
];

const mealTimingCommon = ["upon_waking", "before_bedtime"];
export const mealTiming = ["breakfast", "dinner", "lunch", ...mealTimingCommon];
export const mealTiming2 = ["morning", "evening", "noon", ...mealTimingCommon];
const mealTimingJaCommon = { upon_waking: "起床時", before_bedtime: "就寝前" };
export const mealTimingJa: any = {
  breakfast: "朝",
  lunch: "昼",
  dinner: "夕",
  ...mealTimingJaCommon,
};
export const mealTimingJa2: any = {
  morning: "朝",
  noon: "昼",
  evening: "夜",
  ...mealTimingJaCommon,
};
