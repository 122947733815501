import {
  ActionType,
  Action,
  PostDoctorSchedulesResponse,
} from "models/schedules/postDoctorSchedules";
import createReducer from "../../createReducer";

export interface PostDoctorSchedulesReducerType {
  loading: boolean;
  error?: string;
  postDoctorSchedulesResponse?: PostDoctorSchedulesResponse;
}

const defaultState: PostDoctorSchedulesReducerType = {
  loading: false,
  error: undefined,
  postDoctorSchedulesResponse: undefined,
};

export const postDoctorSchedulesReducer =
  createReducer<PostDoctorSchedulesReducerType>(defaultState, {
    [ActionType.POST_DOCTOR_SCHEDULES](state: PostDoctorSchedulesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.POST_DOCTOR_SCHEDULES_ERROR](
      state: PostDoctorSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        postDoctorSchedulesResponse: undefined,
      };
    },

    [ActionType.POST_DOCTOR_SCHEDULES_SUCCESS](
      state: PostDoctorSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        postDoctorSchedulesResponse: action.payload,
      };
    },

    [ActionType.POST_DOCTOR_SCHEDULES_RESET](
      state: PostDoctorSchedulesReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        postDoctorSchedulesResponse: undefined,
      };
    },
  });
