import { put, takeLatest, fork, call } from "redux-saga/effects";

import { authTokenKey } from "services/Api";
import { setCookie, localStorageHelper, getDefaultScreen } from "utils";
import { LoginData, ActionType, LoginResponse } from "models/authentication";
import { httpStatus } from "configs/httpStatus";
import { fetchLogin } from "services/auth";

import { handleSuccesAction, handleErrorAction } from "./authenticationActions";
import { history } from "../configureStore";

function* loginSaga({ payload }: { payload: LoginData }) {
  try {
    const response: LoginResponse = yield call(fetchLogin, payload);

    if (response.status === httpStatus.StatusOK) {
      // const token = response.result.auth_token.split(" ")[1];
      const token = response.result.auth_token;

      setCookie(authTokenKey, token);
      localStorageHelper.setItem("doctor_name", "Dr. " + response.result.name);
      // localStorageHelper.setItem("role", payload.user.role);
      // localStorageHelper.setItem("id", response.result.id);

      history.push("/schedules");
      yield put(handleSuccesAction(token));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onLoginSubmitWatcher() {
  yield takeLatest(ActionType.LOGIN_USER as any, loginSaga);
}

export default [fork(onLoginSubmitWatcher)];
