import React from "react";
import styled from "styled-components";

import { theme } from "theme/theme";

export const WrapperErrorMessage = styled.div`
  position: absolute;
  bottom: -4px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  display: block;
  background: ${theme.colors.white};
  color: ${theme.colors.error};
  font-size: 10px;
  font-weight: ${theme.fontWeight.regular};
  line-height: 10px !important;
  padding: 2px 4px;
  z-index: 100;
  border-radius: 4px;
  border: 1px solid ${theme.colors.error};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  width: max-content;
  &::after {
    content: "";
    position: absolute;
    top: -3px;
    left: 18px;
    height: 4px;
    width: 4px;
    background: ${theme.colors.error};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -2.2px;
    left: 18.2px;
    width: 3.8px;
    height: 3.8px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;
