import { ISearchMedicinePayload } from "models/medicine/searchMedicine";
import { createApiCall, MethodType, URI } from "../Api";

export const searchMedicine = (payload: ISearchMedicinePayload) => {
  return createApiCall(MethodType.GET, URI.MEDICINE.SEARCH_MEDICINE, payload);
};

export const createMedicine = (payload: any) => {
  return createApiCall(
    MethodType.POST,
    URI.MEDICINE.CREATE_MEDICINE(payload.id),
    payload.params
  );
};

export const getMedicineList = () => {
  return createApiCall(MethodType.GET, URI.MEDICINE.GET_MEDICINE);
};

export const getMedicineListByID = (payload: any) => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICINE.GET_MEDICINE_LIST_BY_ID(payload.id)
  );
};

export const updateMedicineByID = (payload: any) => {
  return createApiCall(
    MethodType.PATCH,
    URI.MEDICINE.DELETE_MEDICINE_BY_ID(payload.id),
    payload.params
  );
};

export const createMedicineByID = (payload: any) => {
  return createApiCall(
    MethodType.POST,
    URI.MEDICINE.GET_MEDICINE_LIST_BY_ID(payload.id),
    payload.params
  );
};

export const deleteMedicineByID = (payload: any) => {
  return createApiCall(
    MethodType.DELETE,
    URI.MEDICINE.DELETE_MEDICINE_BY_ID(payload.id)
  );
};

export const subSearchMedicine = (payload: any) => {
  return createApiCall(
    MethodType.GET,
    URI.MEDICINE.SUB_SEARCH_MEDICINE,
    payload
  );
};
