import { Action, ActionType } from "models/medicalPlans/notifyMedicalPlan";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface NotifyMedicalPlanReducerType {
  loading: boolean;
  error?: string;
  notifyMedicalPlanResponse?: IResponse;
}

const defaultState: NotifyMedicalPlanReducerType = {
  loading: false,
  error: undefined,
  notifyMedicalPlanResponse: undefined,
};

export const notifyMedicalPlanReducer =
  createReducer<NotifyMedicalPlanReducerType>(defaultState, {
    [ActionType.NOTIFY_MEDICAL_PLAN](state: NotifyMedicalPlanReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.NOTIFY_MEDICAL_PLAN_ERROR](
      state: NotifyMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        notifyMedicalPlanResponse: undefined,
      };
    },

    [ActionType.NOTIFY_MEDICAL_PLAN_SUCCESS](
      state: NotifyMedicalPlanReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        notifyMedicalPlanResponse: action.payload,
      };
    },

    [ActionType.NOTIFY_MEDICAL_PLAN_RESET](
      state: NotifyMedicalPlanReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        notifyMedicalPlanResponse: undefined,
      };
    },
  });
