import {
  ICreateMedicineResponse,
  ActionType,
} from "models/medicine/createMedicine";

export const createMedicineAction = (payload: any) => {
  return {
    type: ActionType.CREATE_MEDICINE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_MEDICINE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ICreateMedicineResponse) => {
  return {
    type: ActionType.CREATE_MEDICINE_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_MEDICINE_RESET,
  };
};
