import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  MedicalPlanResponse,
  IMedicalPlanPayload,
} from "models/medicalPlans/medicalPlan";
import { httpStatus } from "configs/httpStatus";
import { getMedicalPlan } from "services/medicalPlans";

import { handleSuccessAction, handleErrorAction } from "./medicalPlanActions";

function* getMedicalPlanSaga({ payload }: { payload: IMedicalPlanPayload }) {
  try {
    const response: MedicalPlanResponse = yield call(getMedicalPlan, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetMedicalPlanWatcher() {
  yield takeLatest(ActionType.GET_MEDICAL_PLAN as any, getMedicalPlanSaga);
}

export default [fork(onGetMedicalPlanWatcher)];
