import {
  ClinicalDepartmentListResponse,
  ActionType,
} from "models/medicalRecords/clinicalDepartmentList";

export const getClinicalDepartmentList = () => {
  return {
    type: ActionType.GET_CLINICAL_DEPARTMENT_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CLINICAL_DEPARTMENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: ClinicalDepartmentListResponse
) => {
  return {
    type: ActionType.GET_CLINICAL_DEPARTMENT_LIST_SUCCESS,
    payload,
  };
};
