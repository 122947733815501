import styled from "styled-components";

import { theme } from "theme/theme";

export const WrapperSection = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 39px 15px 15px;
  width: 100%;
  height: max-content;
  ${theme.media.tablet} {
    padding: 39px 23px 15px;
    width: 50%;
  }
  ${theme.media.desktop} {
    width: max-content;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -16px;
  left: 9px;
  background: ${theme.colors.white};
  padding: 0 8px;
`;

export const FormTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.lg};
  line-height: 26px;
  color: ${theme.colors.titleText};
`;

export const PasswordContainer = styled.div`
  input {
    width: 100%;
  }
  ${theme.media.tablet} {
    margin-bottom: 61px;
  }
  ${theme.media.laptop} {
    margin-bottom: unset;
    input {
      width: 280px;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 39px;
  ${theme.media.tablet} {
    flex-wrap: unset;
    gap: 16px;
  }
  ${theme.media.desktop} {
    gap: 24px;
  }
`;
