import {
  Action,
  ActionType,
  MonthSchedulesResponse,
} from "models/schedules/monthSchedules";
import createReducer from "../../createReducer";

export interface MonthSchedulesReducerType {
  loading: boolean;
  error?: string;
  monthSchedulesResponse?: MonthSchedulesResponse;
}

const defaultState: MonthSchedulesReducerType = {
  loading: false,
  error: undefined,
  monthSchedulesResponse: undefined,
};

export const monthSchedulesReducer = createReducer<MonthSchedulesReducerType>(
  defaultState,
  {
    [ActionType.MONTH_SCHEDULE_LIST](state: MonthSchedulesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MONTH_SCHEDULE_LIST_ERROR](
      state: MonthSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        monthSchedulesResponse: undefined,
      };
    },

    [ActionType.MONTH_SCHEDULE_LIST_SUCCESS](
      state: MonthSchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        monthSchedulesResponse: action.payload,
      };
    },

    [ActionType.MONTH_SCHEDULE_LIST_RESET](state: MonthSchedulesReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        monthSchedulesResponse: undefined,
      };
    },
  }
);
