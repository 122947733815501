import { IResponse } from "models";

export enum ActionType {
  WEEK_SCHEDULE_LIST = "action/WEEK_SCHEDULE_LIST",
  WEEK_SCHEDULE_LIST_SUCCESS = "action/WEEK_SCHEDULE_LIST_SUCCESS",
  WEEK_SCHEDULE_LIST_ERROR = "action/WEEK_SCHEDULE_LIST_ERROR",
  WEEK_SCHEDULE_LIST_RESET = "action/WEEK_SCHEDULE_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IWeekSchedulesPayload {
  doctor_id: number;
  payload: {
    start_date: string;
    end_date: string;
  };
}

export interface IWeekSchedule {
  id: number;
  doctor_id: number;
  schedule_status_id: number;
  doctor_name: string;
  date: string;
  weekday_index: number;
  start_time: string;
  end_time: string;
}

export interface WeekSchedulesResponse extends IResponse {
  result: IWeekSchedule[];
}
