import { IResponse } from "models";

export enum ActionType {
  EDIT_DOCTOR = "action/EDIT_DOCTOR",
  EDIT_DOCTOR_SUCCESS = "action/EDIT_DOCTOR_SUCCESS",
  EDIT_DOCTOR_ERROR = "action/EDIT_DOCTOR_ERROR",
  EDIT_DOCTOR_RESET = "action/EDIT_DOCTOR_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IEditDoctorPayload {
  id: any;
  doctor: {
    email: string;
    // password: string;
    // last_name: string;
    // first_name: string;
    // kana_last_name: string;
    // kana_first_name: string;
  };
}

export interface EditDoctorResponse extends IResponse {
  result: any;
}
