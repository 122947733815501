import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IDeleteSchedulePayload,
  ActionType,
  DeleteScheduleResponse,
} from "models/schedules/deleteSchedule";
import { httpStatus } from "configs/httpStatus";
import { deleteSchedule } from "services/schedules";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./deleteScheduleActions";

function* deleteScheduleSaga({ payload }: { payload: IDeleteSchedulePayload }) {
  try {
    const response: DeleteScheduleResponse = yield call(
      deleteSchedule,
      payload
    );

    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDeleteScheduleSagaSubmitWatcher() {
  yield takeLatest(ActionType.DELETE_SCHEDULE as any, deleteScheduleSaga);
}

export default [fork(onDeleteScheduleSagaSubmitWatcher)];
