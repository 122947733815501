import { IResponse } from "models";

export enum ActionType {
  MONTH_SCHEDULE_LIST = "action/MONTH_SCHEDULE_LIST",
  MONTH_SCHEDULE_LIST_SUCCESS = "action/MONTH_SCHEDULE_LIST_SUCCESS",
  MONTH_SCHEDULE_LIST_ERROR = "action/MONTH_SCHEDULE_LIST_ERROR",
  MONTH_SCHEDULE_LIST_RESET = "action/MONTH_SCHEDULE_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMonthSchedulesPayload {
  start_date: string;
  end_date: string;
}

export interface IMonthSchedule {
  id: number;
  doctor_id: number;
  schedule_status_id: number;
  doctor_name: string;
  date: string;
  weekday_index: number;
  start_time: string;
  end_time: string;
}

export interface MonthSchedulesResponse extends IResponse {
  result: IMonthSchedule[];
}
