import { IResponse } from "models";

export enum ActionType {
  CREATE_MEDICINE = "action/CREATE_MEDICINE",
  CREATE_MEDICINE_SUCCESS = "action/CREATE_MEDICINE_SUCCESS",
  CREATE_MEDICINE_ERROR = "action/CREATE_MEDICINE_ERROR",
  CREATE_MEDICINE_RESET = "action/CREATE_MEDICINE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateMedicineResponse extends IResponse {
  result: any;
}
