import { IResponse } from "models";

export enum ActionType {
  APPOINTMENT_UPDATE_STATUS = "action/APPOINTMENT_UPDATE_STATUS",
  APPOINTMENT_UPDATE_STATUS_SUCCESS = "action/APPOINTMENT_UPDATE_STATUS_SUCCESS",
  APPOINTMENT_UPDATE_STATUS_ERROR = "action/APPOINTMENT_UPDATE_STATUS_ERROR",
  APPOINTMENT_UPDATE_STATUS_RESET = "action/APPOINTMENT_UPDATE_STATUS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AppointmentUpdateStatusResponse extends IResponse {
  result: any;
}
