import {
  ICreateBulkSchedulesPayload,
  ActionType,
  CreateBulkSchedulesResponse,
} from "models/schedules/createBulkSchedules";

export const createBulkSchedulesAction = (
  payload: ICreateBulkSchedulesPayload
) => {
  return {
    type: ActionType.CREATE_BULK_SCHEDULES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_BULK_SCHEDULES_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateBulkSchedulesResponse) => {
  return {
    type: ActionType.CREATE_BULK_SCHEDULES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_BULK_SCHEDULES_RESET,
  };
};
