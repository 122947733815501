import { RootState } from "../../rootReducer";

export const selectIsLoadingAppointmentDetails = (state: RootState) =>
  state.updateStatusConsultationAppointment.loading;
export const selectAppointmentDetailsErrorMessage = (state: RootState) =>
  state.updateStatusConsultationAppointment.error;
export const updateStatusConsultationAppointmentDataResponse = (
  state: RootState
) =>
  state.updateStatusConsultationAppointment
    .appointmentUpdateStatusConsultationResponse;
