import {
  Action,
  ActionType,
  VersionHistoryResponse,
} from "models/medicalRecords/versionHistory";
import createReducer from "../../createReducer";

export interface VersionHistoryReducerType {
  loading: boolean;
  error?: string;
  versionHistoryResponse?: VersionHistoryResponse;
}

const defaultState: VersionHistoryReducerType = {
  loading: false,
  error: undefined,
  versionHistoryResponse: undefined,
};

export const versionHistoryReducer = createReducer<VersionHistoryReducerType>(
  defaultState,
  {
    [ActionType.GET_VERSION_HISTORY](state: VersionHistoryReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_VERSION_HISTORY_ERROR](
      state: VersionHistoryReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        versionHistoryResponse: undefined,
      };
    },

    [ActionType.GET_VERSION_HISTORY_SUCCESS](
      state: VersionHistoryReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        versionHistoryResponse: action.payload,
      };
    },

    [ActionType.GET_VERSION_HISTORY_RESET](state: VersionHistoryReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        versionHistoryResponse: undefined,
      };
    },
  }
);
