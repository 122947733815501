import { IResponse } from "models";

export enum ActionType {
  CREATE_BULK_SCHEDULES = "action/CREATE_BULK_SCHEDULES",
  CREATE_BULK_SCHEDULES_SUCCESS = "action/CREATE_BULK_SCHEDULES_SUCCESS",
  CREATE_BULK_SCHEDULES_ERROR = "action/CREATE_BULK_SCHEDULES_ERROR",
  CREATE_BULK_SCHEDULES_RESET = "action/CREATE_BULK_SCHEDULES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDoctorSchedule {
  doctor_id: number;
  schedule: {
    schedule_date: string;
    start_time: string;
    end_time: string;
  };
}

export interface ICreateBulkSchedulesPayload {
  schedules: IDoctorSchedule[];
}

export interface CreateBulkSchedulesResponse extends IResponse {}
