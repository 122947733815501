import {
  ActionType,
  Action,
  DeleteDoctorResponse,
} from "models/doctor/deleteDoctor";
import createReducer from "../../createReducer";

export interface DeleteDoctorReducerType {
  loading: boolean;
  error?: string;
  deleteDoctorResponse?: DeleteDoctorResponse;
}

const defaultState: DeleteDoctorReducerType = {
  loading: false,
  error: undefined,
  deleteDoctorResponse: undefined,
};

export const deleteDoctorReducer = createReducer<DeleteDoctorReducerType>(
  defaultState,
  {
    [ActionType.DELETE_DOCTOR](state: DeleteDoctorReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_DOCTOR_ERROR](
      state: DeleteDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleteDoctorResponse: undefined,
      };
    },

    [ActionType.DELETE_DOCTOR_SUCCESS](
      state: DeleteDoctorReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteDoctorResponse: action.payload,
      };
    },

    [ActionType.DELETE_DOCTOR_RESET](state: DeleteDoctorReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteDoctorResponse: undefined,
      };
    },
  }
);
