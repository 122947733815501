import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ISubSearchMedicineResponse,
} from "models/medicine/subSearchMedicine";
import { httpStatus } from "configs/httpStatus";
import { subSearchMedicine } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./subSearchActions";

function* subSearchMedicineSaga({ payload }: { payload: any }) {
  try {
    const response: ISubSearchMedicineResponse = yield call(
      subSearchMedicine,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleSuccesAction(response));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onSubSearchMedicineWatcher() {
  yield takeLatest(
    ActionType.SUB_SEARCH_MEDICINE as any,
    subSearchMedicineSaga
  );
}

export default [fork(onSubSearchMedicineWatcher)];
