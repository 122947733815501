import {
  ISubSearchMedicineResponse,
  Action,
  ActionType,
} from "models/medicine/subSearchMedicine";
import createReducer from "../../createReducer";

export interface SubSearchMedicineReducerType {
  loading: boolean;
  error?: string;
  subSearchMedicineResponse?: ISubSearchMedicineResponse;
}

const defaultState: SubSearchMedicineReducerType = {
  loading: false,
  error: "",
  subSearchMedicineResponse: undefined,
};

export const subSearchMedicineReducer =
  createReducer<SubSearchMedicineReducerType>(defaultState, {
    [ActionType.SUB_SEARCH_MEDICINE](state: SubSearchMedicineReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SUB_SEARCH_MEDICINE_ERROR](
      state: SubSearchMedicineReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.SUB_SEARCH_MEDICINE_SUCCESS](
      state: SubSearchMedicineReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        subSearchMedicineResponse: action.payload,
      };
    },
  });
