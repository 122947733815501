import {
  IDeleteMedicineByIDResponse,
  Action,
  ActionType,
} from "models/medicine/deleteByID";
import createReducer from "../../createReducer";

export interface DeleteMedicineByIDReducerType {
  loading: boolean;
  error?: string;
  deleteMedicineByIDResponse?: IDeleteMedicineByIDResponse;
}

const defaultState: DeleteMedicineByIDReducerType = {
  loading: false,
  error: "",
  deleteMedicineByIDResponse: undefined,
};

export const deleteMedicineByIDReducer =
  createReducer<DeleteMedicineByIDReducerType>(defaultState, {
    [ActionType.DELETE_MEDICINE_BY_ID](state: DeleteMedicineByIDReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_MEDICINE_BY_ID_ERROR](
      state: DeleteMedicineByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_MEDICINE_BY_ID_SUCCESS](
      state: DeleteMedicineByIDReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteMedicineByIDResponse: action.payload,
      };
    },

    [ActionType.DELETE_MEDICINE_BY_ID_RESET](
      state: DeleteMedicineByIDReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteMedicineByIDResponse: undefined,
      };
    },
  });
