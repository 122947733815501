import {
  ActionType,
  IMedicalRecordPayload,
  MedicalRecordResponse,
} from "models/medicalRecords/medicalRecord";

export const getMedicalRecordAction = (payload: IMedicalRecordPayload) => {
  return {
    type: ActionType.GET_MEDICAL_RECORD,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_MEDICAL_RECORD_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: MedicalRecordResponse) => {
  return {
    type: ActionType.GET_MEDICAL_RECORD_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.GET_MEDICAL_RECORD_RESET,
  };
};
