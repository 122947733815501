import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  VersionHistoryResponse,
  VersionHistoryPayload,
} from "models/medicalRecords/versionHistory";
import { httpStatus } from "configs/httpStatus";
import { getVersionHistory } from "services/medicalRecords";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./versionHistoryActions";

function* getVersionHistorySaga({
  payload,
}: {
  payload: VersionHistoryPayload;
}) {
  try {
    const response: VersionHistoryResponse = yield call(
      getVersionHistory,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetVersionHistoryWatcher() {
  yield takeLatest(
    ActionType.GET_VERSION_HISTORY as any,
    getVersionHistorySaga
  );
}

export default [fork(onGetVersionHistoryWatcher)];
