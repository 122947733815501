import * as React from "react";

function ScheduleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "transparent" }}
      {...props}
    >
      <path
        d="M24 6H8C6.89543 6 6 6.89543 6 8V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24V8C26 6.89543 25.1046 6 24 6Z"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.85 12.86H9.20996V15.5H11.85V12.86Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M15.52 12.86H12.88V15.5H15.52V12.86Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M19.2001 12.86H16.5601V15.5H19.2001V12.86Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M22.88 12.86H20.24V15.5H22.88V12.86Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M11.85 16.6H9.20996V19.24H11.85V16.6Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M15.52 16.6H12.88V19.24H15.52V16.6Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M19.2001 16.6H16.5601V19.24H19.2001V16.6Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M22.88 16.6H20.24V19.24H22.88V16.6Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M11.85 20.34H9.20996V22.98H11.85V20.34Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M15.52 20.34H12.88V22.98H15.52V20.34Z"
        fill={props.fill || "#3B3B3B"}
      />
      <path
        d="M10 10.09H22"
        stroke={props.stroke || "#3B3B3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ScheduleIcon;
