import {
  IGetMedicineListResponse,
  ActionType,
} from "models/medicine/getMedicineList";

export const getMedicineListAction = () => {
  return {
    type: ActionType.GET_MEDICINE_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_MEDICINE_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetMedicineListResponse) => {
  return {
    type: ActionType.GET_MEDICINE_LIST_SUCCESS,
    payload,
  };
};
