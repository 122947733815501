import httpClient2 from "configs/httpClient2";
import { localStorageHelper } from "utils";

export const authTokenKey = process.env.REACT_APP_ACCESS_TOKEN_KEY!;

export enum MethodType {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const id = localStorageHelper.getItem("id");

export const URI = {
  AUTH: {
    LOGIN: "/v1/admins/login",
    FORGOTPASSWORD: "/web_api_v1/users/forgot_password",
    RESETPASSWORD: "/web_api_v1/users/password_reset",
  },
  DOCTOR: {
    DOCTOR_LIST: "/v1/admins/doctors",
    DELETE_DOCTOR: (doctor_id: number) => `/v1/admins/doctors/${doctor_id}`,
    EDIT_DOCTOR: (doctor_id: number) => `/v1/admins/doctors/${doctor_id}`,
    CREATE_DOCTOR: "/v1/admins/doctors",
  },
  SCHEDULES: {
    MONTH_SCHEDULES: "/v1/admins/schedules",
    WEEK_SCHEDULES: (doctor_id: number) =>
      `/v1/admins/doctors/${doctor_id}/schedules`,
    POST_DOCTOR_SCHEDULES: (doctor_id: number) =>
      `/v1/admins/doctors/${doctor_id}/schedules`,
    DELETE_SCHEDULE: (schedule_id: number) =>
      `/v1/admins/schedules/${schedule_id}`,
    CREATE_BULK_SCHEDULES: "/v1/admins/doctors/schedules/bulk",
  },
  APPOINTMENTS: {
    APPOINTMENTS_LIST: "/v1/admins/appointments",
    DOCTOR_APPOINTMENTS: (doctor_id: number) =>
      `/v1/admins/doctors/${doctor_id}/appointments`,
    APPOINTMENT_DETAILS: (appointment_id: number) =>
      `/v1/admins/appointments/${appointment_id}`,
    APPOINTMENT_UPDATE_STATUS: (appointment_id: number) =>
      `/v1/admins/appointments/${appointment_id}/agree`,
    APPOINTMENT_UPDATE_STATUS_CONSULTATION: (appointment_id: number) =>
      `/v1/admins/appointments/${appointment_id}/agree_only_consultation`,
    APPOINTMENT_CANCEL_STATUS: (appointment_id: number) =>
      `/v1/admins/appointments/${appointment_id}/cancel_agree`,
  },
  MEDICAL_RECORDS: {
    GET_MEDICAL_RECORD: (record_id: number) =>
      `/v1/admins/medical_records/${record_id}`,
    CREATE_MEDICINE_RECORD: (id: number) =>
      `/v1/admins/appointments/${id}/medical_records`,
    GET_CLINICAL_DEPARTMENT_LIST: "v1/admins/clinical_departments",
    GET_VERSION_HISTORY: (record_id: number) =>
      `/v1/admins/medical_records/${record_id}/versions`,
  },
  MEDICINE: {
    SEARCH_MEDICINE: "/v1/admins/medicines",
    CREATE_MEDICINE: (id: number) =>
      `/v1/admins/medical_records/${id}/prescriptions/create_from_coded`,
    GET_MEDICINE: "/v1/admins/prescriptions",
    GET_MEDICINE_LIST_BY_ID: (id: number) =>
      `/v1/admins/medical_records/${id}/prescriptions`,
    DELETE_MEDICINE_BY_ID: (id: number) => `/v1/admins/prescriptions/${id}`,
    SUB_SEARCH_MEDICINE: "/v1/admins/coded_prescriptions",
  },
  MEDICAL_PLANS: {
    GET_MEDICAL_PLAN: (record_id: number) =>
      `/v1/admins/medical_records/${record_id}/medical_plans`,
    POST_MEDICAL_PLAN: (record_id: number) =>
      `/v1/admins/medical_plans/${record_id}/notify`,
    CREATE_MEDICAL_PLAN: (record_id: number) =>
      `/v1/admins/medical_records/${record_id}/medical_plans`,
    EDIT_MEDICAL_PLAN: (plan_id: number) =>
      `/v1/admins/medical_plans/${plan_id}`,
  },
};

const getMethod = async (url: string, params: any) => {
  return httpClient2.get(url, { params });
};

const postMethod = async (url: string, params: any) => {
  return httpClient2.post(url, params);
};

const putMethod = async (url: string, params: any) => {
  return httpClient2.put(url, params);
};

const deleteMethod = async (url: string, params: any) => {
  return httpClient2.delete(url, { params });
};

const patchMethod = async (url: string, params: any) => {
  return httpClient2.patch(url, params);
};

const methods: any = {
  GET: (url: string, params: any) => getMethod(url, params),
  POST: (url: string, params: any) => postMethod(url, params),
  PUT: (url: string, params: any) => putMethod(url, params),
  DELETE: (url: string, params: any) => deleteMethod(url, params),
  PATCH: (url: string, params: any) => patchMethod(url, params),
};

export const createApiCall = async (
  method: string,
  url: string,
  params = {}
) => {
  const { data } = await methods[method](url, params);
  return data;
};
