import * as React from "react";

function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
        stroke="#B7B7B7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L18.2929 19.7071ZM19.7071 18.2929L15.7071 14.2929L14.2929 15.7071L18.2929 19.7071L19.7071 18.2929Z"
        fill="#B7B7B7"
      />
    </svg>
  );
}

export default SearchIcon;
