import { IResponse } from "models";

export enum ActionType {
  SUB_SEARCH_MEDICINE = "action/SUB_SEARCH_MEDICINE",
  SUB_SEARCH_MEDICINE_SUCCESS = "action/SUB_SEARCH_MEDICINE_SUCCESS",
  SUB_SEARCH_MEDICINE_ERROR = "action/SUB_SEARCH_MEDICINE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISubSearchMedicineResponse extends IResponse {
  result: any;
}
