import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  IGetMedicineListByIDResponse,
} from "models/medicine/getMedicineListByID";
import { httpStatus } from "configs/httpStatus";
import { getMedicineListByID } from "services/medicine";

import { handleSuccesAction, handleErrorAction } from "./listByIDActions";

function* getMedicineListByIDSaga({ payload }: { payload: any }) {
  try {
    const response: IGetMedicineListByIDResponse = yield call(
      getMedicineListByID,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    }
    if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetMedicineListByIDWatcher() {
  yield takeLatest(
    ActionType.GET_MEDICINE_LIST_BY_ID as any,
    getMedicineListByIDSaga
  );
}

export default [fork(onGetMedicineListByIDWatcher)];
