import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  CreateMedicalPlanResponse,
  ICreateMedicalPlanPayload,
} from "models/medicalPlans/createMedicalPlan";
import { httpStatus } from "configs/httpStatus";
import { createMedicalPlan } from "services/medicalPlans";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./createMedicalPlanActions";

function* createMedicalPlanSaga({
  payload,
}: {
  payload: ICreateMedicalPlanPayload;
}) {
  try {
    const response: CreateMedicalPlanResponse = yield call(
      createMedicalPlan,
      payload
    );
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateMedicalPlanWatcher() {
  yield takeLatest(
    ActionType.CREATE_MEDICAL_PLAN as any,
    createMedicalPlanSaga
  );
}

export default [fork(onCreateMedicalPlanWatcher)];
