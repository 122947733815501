import { IResponse } from "models";

export enum ActionType {
  EDIT_MEDICAL_PLAN = "action/EDIT_MEDICAL_PLAN",
  EDIT_MEDICAL_PLAN_SUCCESS = "action/EDIT_MEDICAL_PLAN_SUCCESS",
  EDIT_MEDICAL_PLAN_ERROR = "action/EDIT_MEDICAL_PLAN_ERROR",
  EDIT_MEDICAL_PLAN_RESET = "action/EDIT_MEDICAL_PLAN_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IEditMedicalPlanPayload {
  medical_plan_id: number;
  medical_plan: any;
}

export interface EditMedicalPlanResponse extends IResponse {
  result: any;
}
