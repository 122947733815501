import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ClinicalDepartmentListResponse,
} from "models/medicalRecords/clinicalDepartmentList";
import { httpStatus } from "configs/httpStatus";
import { getClinicalDepartmentList } from "services/medicalRecords";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./clinicalDepartmentListActions";

function* getClinicalDepartmentListSaga() {
  try {
    const response: ClinicalDepartmentListResponse = yield call(
      getClinicalDepartmentList
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetClinicalDepartmentListWatcher() {
  yield takeLatest(
    ActionType.GET_CLINICAL_DEPARTMENT_LIST as any,
    getClinicalDepartmentListSaga
  );
}

export default [fork(onGetClinicalDepartmentListWatcher)];
