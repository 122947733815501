import { IResponse } from "models";

export enum ActionType {
  GET_MEDICAL_PLAN = "action/GET_MEDICAL_PLAN",
  GET_MEDICAL_PLAN_SUCCESS = "action/GET_MEDICAL_PLAN_SUCCESS",
  GET_MEDICAL_PLAN_ERROR = "action/GET_MEDICAL_PLAN_ERROR",
  GET_MEDICAL_PLAN_RESET = "action/GET_MEDICAL_PLAN_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMedicalPlanPayload {
  record_id: number;
}

export interface IMedicalPlan {
  id: number;
  hospital_name: string;
  hospital_director_name: string;
  disease_name: string;
  details: string;
  period: string;
  doctors_name: any;
  urgent_hospital_name: string;
  parent_name: string;
  patient_name: string;
}

export interface MedicalPlanResponse extends IResponse {
  result: IMedicalPlan;
}
