import { IResponse } from "models";

export enum ActionType {
  GET_MEDICAL_RECORD = "action/GET_MEDICAL_RECORD",
  GET_MEDICAL_RECORD_SUCCESS = "action/GET_MEDICAL_RECORD_SUCCESS",
  GET_MEDICAL_RECORD_ERROR = "action/GET_MEDICAL_RECORD_ERROR",
  GET_MEDICAL_RECORD_RESET = "action/GET_MEDICAL_RECORD_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMedicalRecordPayload {
  record_id: number;
}

export interface IMedicalRecord {
  id: number;
  subjective: string;
  objective: string;
  objective_temperature: number;
  objective_sbp: number;
  objective_dbp: number;
  objective_bpm: number;
  objective_spo2: number;
  assessment: string;
  plan: string;
  clinical_department_id: number;
  clinical_department: string;
}

export interface MedicalRecordResponse extends IResponse {
  result: IMedicalRecord;
}
