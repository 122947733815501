import styled from "styled-components";
import { Checkbox } from "antd";

import { Table, Button, Select, Input } from "components";
import { theme } from "theme/theme";
import { Modal } from "./components";

export const WrapperSchedule = styled.div`
  position: relative;
`;

export const Alert = styled.div<{ background: string }>`
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px;
    height: 58px;
    background: ${(props) => props.background};
    color: ${theme.colors.white};
    position: absolute;
    right: 0;
    top: -21px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 20px;
    font-weight: ${theme.fontWeight.bold};
    max-width: 68%;
  }
  ${theme.media.laptop} {
    max-width: none;
  }
`;

export const AlertMobile = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 58px;
  padding-left: 8px;
  background: ${(props) => props.background};
  color: ${theme.colors.white};
  position: absolute;
  right: 0;
  top: -21px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 20px;
  font-weight: ${theme.fontWeight.bold};
  z-index: 2;
`;

export const Tooltip4AlertMobile = styled.div`
  position: absolute;
  top: -21px;
  right: 75px;
  height: 58px;
  width: 173px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  line-height: 20px;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 45%;
    right: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 45%;
    right: -1px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  fontSize: "16px",
  lineHeight: "16px",
  margin: "10px 0px 16px",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 20,
    top: 1,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox + span": {
    paddingLeft: 5,
    alignSelf: "center",
  },
}));

export const WapperBtnItem = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  justify-content: flex-end;
  margin-bottom: 8px;
  flex-direction: row;
  .div-btn {
    width: 100%;
    text-align: end;
    display: flex;
    flex-direction: row;
    gap: 16px;
    button {
      width: 50%;
      margin: 0;
    }
    ${theme.media.tablet} {
      width: max-content;
      button {
        width: unset;
      }
    }
    ${theme.media.desktop} {
      flex-direction: column;
    }
    .single-btn {
      margin: 0px auto;
      width: 112px;
      ${theme.media.tablet} {
        width: unset;
        margin-top: 8px;
        margin-right: 8px;
      }
      ${theme.media.desktop} {
        margin-top: unset;
        margin-right: unset;
      }
    }
    .btn-order-left {
      order: 2;
      ${theme.media.desktop} {
        order: 1;
      }
    }
    .btn-order-right {
      order: 1;
      ${theme.media.desktop} {
        order: 2;
      }
    }
  }
  ${theme.media.desktop} {
    flex-direction: row;
    margin-bottom: unset;
    margin-right: 8px;
  }
`;

export const WapperBlockItem = styled.div`
  margin-top: 15px;
`;

export const DivBlockItem = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  :last-child {
    margin-bottom: 8px;
  }
  .item-left {
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    background: #f2f2f2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 110px;
    width: 100%;
    input {
      color: #3b3b3b !important;
      text-align: end;
    }
    ${theme.media.tablet} {
      min-height: 136px;
      max-width: 206px;
    }
    ${theme.media.desktop} {
      padding: 22px 24px 22px;
      height: auto;
    }
  }
  ${theme.media.tablet} {
    gap: 16px;
    flex-direction: row;
  }
  .item-left-block {
    min-height: 110px;
    ${theme.media.tablet} {
      min-height: 98px;
      min-height: unset;
      height: auto;
    }
  }
  .wrapper-block {
    ${theme.media.tablet} {
      margin-right: 20px;
    }
    ${theme.media.desktop} {
      margin-right: unset;
    }
  }
`;

export const WapperFilter = styled.div`
  background: #f2f2f2;
  margin-top: 24px;
  border-radius: 8px;
  padding: 8px;
  .btn-previous,
  .btn-next {
    position: absolute;
    top: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    border: none;
  }
  .btn-previous {
    left: 5px;
  }
  .btn-next {
    left: auto;
    right: 5px;
  }
  .btn-previous span {
    top: -10px;
    left: -1px;
  }
  .btn-next span {
    top: -10px;
    right: -1px;
    left: auto;
  }
  .react-datepicker__navigation-icon::before {
    top: 7px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: #2f8cae;
  }
  .react-datepicker__navigation-icon::before {
    top: 7px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: #2f8cae;
  }
  .pTittle {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #2f8cae;
  }
`;

export const WrapCheckboxFilter = styled.div`
  .ant-checkbox + span {
    line-height: 16px !important;
    font-size: 16px !important;
    padding-right: 0;
  }

  ${theme.media.desktop} {
    margin-top: 2px;
    margin-bottom: 39px;
    .ant-checkbox-wrapper {
      margin: 0px;
    }
  }
`;

export const WapperCheckboxItem = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .ant-checkbox-group {
    > div > div {
      height: max-content;
      .ant-checkbox-wrapper {
        align-items: center;
        span {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
  ${theme.media.mobileM} {
    margin-top: 16px;
  }
  ${theme.media.tablet} {
    margin-top: auto;
    margin-right: 8px;
    gap: 24px;
  }
  ${theme.media.desktop} {
    margin-right: unset;
    flex-direction: row;
    gap: 32px;
  }
`;

export const GroupBtnFilter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 8px;
  button {
    width: 139px;
  }
  ${theme.media.tablet} {
    justify-content: end;
    margin-bottom: unset;
    button {
      width: 96px;
    }
    button:first-child {
      margin-right: 16px;
    }
  }
  ${theme.media.desktop} {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0px;
  }
`;
export const DivInput = styled.div`
  width: 100%;
  margin: 0px 0px 24px 0px;
  font-weight: bold;
  font-size: 18px;
  ${theme.media.mobileM} {
    input {
      display: none;
    }
    textarea {
      display: block;
      height: 82px;
      padding: 8px 12px;
      line-height: 22px;
    }
  }
  ${theme.media.tablet} {
    margin: 0px 0px 16px 0px;
    input {
      height: 32px;
      display: block;
      width: 100%;
    }
    textarea {
      display: none;
    }
  }
  ${theme.media.desktop} {
    input {
      width: 100%;
      max-width: 738px;
    }
    margin: 0px 0px 16px 0px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .content-location {
    margin-bottom: 24px;
  }
  > p {
    margin-bottom: 32px;
  }
  ${theme.media.tablet} {
    .content-location {
      margin-bottom: 16px;
    }
    > p {
      margin-bottom: 6px;
    }
  }
  ${theme.media.desktop} {
    margin-right: unset;
  }
`;

export const DivContent = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  ${theme.media.mobileM} {
    margin: 24px 0px;
  }
  ${theme.media.tablet} {
    margin: 4px 0px 16px;
  }
`;

export const WapperSelectTimeForItem = styled.div`
  ${theme.media.mobileM} {
    padding-left: 0px;
    .div-right-item {
      display: flex;
      margin: 24px 0px 32px 0px;
    }
    .dleft,
    .dright {
      width: 100%;
    }
    .dright {
      margin-top: 10px;
      overflow: hidden;
    }
  }
  ${theme.media.tablet} {
    .bor-div-time {
      display: flex;
    }
    .dleft,
    .dright {
      display: flex;
      width: auto;
    }
    .dright {
      margin: auto;
      margin-top: 0px;
    }
    .div-right-item {
      padding-top: 16px;
      margin: 0;
    }
  }
  ${theme.media.laptop} {
    .ant-select-selection-item {
      padding-right: 18px;
    }
    .div-right-item {
      padding-top: 5px;
      margin: 0px 0px 0px 16px;
    }
    display: flex;
  }
  ${theme.media.desktop} {
    .div-right-item {
      padding-top: 0px;
      margin: 0px 0px 0px 16px;
    }
  }
`;

export const WrapperAction = styled.div`
  background: ${theme.colors.white};
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px;
  ${theme.media.tablet} {
    padding: 16px;
  }
  ${theme.media.desktop} {
    display: flex;
    column-gap: 32px;
  }
`;

export const FirstBlock = styled.div`
  margin-bottom: 24px;
  ${theme.media.tablet} {
    display: flex;
    column-gap: 32px;
    margin-bottom: 20px;
    > div:first-child {
      min-width: 340px;
      > label {
        margin: 2px 0px;
      }
    }
    > div:last-child {
      width: 100%;
    }
  }
  ${theme.media.desktop} {
    flex-direction: column;
    width: 39%;
    margin-bottom: 0;
  }
`;

export const SecondBlock = styled.div`
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }
  ${theme.media.laptopL} {
    column-gap: 50px;
  }
  ${theme.media.desktop} {
    flex-direction: column;
    row-gap: 13px;
    align-items: start;
  }
`;

export const FixSelect = styled.div`
  > div {
    margin-top: 20px;
  }
`;

export const FirstInputStyled = styled(Input)`
  width: 165px;
  ${theme.media.laptopL} {
    width: 138px;
  }
`;

export const SecondBlockTop = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 24px;
  ${theme.media.tablet} {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 0px;
  }
`;

export const SecondBlockBottom = styled.div`
  > div:first-child {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
  }
  ${theme.media.tablet} {
    display: flex;
    column-gap: 8px;
    > div:first-child {
      margin-bottom: 0px;
    }
  }
`;

export const WapperDatePicker = styled.div`
  ${theme.media.mobileM} {
    .div-date-picker-mobile {
      display: block;
    }
    .div-date-picker-desktop {
      display: none;
    }
  }
  ${theme.media.laptop} {
    .div-date-picker-mobile {
      display: none;
    }
    .div-date-picker-desktop {
      display: block;
    }
  }
  ${theme.media.desktop} {
    .div-date-picker-desktop {
      display: block;
      > div {
        height: max-content;
        border-radius: 8px;
        > div {
          height: max-content;
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const ScheduleTableStyled = styled(Table)`
  .ant-table-content {
    border-radius: 4px;
    /* border: 1px solid #cccccc; */
    border: none;
    table {
      min-width: calc(100% - 4px) !important;
    }
  }
  .ant-table-thead > tr > th {
    padding: 8px 15px;
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    background: #f2f2f2 !important;
    border-color: #cccccc !important;
    border: none !important;
    :last-child {
      border-right: none;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    padding: 17px 23px 17px 12px;
  } */
  .ant-table table {
    border-spacing: 0 4px;
  }
  .ant-table-tbody > tr > td {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 9px 12px;
    background: #ffffff !important;
    border-color: #cccccc !important;
    margin-top: 4px;
    border-top: 1px solid #e0e0e0;
    :first-child {
      border-width: 1px;
      border-left: 1px solid #e0e0e0;
      padding: 9px 16px 9px 15px;
      border-radius: 6px 0px 0px 6px;
    }
    :last-child {
      padding: 9px 15px 9px 16px;
      border-right: 1px solid #e0e0e0;
      border-radius: 0px 6px 6px 0px;
      margin-right: 4px;
    }
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    font-weight: 700 !important;
    span {
      font-weight: 400;
    }
  }
  .ant-table-tbody > tr > td:nth-child(5) {
    position: relative;
    div {
      top: 9px;
      position: absolute;
      height: calc(100% - 18px);
      width: calc(100% - 24px);
      border-radius: 4px;
      background: #f2f2f2;
      padding: 12px 8px 14px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: left;
  }
  /* .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 14px;
    padding-right: 14px;
  } */
  .ant-table-tbody > tr.ant-table-row {
    border-radius: 6px;
    border: 1px solid #e0e0e0 !important;
    background: #ffffff;
  }
  .ant-table-tbody > tr.ant-table-measure-row {
    display: none;
  }
  .ant-table-tbody > tr.ant-table-row:nth-child(2) {
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.12) !important;
    > td:first-child {
      color: #fb4e4e;
      font-weight: 700;
    }
  }
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #ffffff !important;
  }
  button {
    width: max-content;
  }
  /* .ant-table-tbody > tr > td {
    vertical-align: top;
  } */
  .ant-table-tbody > tr > td:first-child {
    border-right: none !important;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: right;
    svg {
      transform: rotate(180deg);
      cursor: pointer;
      position: relative;
      top: 4px;
    }
  }
`;

export const ScheduleTableStyledAdmin = styled(Table)`
  .ant-table-content {
    border-radius: 4px;
    border: 1px solid #cccccc;
    border-bottom: none;
  }
  .ant-table-thead > tr > th {
    padding: 8px 15px;
    color: #666666;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px; /* 100% */
    background: #f2f2f2 !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    padding: 17px 23px 17px 12px;
  } */
  .ant-table-tbody > tr > td {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 8px 16px 12px;
    background: #ffffff !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
    :first-child {
      border-width: 1px;
    }
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    color: #2f8cae;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    text-align: left;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: left;
    padding: 10px 16px 10px;
  }
  /* .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 14px;
    padding-right: 14px;
  } */
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #ffffff !important;
  }
  button {
    width: max-content;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
  .ant-table-tbody > tr > td:nth-child(7) {
    vertical-align: middle;
  }
`;

export const WrapperFullCalendar = styled.div<{
  displayMode: "month" | "week";
}>`
  position: relative;
  .week-confirm {
    position: absolute;
    top: 4px;
    right: 150px;
    border: none;
    &:disabled {
      background: rgb(204, 204, 204) !important;
      border-color: rgb(204, 204, 204) !important;
    }
    &:hover {
      background: rgba(47, 140, 174, 0.4);
      border: 1px solid #2f8cae;
      color: #ffffff !important;
      padding: 7px 7px 6px !important;
    }
  }
  .fc-toolbar-title {
    /* margin-right: 120px; */
  }
  /* .fc .fc-scrollgrid-section-liquid > td {
    border-bottom-right-radius: 8px;
  }  */
  .fc-prev-button,
  .fc-next-button {
    width: 60px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.button};
    margin-right: 16px;
    background: ${theme.colors.white};

    &:hover {
      background: #99e4b7;
      border-color: ${theme.colors.button};
      .fc-icon {
        color: #ffffff;
      }
    }
  }
  .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background: unset;
    border-color: ${theme.colors.button};
  }
  .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }
  .fc-icon {
    color: ${theme.colors.button};
  }

  .fc-col-header-cell {
    background: ${theme.colors.background};
    border-bottom: ${(props) =>
      props.displayMode === "week" ? "none" : "1px solid #dddddd"};
  }
  .fc-timegrid-axis {
    border-bottom: ${(props) =>
      props.displayMode === "week" ? "none" : "1px solid #dddddd"};
  }
  .fc-col-header-cell-cushion {
    color: ${theme.colors.text.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    padding: 8px 4px;
  }

  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-gridMonth-button,
  .fc-gridWeek-button {
    width: 60px;
    height: 26px;
    border-radius: 4px;
    margin-right: 4px;
    background-color: ${theme.colors.background};
    color: #2ac769 !important;
    border: none;
    outline: none;
    box-shadow: none !important;
    line-height: 15px;
    font-size: 18px;
  }
  .fc-dayGridMonth-button.fc-button-active,
  .fc-timeGridWeek-button.fc-button-active,
  .fc-gridMonth-button.fc-button-active,
  .fc-gridWeek-button.fc-button-active {
    color: #ffffff !important;
    background-color: #2ac769 !important;
    opacity: 1 !important;
  }
  .fc-timeGridWeek-button {
    margin-right: 0px;
  }

  .fc-button-active {
    background-color: ${theme.colors.button}!important;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .fc-today-button,
  .fc-todayCustom-button {
    background-color: ${theme.colors.white};
    color: ${theme.colors.button}!important;
    font-size: 18px;
    line-height: 15px;
    padding: 7px 8px 6px;
  }
  .fc .fc-titleCustom-button {
    background-color: rgba(47, 140, 174, 1);
    border-color: rgba(47, 140, 174, 1);
    color: ${theme.colors.white};
    font-size: 18px;
    line-height: 15px;
    padding: 7px 8px 6px;
    &:disabled {
      background-color: rgb(204, 204, 204) !important;
      border-color: rgb(204, 204, 204) !important;
    }
    &:hover {
      background: rgba(47, 140, 174, 0.4);
      color: #ffffff !important;
    }
  }
  .fc .fc-today-button,
  .fc .fc-todayCustom-button {
    border-color: rgb(42, 199, 105);
    &:hover {
      background: #99e4b7;
      border-color: ${theme.colors.button};
      color: #ffffff !important;
    }
  }

  .fc .fc-today-button.fc-button-primary:disabled {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button};
    color: ${theme.colors.white}!important;
    opacity: 1 !important;
  }
  .fc .fc-button-primary:disabled {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.button};
    color: ${theme.colors.button}!important;
  }
  .fc .fc-button-primary:focus {
    box-shadow: none;
  }
  .fc-timeGridWeek-button,
  .fc-dayGridMonth-button,
  .fc-gridMonth-button,
  .fc-gridWeek-button {
    &:hover {
      opacity: 0.2;
      background-color: #2ac769;
      color: #ffffff !important;
    }
  }

  .fc-daygrid-day-top {
    justify-content: center;
  }

  .fc-daygrid-day-number {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.regular};
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: ${theme.colors.text.secondary};
  }

  .fc-icon-chevron-left {
    position: absolute;
    left: 16px;
    bottom: 3px;
  }

  .fc-icon-chevron-right {
    position: absolute;
    left: 20px;
    bottom: 3px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    .fc-toolbar-chunk:nth-child(2) div {
      display: flex;
      align-items: center;
      gap: 8px;
      button {
        display: ${(props) =>
          props.displayMode === "week" ? "block" : "none"};
      }
    }
  }
  .fc-header-toolbar > div:last-child {
    .fc-button-group {
      border: 1px solid #2ac769;
      padding: 4px;
      border-radius: 4px;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${(props) =>
      props.displayMode === "month"
        ? "rgba(42, 199, 105, 0.16)"
        : "transparent"};
  }

  .fc .fc-timegrid-divider {
    padding: 0px;
  }

  thead table colgroup col {
    background: ${theme.colors.background};
  }

  table colgroup col {
    width: 142px !important;
  }

  .fc .fc-timegrid-axis-frame {
    justify-content: center;
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: ${(props) =>
      props.displayMode === "month"
        ? "rgba(42, 199, 105, 0.16)"
        : "transparent"};
  }

  .fc-col-header-cell.fc-day-today {
    background: ${(props) =>
      props.displayMode === "month"
        ? "rgba(42, 199, 105, 0.16)"
        : "#2AC769 !important"};
    .fc-col-header-cell-cushion {
      color: ${theme.colors.white};
    }
  }

  .fc-day-thu.fc-day-today {
    background-color: #a0e5b7;
    .fc-col-header-cell-cushion {
      color: ${theme.colors.white};
    }
  }

  .fc .fc-daygrid-event-harness {
    border-radius: 2px;
    background: var(--leber-admin-color-text-links, #2f8cae);
    height: 17px;

    a {
      color: var(--leber-admin-color-white, #fff);
      font-family: Noto Sans JP;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
    }
  }

  .fc .fc-daygrid-day-frame {
    height: ${(props) => (props.displayMode === "month" ? "89px" : "auto")};
    max-height: ${(props) => (props.displayMode === "month" ? "89px" : "auto")};

    .fc-daygrid-day-events {
      max-height: 66px;
      overflow: hidden auto !important;
      /* background: rgb(242, 242, 242);
      border-bottom: 1px solid #dddddd; */
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        margin: 6px 0px 6px;
        border-left: 4px solid ${theme.colors.white};
        border-right: 4px solid ${theme.colors.white};
        background: ${theme.colors.background};
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        border-radius: 7px;
        border-left: 4px solid ${theme.colors.white};
        border-right: 4px solid ${theme.colors.white};
      }
    }
  }

  .fc-daygrid-day-frame {
    padding: 0 4px;
  }

  .fc .fc-daygrid-day.fc-day-past .fc-daygrid-day-frame:has(.past-date) {
    background-color: ${(props) =>
      props.displayMode === "month" ? "none" : "rgb(183, 183, 183, 0.16)"};
  }

  .fc .fc-daygrid-day.fc-day-past .fc-daygrid-event-harness:has(.past-date) {
    background: #ababab;
  }

  .fc .fc-daygrid-day.fc-day-future .fc-daygrid-day-frame:has(.future-date) {
    background-color: ${(props) =>
      props.displayMode === "month" ? "none" : "#C7DFEF"};
  }
  .fc .fc-daygrid-day.fc-day-future .fc-daygrid-day-frame:has(.future-date) {
    background-color: ${(props) =>
      props.displayMode === "month" ? "none" : "#C7DFEF"};
  }

  .fc .fc-scrollgrid-section-header .fc-scroller {
    background: ${(props) =>
      props.displayMode === "month" ? "none" : "rgb(242, 242, 242)"};
    border-bottom: ${(props) =>
      props.displayMode === "month" ? "none" : "1px solid #dddddd"};
  }
  .fc .fc-scroller {
    overflow: hidden auto !important;
    /* background: rgb(242, 242, 242);
    border-bottom: 1px solid #dddddd; */
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      margin: 6px 0px 6px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
      background: ${theme.colors.background};
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 7px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
    }
  }

  .fc .fc-view-harness {
    height: 575px !important;
  }
  .fc .fc-daygrid-day-events > .fc-daygrid-event-harness:not(:first-child) {
    margin-top: 4px !important;
  }
  .fc .fc-timegrid-slot-label.fc-timegrid-slot-minor {
    border-top: none;
  }
  .fc .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    position: relative;
    top: 12px;
  }
  .fc .fc-scrollgrid.fc-scrollgrid-liquid {
    border-radius: 8px;
  }
  .fc
    .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
    > td {
    border-radius: 0px 0px 8px 8px;
    overflow: hidden;
  }
  .fc .fc-scrollgrid-section.fc-scrollgrid-section-header {
    border-radius: 8px 8px 0px 0px;
  }
  .fc .fc-scrollgrid-section.fc-scrollgrid-section-header > th {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
  }
  .fc .fc-bg-event.fc-event-future.future-date,
  .fc .fc-bg-event.fc-event-future {
    opacity: 0.5;
    background-color: #c7dfef !important;
  }
  .fc .fc-bg-event.fc-event-today,
  .fc .fc-bg-event.fc-event-today.fc-event-future.future-date {
    background-color: #99e4b7 !important;
  }
  .fc .fc-col-header {
    width: ${(props) =>
      props.displayMode === "month"
        ? "100% !important"
        : "calc(100% - 17px) !important"};
  }
  .fc .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural {
    width: ${(props) =>
      props.displayMode === "month"
        ? "100% !important"
        : "calc(100% - 17px) !important"};
    > .fc-scrollgrid-sync-table {
      width: ${(props) =>
        props.displayMode === "month"
          ? "100% !important"
          : "calc(100%) !important"};
    }
  }
  .fc .fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: ${(props) =>
      props.displayMode === "month"
        ? "100% !important"
        : "calc(100% - 17px) !important"};
    > .fc-scrollgrid-sync-table {
      width: ${(props) =>
        props.displayMode === "month"
          ? "100% !important"
          : "calc(100% - 17px) !important"};
    }
  }
  .fc .fc-timegrid-body {
    width: calc(100% - 1px) !important;
    > .fc-timegrid-slots > table,
    > .fc-timegrid-cols > table {
      width: 100% !important;
    }
  }
  /* .fc .fc-toolbar {
    gap: calc(50% - 302px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  } */
`;

export const WrapperModal = styled.div``;

export const ScheduleInfo = styled.div`
  border: 1px solid #cccccc;
  border-radius: 8px;
  background: #f2f2f2;
  margin-bottom: 16px;
`;

export const DoctorName = styled.div`
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    width: 52px;
    height: 32px;
  }

  .ant-select-selector {
    background-color: #ffffff !important;
  }
`;

export const Results = styled.div``;

export const ScheduleInfoTop = styled.div`
  padding: 12px 16px;
`;

export const ScheduleInfoBottom = styled.div`
  padding: 16px;
  background: ${theme.colors.white};
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  min-height: 49px;
  flex-flow: row wrap;
  .item {
    display: flex;
    gap: 4px;
    align-items: center;
    span {
      line-height: 17px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button {
    height: 40px;
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #cccccc;
  width: 98%;
  margin: 0 auto;
`;

export const WrapperInfo = styled.div`
  border-radius: 8px;
  border: 1px solid #cccccc;
  background: #f2f2f2;
  padding: 12px 16px 14px;
  margin-bottom: 8px;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px !important;
  letter-spacing: 0px;
  text-align: left;
  span {
    font-weight: 700;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  gap: 8px;
`;

export const Card = styled.div`
  div:first-child {
    font-family: Noto Sans JP;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 2px;
  }

  div:last-child {
    width: 80px;
    height: 60px;
    border-radius: 8px;
    border: 1px dashed #bdbdbd;
    background: ${theme.colors.white};
  }
`;

export const GroupCards = styled.div`
  border-radius: 8px;
  border: 1px solid #cccccc;
  background: #f2f2f2;
  padding: 8px;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-bottom: 8px;
`;

export const Link = styled.span`
  text-decoration: underline;
  color: ${theme.colors.textLink};
`;

export const Bold = styled.div`
  font-weight: ${theme.fontWeight.bold};
`;

export const WrapperDoctor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 28px;

  div {
    font-family: Noto Sans JP;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #2f8cae;
  }
`;

export const ScheduleTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #3b3b3b;
  margin-bottom: 16px;
`;

export const ScheduleNotiWrapper = styled.div`
  right: 0;
  top: -21px;
  position: absolute;
  width: max-content;
  border-radius: 4px;
  background: #ffffff;
  padding: 3px 4px 4px 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .title {
    width: 129px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #3b3b3b;
    text-align-last: right;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    button {
      width: max-content;
    }
    button:first-child {
      margin: 0px;
    }
  }
  ${theme.media.tablet} {
    .title {
      width: 255px;
    }
    .button-wrapper {
      gap: 16px;
      flex-direction: row;
    }
  }
  ${theme.media.laptop} {
    align-items: center;
    .title {
      width: 375px;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  width: 721px !important;
  .ant-modal-header {
    padding: 8px 25px 7px 22px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }
  .ant-modal-body {
    /* padding: 17px 24px 24px; */
    padding: 0px !important;
  }
  .ant-modal-close {
    right: 4px;
    top: -3px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalStyledAdmin = styled(Modal)`
  width: 721px !important;
  top: calc(50% - 120px);
  .ant-modal-header {
    padding: 10px 25px 9px 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }
  .ant-modal-body {
    /* padding: 17px 24px 24px; */
    padding: 24px !important;
  }
  .ant-modal-close {
    top: -6px;
    right: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const WrapperScheduleResize = styled.div`
  width: 100%;
  .info-block {
    padding: 17px 0px 16px;
    border-bottom: 1px solid #f2f2f2;
    > div {
      padding: 0px 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      span {
        font-weight: 700;
      }
    }
  }
  .input-block {
    padding: 16px 24px 0px;
    min-height: 658px;
  }
  .button-block {
    width: 100%;
    padding: 0px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
`;

export const ResizeWrapper = styled.div`
  height: 24px;
  position: relative;
  .tool-tip {
    position: absolute;
    bottom: -26px;
    left: -14px;
    width: max-content;
    border-radius: 5px;
    background: #2ac769;
    padding: 3px 8px 4px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      border-left: none;
      border-top: none;
      border-right: 8px solid #2ac769;
      border-bottom: 8px solid #2ac769;
      bottom: 15px;
      left: 22px;
      transform: rotate(45deg);
    }
  }
`;

export const VideoModalContentWrapper = styled.div`
  width: 100%;
  .video-input {
    margin-bottom: 24px;
    display: flex;
    gap: 8px;
    input {
      color: #2f8cae;
    }
    div:last-child {
      flex: 1;
    }
  }
  .sent-block {
    margin-top: 24px;
    text-align: center;
    .sent-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.colors.text.primary};
      margin-bottom: 4px;
    }
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-decoration-line: underline;
      color: #2f8cae;
      display: block;
    }
  }
`;

export const ScheduleTooltipWrapper = styled.div`
  position: relative;
  .tool-tip {
    width: max-content;
    margin: 0px auto;
    position: absolute;
    text-align: center;
    top: 22px;
    left: calc(50% - 10px);
    border-radius: 5px;
    background: #ffffff;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
    border: 1px solid #2f8cae;
    padding: 3px 35px;
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      z-index: 1;
      position: absolute;
      border-left: none;
      border-top: none;
      border-right: 1px solid #2f8cae;
      border-bottom: 1px solid #2f8cae;
      background: #ffffff;
      top: -5px;
      left: calc(50% - 5px);
      /* box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25); */
      transform: rotate(-135deg);
    }
  }
`;

export const Image = styled.img`
  height: 60px;
  width: 80px;
  border-radius: 8px;
  ${theme.media.desktop} {
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.error};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 16px;
`;

export const StyledLink = styled.a`
  color: #2f8cae;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-decoration: underline;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: #2f8cae;
  }
`;
